import back5 from "../../../imgs/back5.png";
import cartW from "../../../imgs/cartW.png";
import fav from "../../../imgs/heartW.png";
import { useNavigate } from "react-router-dom";
import Cards from "./products";
import { useSelector } from "react-redux";
const ListingGROAdmin = () => {
  const router = useNavigate();
  const category = useSelector((state) => state.category);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);

  console.log("user", user);
  return (
    <div className="flex items-center justify-center  flex-col bg-[#D9EFED]">
      <div className="w-[95%] px-20 flex flex-col items-center justify-center mt-[1rem]">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (user === "superadmin") router("/Inventory-Categories");
                else if (user === "admin") router("/Inventory-Categories");
                else if (user === "employee") router("/Inventory-Categories");
                else router("/Inventory-Categories");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                {category}
              </h2>
            </div>

            {role === "buyer" && (
              <div className="flex gap-2">
                <button
                  onClick={() => {
                    router("/Inventory-Favorite");
                  }}
                  className={` text-white bg-primary p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md `}
                >
                  <img src={fav} alt="" className="w-5 h-5" />
                </button>

                <button
                  onClick={() => {
                    router("/Inventory-Cart");
                  }}
                  className={` text-white bg-primary p-2 pl-3 pr-3 rounded-lg sm:text-lg text-md `}
                >
                  <img src={cartW} alt="" className="w-5 h-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[95%] px-20  ">
        <Cards />
      </div>
    </div>
  );
};

export default ListingGROAdmin;
