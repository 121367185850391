// import Ellipse from "../../../imgs/backg.svg";
import Vegetables from "../../../../imgs/Vegetables.png";
import Fruits from "../../../../imgs/Fruits.png";
import Meat from "../../../../imgs/Meat.png";
import Sea from "../../../../imgs/Sea.png";
import back5 from "../../../../imgs/back5.png";
import Bakery from "../../../../imgs/Bakery.png";
import Dairy from "../../../../imgs/Dairy.png";
import Juices from "../../../../imgs/Juices.png";
import Snacks from "../../../../imgs/Snacks.png";
import img from "../../../../imgs/impLogo.png";
import { useNavigate } from "react-router";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Nav from "../../vendor/navbar";
import Table from "./vendorList";
let items;
const AdminPanel = () => {
  const user = useSelector((state) => state.user);
  console.log(user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const role = useSelector((state) => state.role);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    dispatch({ type: "PLACE_ORDER_CHECK", num: false });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  const items = [
    {
      id: 1,
      coverPhotos: [
        "https://images.unsplash.com/photo-1588165171080-c89acfa5ee83?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyYXdiZXJyeXxlbnwwfHwwfHx8MA%3D%3D",
        "https://i.pinimg.com/originals/c3/5c/84/c35c84121aaea699769bf2623bb8bd56.png",
        "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg/1200px-Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg",
      ],
      name: "Strawberry",
      price: 23,
      quantity: 1,
      items: 23,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 2,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZdYAUXvlwNIrC2A_4oIhFUyBtKtLNHm0buQ&s",
        "https://images.unsplash.com/photo-1571771894821-ce9b6c11b08e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmFuYW5hc3xlbnwwfHwwfHx8MA%3D%3D",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFJ45D9B5tq0e9ijztRbX_y7YTbGZzn0T1w&s",
      ],
      name: "Banana",
      price: 22,
      items: 12,
      routeV: "/Inventory-Vendor-Product",
      quantity: 1,
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 3,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIg-NlWsGyHPdsYHRMPHoynmyVvtx9GLNRA&s",
        "https://cdn.britannica.com/24/174524-050-A851D3F2/Oranges.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYq2s3xqHMWVzlAUXGh-LnB20Z3nhC8Y46liCklv90f0ZSaxThNe1EFCAETyg5vnHlK8&usqp=CAU",
      ],
      name: "Orange",
      price: 43,
      items: 97,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "dozen",
      route: "/Inventory-Product",
    },
    {
      id: 4,
      coverPhotos: [
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Mangos_-_single_and_halved.jpg/640px-Mangos_-_single_and_halved.jpg",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmgfExstNMpVm0dVH-xKurYdLlQpZpgJxHaw&s",
        "https://www.finedininglovers.com/sites/g/files/xknfdk626/files/styles/article_1200_800_fallback/public/2021-06/best-mango-varieties%C2%A9iStock.jpg?itok=KHYB1X__",
      ],
      name: "Mango",
      price: 33,
      items: 56,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
    {
      id: 5,
      coverPhotos: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgg1d49uY8Qx_SA9IgB4VC_30POp9wq_Vpig&s",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnfVxPeSWw5EY2KodFM3M8qUIEbTeS5fjt4AucycvBPT9R9JSF3GZGD3zKJV8OaCFlYpQ&usqp=CAU",
        "https://us.123rf.com/450wm/spyrakot/spyrakot1901/spyrakot190100061/116630376-single-bunch-grapes-on-vine-close-up-of-bunches-of-ripe-wine-grapes-on-vine-blue-grapes-in-a.jpg?ver=6",
      ],
      name: "Grapes",
      price: 89,
      items: 9,
      quantity: 1,
      routeV: "/Inventory-Vendor-Product",
      amount: "kg",
      route: "/Inventory-Product",
    },
  ];

  return (
    <div>
      <Nav />
      <div>
        {contextHolder}
        <div className={`flex items-center justify-center bg-[#D9EFED]`}>
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] px-20">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  router("/superAdminDashboard");
                  return;
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Inventory
              </h2>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Product"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#00b0a6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>

              <button
                type="button"
                onClick={() => {
                  dispatch({ type: "PLACE_ORDER_CHECK", num: true });
                  router("/Inventory-Categories");
                }}
                className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
              >
                Place Order
              </button>
            </div>

            <div className="mt-3  rounded-md  p-[2rem] pt-[2rem] mb-16  sm:min-h-[360px] bg-[#F6F4F4] shadow-lg">
              <div className="flex items-center justify-between">
                <h1 className="text-gray-700 font-semibold text-[1.1rem]">
                  Recent Orders
                </h1>
                <p
                  className="text-primary cursor-pointer font-semibold text-[0.93rem]"
                  onClick={() => {
                    router("/Inventory-Vendor-Orders");
                  }}
                >
                  View All
                </p>
              </div>
              <div className="w-full grid grid-cols-5  justify-center items-center gap-8 md:gap-x-4 gap-y-10 sm:min-h-[200px]">
                {items?.map((item, index) => (
                  <div class=" bg-white border border-gray-200 rounded-lg shadow">
                    <img
                      class="rounded-t-lg w-full h-24 object-cover"
                      src={item?.coverPhotos?.[0]}
                      alt=""
                    />

                    <div class="p-2">
                      <div className="flex items-center justify-between">
                        <h5 class="mb-2 text-[0.9rem] font-semibold tracking-tight text-gray-700 ">
                          {item?.name}
                        </h5>
                        <p>
                          ${item?.price}/ {item?.amount}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p>Quantity</p>
                        <div className="inline-flex items-center px-8 py-1 text-sm font-medium text-center text-white bg-primary rounded-lg ">
                          120
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-between mt-3">
                <h1 className="text-gray-700 font-semibold text-[1.1rem]">
                  Recent Vendors
                </h1>
                <p
                  className="text-primary cursor-pointer font-semibold text-[0.93rem]"
                  onClick={() => {
                    router("/SuperAdmin/Inventory/VendorListing");
                  }}
                >
                  View All
                </p>
              </div>
              <Table />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
