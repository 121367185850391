import React, { useState } from "react";
import { Avatar, Dropdown, Menu, Input, Pagination } from "antd";
// import Sidebar from "./sideBar";
import Card from "../dash/Card";
// import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import Sidebar from "../dash/sideBar";
import Header from "../dash/Header";
import AttendanceChart from "./components/AttendanceChart";
import UpcomingHoliday from "./components/UpcomingHoliday";

import totalEmp from "../../../imgs/total-employee.png";
import onLeave from "../../../imgs/user.png";
import workingEmp from "../../../imgs/working-employee.png";
import pendingTasks from "../../../imgs/pending.png";
import DetailCard from "../../card/DetailCard";
import { useNavigate } from "react-router-dom";
import AttendanceDashboardWrapper from "../components/AttendanceWrapper";

const columnHeaders = ["ID", "Name", "Position", "Department", "Device", "Type", "Hours", "Status"];

const staticData = [
  {
    id: "1",
    profile: "../../../imgs/dummy.png",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    device: "Clock-In",
    type: "Fingerprint",
    hours: "06",
    status: "Onsite",
  },
  {
    id: "2",
    profile: "../../../imgs/dummy.png",
    name: "Jane Smith",
    position: "Developer",
    device: "Visitor",
    department: "Mechanical",
    type: "Facial",
    hours: "04",
    status: "Onsite",
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    device: "Clock-Out",
    department: "Medical",
    type: "Manual Punch",
    hours: "08",
    status: "Shift Complete",
  },
];

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "254",
    subTitle: "Total Employees",
  },
  {
    id: 2,
    icon: onLeave,
    title: "20",
    subTitle: "Working Employees",
  },
  {
    id: 3,
    icon: workingEmp,
    title: "20",
    subTitle: "On Leave",
  },
  {
    id: 4,
    icon: pendingTasks,
    title: "4",
    subTitle: "On Break",
  },
];
const Index = () => {
  const router = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SIDEBAR", num: "1" });
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6); // Set the number of entries per page
  const filteredData = staticData.filter((data) =>
    data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        // router(`/EmployeeDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        View Profile
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Edit Details
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Remove
      </Menu.Item>
    </Menu>
  );
  return (
    <AttendanceDashboardWrapper>
      <div className="">
        <div className="">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:ml-0 place-items-center ">
            {cardsData.map((card, index) => (
              <DetailCard
                key={index}
                icon={card.icon}
                title={card.title}
                subTitle={card.subTitle}
              />
            ))}
          </div>
        </div>

        {/* <AttendanceCards /> */}
        <div className="grid grid-cols-[3fr_2fr] gap-3 mt-8">
          <AttendanceChart />
          <UpcomingHoliday />
        </div>
        <div className="overflow-x-auto">
          <div
            style={{
              margin: "0.75rem 0 0.75rem 0",
              "font-size": "26px",
              "font-weight": "400",
            }}>
            Recent Activities
          </div>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
              <tr>
                {columnHeaders.map((columnHeader, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap">
                    {columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentData.length === 0 ? (
                <tr>
                  <td colSpan="10" className="text-center text-gray-500 text-lg">
                    <p className="p-10">No data found</p>
                  </td>
                </tr>
              ) : (
                currentData.map((data, index) => (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100 text-md">
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.id}</td>
                    <td className="px-4 flex items-center py-2 font-semibold text-primary whitespace-nowrap">
                      <Avatar
                        src={data.profile}
                        className="border-2 border-primary mr-2 w-10 h-10"
                      />
                      <p className="mr-2 text-primary capitalize">{data.name}</p>
                    </td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.position}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.department}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.device}</td>
                    <td className="px-4 py-2 whitespace-nowrap">{data.type}</td>
                    <td className="px-4 py-2 whitespace-nowrap">{data.hours}</td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      <div
                        className={`p-1 px-3 text-center ${
                          data.status === "Onsite" ? "bg-primary" : "bg-red-600"
                        } text-white font-semibold rounded-lg`}>
                        {data.status}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div
            onClick={() => router("/Attendance")}
            style={{
              display: "flex",
              "flex-direction": "row",
              margin: "10px 6px 0px 0px",
              "justify-content": "end",
              color: "#1ba397",
              cursor: "pointer",
            }}>
            See All
          </div>
        </div>
      </div>
    </AttendanceDashboardWrapper>
  );
};

export default Index;
