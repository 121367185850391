import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import { useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { Calendar } from "react-multi-date-picker";
import axios from "axios";
import { Checkbox, Select } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SearchModal from "../searchModal";

const SelectProgram = () => {
  const router = useNavigate();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const today = new Date();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const dataUser = useSelector((state) => state.dataUser);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState(null);
  const [month, setMonth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [selectedDate, setSelectedDate] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [dob, setDob] = useState("");
  const [countryCode, setCountryCode] = useState(null);
  const [phoneNoo, setPhoneNoo] = useState(null);
  const [searchDob, setSearchDob] = useState("");
  const [pagewithsearch, setPagewithsearch] = useState(1);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [status, setStatus] = useState("All");
  const [noUserFound, setNoUserFound] = useState(false);
  const [gid, setGid] = useState("");
  const [lastName, setLastName] = useState("");
  const [bookletNo, setBookletNo] = useState("");
  const [pensionerData, setPensionerData] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BASE_URL}/v1/pension-programs/names-and-icons`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //         },
  //       }
  //     )
  //     .then((data) => {
  //       setPrograms(data?.data);
  //     })
  //     .catch(() => {});
  // }, []);

  //Holidays API
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/scheduler-holidays`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((res) => {
        setHolidays(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [month, year]);

  const isHoliday = (date) => {
    const dateString = date.format("YYYY-MM-DD"); // Format the date properly

    // Find the holiday object that matches the dateString
    const holiday = holidays.find((holiday) => holiday.date === dateString);

    console.log(holiday, "Hold"); // For debugging

    return holiday; // Return the holiday object or undefined if not found
  };
  const records = [
    {
      id: 1,
      dateTime: "5/6/2024, 9:15am",
      type: "Upcoming",
      profileImage:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      name: "John Doe",
      GID: "GID123456",
      DOB: "01/01/1980",
      completedDate: "",
      cancellationDate: "",
      cancellationReason: "",
    },
    {
      id: 2,
      dateTime: "5/7/2024, 10:00am",
      type: "Cancelled",
      profileImage:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      name: "Jane Smith",
      GID: "GID654321",
      DOB: "02/02/1990",
      completedDate: "",
      cancellationDate: "5/8/2024, 11:00am",
      cancellationReason: "Personal reasons",
    },
    {
      id: 3,
      dateTime: "5/8/2024, 11:30am",
      type: "Completed",
      profileImage:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      name: "Alice Johnson",
      GID: "GID789012",
      DOB: "03/03/1985",
      completedDate: "5/8/2024, 12:00pm",
      cancellationDate: "",
      cancellationReason: "",
    },
    {
      id: 4,
      dateTime: "5/9/2024, 1:00pm",
      type: "Upcoming",
      profileImage:
        "https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
      name: "Bob Brown",
      GID: "GID345678",
      DOB: "04/04/1975",
      completedDate: "",
      cancellationDate: "",
      cancellationReason: "",
    },
  ];
  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };
  const Search = () => {
    router("/pensionerVerification/SearchAppoitments");
    const delay = 500;

    const timeoutId = setTimeout(() => {
      // Cancel the previous request if it exists

      // Create a new cancel token source for this request

      const params = {
        // ...(countryCode &&
        //   phoneNoo && { countryCode: `+${countryCode}`, phoneNo: phoneNoo }),
        // ...(emailValue && { email: emailValue }),
      };
    }, delay);

    return () => {
      clearTimeout(timeoutId);

      // Cancel the request if the component is unmounted
    };
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Appointments"}
        route={"/pensionerVerification/selectedProgram"}
      />
      <div className="sm:w-[95%] flex sm:flex-row flex-col items-center justify-end gap-2">
        <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
          <div className="flex sm:w-[16rem] md:w-full w-[20rem] items-center">
            <p className="mr-4 text-[0.95rem] font-semibold text-gray-600 w-[5rem]">
              Filter By
            </p>
            <Select
              placeholder="Select Status"
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              value={status}
              onChange={(value) => setStatus(value)}
              size="middle"
              className="w-full wow"
            >
              <option value="booked">Booked</option>
              <option value="cancelled">Cancelled</option>
            </Select>
          </div>
        </div>
        <div className="sm:w-[16rem] md:w-[20rem] w-full flex items-center justify-end">
          <form className="flex sm:w-[16rem] md:w-full w-[15rem] items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-3     "
                placeholder="Search"
                required=""
                // value={search}
                onClick={() => setShowSearchModal(true)}
                // onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 mx-5 mb-5 mt-2 p-6 sm:w-[90%] w-full`}
        >
          <div className="flex gap-4">
            <div className="w-[40%] p-4 bg-white flex items-center flex-col">
              <p className="font-semibold mb-6 w-full">Choose the dates:-</p>
              <Calendar
                style={{ zIndex: 0 }}
                className="custom-calendar"
                value={selectedDate}
                onChange={(date) => {
                  if (date instanceof Array) {
                    setSelectedDate(date[0].toDate()); // For some cases returning array, just take first element
                  } else {
                    setSelectedDate(date.toDate ? date.toDate() : date); // Set the correct DateObject or JS Date
                  }
                }}
                minDate={today}
                mapDays={({ date }) => {
                  let props = {};
                  const holiday = isHoliday(date); // Returns the holiday object or undefined

                  if (holiday) {
                    // If it's only a holiday, apply holiday styling
                    props.disabled = true;
                    props.style = {
                      // backgroundColor: "lightgreen", // Optional holiday style
                      marginTop: "0.5em",
                      borderRadius: "50%",
                      cursor: "pointer",
                    };
                    props.children = (
                      <div>
                        {date.day}
                        <div
                          style={{ fontSize: "0.6em" }}
                          className="text-gray-500"
                        >
                          Holiday
                        </div>
                      </div>
                    );
                    props.onClick = () => {
                      setHoliday(holiday?.name);
                      setShowHoliday(true);
                    };
                  }

                  return props;
                }}
                onMonthChange={(month) => {
                  setMonth(month.format("MM"));
                }}
                onYearChange={(year) => {
                  setYear(year.format("YYYY"));
                }}
              />
            </div>
            <div className="flex flex-col w-full bg-white p-4">
              <div className="w-full flex mb-2 items-center justify-end">
                <p className="font-semibold text-red-500 hover:underline cursor-pointer">
                  Cancel Appointments
                </p>
              </div>
              <div className="w-full grid grid-cols-2 gap-4">
                {records.map((record, index) => (
                  <div
                    key={index}
                    className="border p-3 gap-1 bg-gray-100 rounded-md shadow-sm"
                  >
                    <div className="w-full flex gap-1 mb-4 justify-between">
                      <div>
                        <p className="font-semibold text-gray-500">
                          Slot booked For
                        </p>
                        <div className="px-4 py-1 bg-primary rounded-md text-white">
                          {record.dateTime}
                        </div>
                      </div>
                      <div>
                        {record.type === "Upcoming" && (
                          <Checkbox
                            className="checkk"
                            checked={selectedRecords.includes(record.id)}
                            onChange={() => handleCheckboxChange(record.id)}
                          />
                        )}
                        <p
                          className={`font-semibold text-right ${
                            record.type === "Cancelled"
                              ? "text-red-500"
                              : record?.type === "Completed"
                              ? "text-gray-500"
                              : "hidden"
                          }`}
                        >
                          {record.type}
                        </p>

                        <p className={`font-semibold`}>
                          {record.type === "Cancelled"
                            ? record?.cancellationDate
                            : record?.type === "Completed"
                            ? record?.completedDate
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="bg-white p-2 py-3 mb-4 rounded-md flex flex-col gap-2">
                      <div className="flex items-center gap-3">
                        <img
                          src={record.profileImage}
                          alt="Profile"
                          className="w-14 h-14 rounded-full"
                        />
                        <div className="ml-3 w-full">
                          <div className="w-full flex items-center justify-between">
                            <p className="font-semibold text-gray-600">
                              {record.name}
                            </p>
                            <p className="text-gray-600">Female</p>
                          </div>
                          <p className="text-gray-600">
                            <span className="font-semibold">GID:</span>{" "}
                            {record.GID}
                          </p>
                          <div className="w-full flex items-center justify-between">
                            <p className="text-gray-600">
                              <span className="font-semibold">DOB:</span>{" "}
                              {record.DOB}
                            </p>
                            <p
                              onClick={() => {
                                router(
                                  "/pensionerVerification/AppoitmentDetails"
                                );
                              }}
                              className="text-primary font-semibold cursor-pointer hover:underline"
                            >
                              view Details
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {record.type === "Cancelled" && (
                      <div className="bg-white p-2 py-2 mb-2 rounded-md flex flex-col">
                        <p className="font-semibold text-red-500">Reason:</p>
                        <p>{record.cancellationReason}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>

      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {showSearchModal && (
        <SearchModal
          show={showSearchModal}
          setShow={setShowSearchModal}
          search={search}
          setSearch={setSearch}
          dob={dob}
          setDob={setDob}
          searchDob={searchDob}
          setSearchDob={setSearchDob}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          phoneNoo={phoneNoo}
          setPhoneNoo={setPhoneNoo}
          gid={gid}
          setGid={setGid}
          bookletNo={bookletNo}
          setBookletNo={setBookletNo}
          lastName={lastName}
          setLastName={setLastName}
          searchFunction={Search}
        />
      )}
    </div>
  );
};

export default SelectProgram;
