import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import trash from "../../../../../imgs/trash.png";
import axios from "axios";
import tick from "../../../../../imgs/across.png";
import { PlusCircleFilled } from "@ant-design/icons";
import cross from "../../../../../imgs/cross.png";
import logo1 from "../../../../../imgs/logo12.png";
import { Select } from "antd";
import { DeleteFilled } from "@ant-design/icons";
let file;
const ThirdI = () => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const merchantCompany = useSelector((state) => state.merchantCompany);
  console.log(merchantCompany);
  const [display, setDisplay] = useState("");
  const [optionn, setOption] = useState(null);
  const [display1, setDisplay1] = useState("");
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [verify, setVerify] = useState(false);
  const [show, setShow] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [nisNo, setNisNo] = useState("");
  const [nisFront, setNisFront] = useState("");
  const [nisBack, setNisBack] = useState("");
  // const [nisb, setNisb] = useState("");
  const [tisNo, setTisNo] = useState("");
  const [tisFront, setTisFront] = useState("");
  const [tisBack, setTisBack] = useState("");
  // const [tisb, setTisb] = useState("");
  const [err, setErr] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [briNo, setBriNo] = useState("");
  const [briFront, setBriFront] = useState("");
  const [brif, setBrif] = useState("");
  const [brib, setBrib] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [vatFront, setVatFront] = useState("");
  // const [vatBack, setVatBack] = useState("");
  const [vatf, setVatf] = useState("");
  const [vatb, setVatb] = useState("");
  console.log(selectedOptions);
  let optionI;
  optionI = [
    {
      label: "Business Registration Information",
      value: "Business Registration Information",
    },
    {
      label: "Value-Added Tax",
      value: "Value-Added Tax",
    },
    {
      label: "National Insurance",
      value: "National Insurance",
    },
    {
      label: "Tax Information",
      value: "Tax Information",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    if (file === undefined) {
      setIsLoading1(false);
      setIsLoading2(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantDocument`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          load(false);
          e.target.value = null;
        })
        .catch(() => {});
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handleOptionDelete = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option)
    );
    if (option === "National Insurance") {
      setDisplay("");
      setNisNo("");
      setNisFront("");
    } else if (option === "Tax Information") {
      setDisplay1("");
      setTisNo("");
      setTisFront("");
    } else if (option === "Business Registration Information") {
      setBriNo("");
      setBriFront("");
      // setBriBack("");
    } else if (option === "Value-Added Tax") {
      setVatNo("");
      setVatFront("");
    }
  };

  const nextHandler = (e) => {
    e.preventDefault();

    const body = [];

    // Business Registration Information
    if (briNo !== "" || brif !== "" || brib !== "") {
      const briData = {
        type: "business registration information",
        docIDName: "briNumber",
        docIDValue: `${briNo}`,
        docImages: [],
      };

      if (brif !== "") {
        briData.docImages.push({
          type: "front image",
          imageUrl: `${brif}`,
        });
      }

      if (brib !== "") {
        briData.docImages.push({
          type: "back image",
          imageUrl: `${brib}`,
        });
      }

      body.push(briData);
    }

    // Value-Added Tax Information
    if (vatNo !== "" || vatf !== "" || vatb !== "") {
      const vatData = {
        type: "value-added tax",
        docIDName: "vatNumber",
        docIDValue: `${vatNo}`,
        docImages: [],
      };

      if (vatf !== "") {
        vatData.docImages.push({
          type: "front image",
          imageUrl: `${vatf}`,
        });
      }

      if (vatb !== "") {
        vatData.docImages.push({
          type: "back image",
          imageUrl: `${vatb}`,
        });
      }

      body.push(vatData);
    }

    // National Insurance Information
    if (nisNo !== "" || nisFront !== "" || nisBack !== "") {
      const nisData = {
        type: "national insurance",
        docIDName: "nisNumber",
        docIDValue: `${nisNo}`,
        docImages: [],
      };

      if (nisFront !== "") {
        nisData.docImages.push({
          type: "front image",
          imageUrl: `${nisFront}`,
        });
      }

      if (nisBack !== "") {
        nisData.docImages.push({
          type: "back image",
          imageUrl: `${nisBack}`,
        });
      }

      body.push(nisData);
    }

    // Taxpayer Identification Information
    if (tisNo !== "" || tisFront !== "") {
      const tisData = {
        type: "taxpayer identification",
        docIDName: "tinNumber",
        docIDValue: `${tisNo}`,
        docImages: [],
      };

      if (tisFront !== "") {
        tisData.docImages.push({
          type: "front image",
          imageUrl: `${tisFront}`,
        });
      }
      if (tisBack !== "") {
        tisData.docImages.push({
          type: "back image",
          imageUrl: `${tisBack}`,
        });
      }

      body.push(tisData);
    }

    if (body.length === 0) {
      setErr("Please add at least one document");
      setShow(true);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/merch-company-doc?companyId=${merchantCompany?.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch({ type: "IINCREMENT" });
      })
      .catch((err) => {
        if (err.response.data.message === '"expiryDate" must be a valid date') {
          setErr("Please verify if expiration date is available on card");
        } else {
          setErr(err.response.data.message);
        }

        setShow(true);
      });
  };
  const handleChange = (setState, check) => (event) => {
    setState(event.target.value);
    console.log(check);
  };
  return (
    <div className="flex items-center flex-col justify-center">
      <div className=" mt-10 sm:w-[60%] w-[80%] min-h-[70vh]">
        <div className="flex justify-center mt-2 flex-col items-center">
          <div className="w-full flex items-center gap-4">
            <div className="w-3/4">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Select Document:
              </label>
              <Select
                placeholder="Document Type"
                optionFilterProp="children"
                // defaultValue={optionI[0].value}
                className="wow9"
                style={{ marginBottom: "1.2rem", marginTop: "5px" }}
                onChange={(val) => {
                  setOption(val);
                }}
                value={optionn}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={optionI.filter(
                  (opt) => !selectedOptions.includes(opt.value)
                )}
              />
            </div>
            <button
              onClick={() => {
                setSelectedOptions([...selectedOptions, optionn]);
                setOption(null);
              }}
              className="w-1/4 lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2"
            >
              Add Now
            </button>
          </div>

          {selectedOptions.map((selectedOption, index) => (
            <div key={index} className="w-full">
              {/* Business Registration Information */}
              {selectedOption === "Business Registration Information" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Business Registration Information
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="BIN #"
                        onChange={handleChange(setBriNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="grid sm:grid-cols-1 sm:gap-4 grid-cols-1 w-full">
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Front
                        </h2>
                        <label>
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Front</p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-poniter"
                            id="special-input2"
                            ref={fileInputRef}
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setBriFront,
                              setBrif,
                              setIsLoading2
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            briFront ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              // setIShow2(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={briFront}
                            alt=""
                          />

                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setBriFront("");
                              setBrif("");
                            }}
                          >
                            <DeleteFilled
                              className="sm:w-[3rem] w-[2rem]"
                              style={{ fontSize: "30px", color: "#1BA397" }}
                            />
                          </div>
                        </div>
                        {/* <div
                                                    className={`${iShow2 ? "" : "hidden"
                                                        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                                                >
                                                    <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                                                        <img
                                                            className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                                                            src={cross}
                                                            onClick={() => setIShow2(false)}
                                                            alt=""
                                                        />
                                                        <img className="w-[45rem] h-[28rem]" src={PFront} alt="" />
                                                    </div>
                                                </div> */}
                      </div>
                      {/* <div>
                        <h2
                          
                          className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                        >
                          Image Back
                        </h2>
                        <label htmlFor="special-input5">
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Back</p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-poniter"
                            id="special-input5"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setBriBack,
                              setBrib,
                              setIsLoading3
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            briBack ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                             
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={briBack}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setBriBack("");
                              setBrib("");
                            }}
                          >
                            <DeleteFilled
                              className="sm:w-[3rem] w-[2rem]"
                              style={{ fontSize: "30px", color: "#1BA397" }}
                            />
                          </div>
                        </div>
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              )}

              {/* Value Added Tax */}
              {selectedOption === "Value-Added Tax" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Value Added Tax
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="VAT #"
                        onChange={handleChange(setVatNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="grid sm:grid-cols-1 sm:gap-4 grid-cols-1 w-full">
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Front
                        </h2>
                        <label>
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Front</p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-poniter"
                            id="special-input2"
                            accept="image/png, image/gif, image/jpeg"
                            ref={fileInputRef}
                            onInput={imageHandler(
                              setVatFront,
                              setVatf,
                              setIsLoading2
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            vatFront ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              // setIShow2(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={vatFront}
                            alt=""
                          />

                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setVatFront("");
                              setVatf("");
                            }}
                          >
                            <DeleteFilled
                              className="sm:w-[3rem] w-[2rem]"
                              style={{ fontSize: "30px", color: "#1BA397" }}
                            />
                          </div>
                        </div>
                        {/* <div
                                                 className={`${iShow2 ? "" : "hidden"
                                                     } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                                             >
                                                 <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                                                     <img
                                                         className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                                                         src={cross}
                                                         onClick={() => setIShow2(false)}
                                                         alt=""
                                                     />
                                                     <img className="w-[45rem] h-[28rem]" src={PFront} alt="" />
                                                 </div>
                                             </div> */}
                      </div>
                      {/* <div>
                        <h2
                          
                          className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
                        >
                          Image Back
                        </h2>
                        <label>
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Back</p>
                          </div>
                          <input
                            type="file"
                            className="hidden w-[100%] cursor-poniter"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setVatBack,
                              setVatb,
                              setIsLoading3
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            vatBack ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                             
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={vatBack}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setVatBack("");
                              setVatb("");
                            }}
                          >
                            <DeleteFilled
                              className="sm:w-[3rem] w-[2rem]"
                              style={{ fontSize: "30px", color: "#1BA397" }}
                            />
                          </div>
                        </div>
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              )}

              {/* National Insurance */}
              {selectedOption === "National Insurance" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        National Insurance
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="NIS #"
                        onChange={handleChange(setNisNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="grid sm:grid-cols-1 sm:gap-4 grid-cols-1 w-full">
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Front
                        </h2>
                        <label
                          htmlFor="special-input"
                          className={`${
                            isLoading1 || isLoading2
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Front</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input"
                            accept="image/png, image/gif, image/jpeg"
                            ref={fileInputRef}
                            onInput={imageHandler(
                              setDisplay,
                              setNisFront,
                              setIsLoading1
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            display ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={display}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setDisplay("");
                              setNisFront("");
                              setIsLoading1(false);
                              setIsLoading2(false);
                              const wow =
                                document.getElementById("special-input");
                              wow.value = "";
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={display}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Back
                        </h2>
                        <label
                          className={`${
                            isLoading1 || isLoading2 || isLoading3
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Back</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setNisb,
                              setNisBack,
                              setIsLoading1
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            nisb ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={nisb}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setNisBack("");
                              setNisb("");
                              setIsLoading1(false);
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={nisb}
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              )}

              {/* Tax Information */}
              {selectedOption === "Tax Information" && (
                <div className="bg-gray-50 relative p-6 shadow-md mb-3 rounded-lg">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="flex flex-col">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Tax Information
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="TIN #"
                        onChange={handleChange(setTisNo)}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>
                    <div className="grid sm:grid-cols-1 sm:gap-4 grid-cols-1 w-full">
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Front
                        </h2>
                        <label
                          className={`${
                            isLoading1 || isLoading2
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Front</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/gif, image/jpeg"
                            ref={fileInputRef}
                            onInput={imageHandler(
                              setDisplay1,
                              setTisFront,
                              setIsLoading2
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            display1 ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow1(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={display1}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setDisplay1("");
                              setTisFront("");
                              setIsLoading1(false);
                              setIsLoading2(false);
                              const wow =
                                document.getElementById("special-input1");
                              wow.value = "";
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow1 ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow1(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={display1}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Back
                        </h2>
                        <label
                          className={`${
                            isLoading1 || isLoading2 || isLoading3
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Back</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setTisb,
                              setTisBack,
                              setIsLoading3
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            tisb ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow1(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={tisb}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setTisBack("");
                              setTisb("");
                              setIsLoading1(false);
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow1 ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow1(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={tisb}
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`mt-3 flex items-center sm:justify-end justify-center sm:w-[60%] w-[80%]`}
      >
        <button
          onClick={() => dispatch({ type: "IINCREMENT" })}
          className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      <div
        className={`${
          verify ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setVerify(false)}
          >
            <img src={tick} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">
            Please Enter at least one
          </p>

          <button
            type="submit"
            onClick={() => setVerify(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>

          <button
            type="submit"
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThirdI;
