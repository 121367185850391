import React from "react";

const UpcomingHoliday = () => {
  return (
    <div className="border border-border shadow bg-[#fff] rounded-md p-5 pb-8 ">
      <h2 className="text-xl font-medium">Upcoming Holidays</h2>
      <div className="grid mt-4">
        <div className="grid grid-cols-2 items-center border-b-2 border-gray-100 px-2 py-3">
          <div className="flex gap-2 items-center">
            <div className="w-2 h-2 bg-orange-500 rounded-full"></div>
            <h6 className="text-lg">Monday 14 August</h6>
          </div>
          <h4 className="text-xl font-semibold">Iqbal Day</h4>
        </div>
        <div className="grid grid-cols-2 items-center  border-b-2 border-gray-100 px-2 py-3">
          <div className="flex gap-2 items-center">
            <div className="w-2 h-2 bg-green-600 rounded-full"></div>
            <h6 className="text-lg">Monday 14 August</h6>
          </div>
          <h4 className="text-xl font-semibold">Eid UI Adha</h4>
        </div>
        <div className="grid grid-cols-2 items-center border-b-2 border-gray-100 px-2 py-3">
          <div className="flex gap-2 items-center">
            <div className="w-2 h-2 bg-green-600 rounded-full"></div>
            <h6 className="text-lg">Monday 14 August</h6>
          </div>
          <h4 className="text-xl font-semibold">Eid UI Fitr</h4>
        </div>
        <div className="grid grid-cols-2 items-center border-b-2 border-gray-100 px-2 py-3">
          <div className="flex gap-2 items-center">
            <div className="w-2 h-2 bg-green-600 rounded-full"></div>
            <h6 className="text-lg">Monday 14 August</h6>
          </div>
          <h4 className="text-xl font-semibold">Milad Un Nabi </h4>
        </div>
        <div className="grid grid-cols-2 items-center border-b-2 border-gray-100 px-2 py-3">
          <div className="flex gap-2 items-center">
            <div className="w-2 h-2 bg-green-600 rounded-full"></div>
            <h6 className="text-lg">Monday 14 August</h6>
          </div>
          <h4 className="text-xl font-semibold">Independence Day</h4>
        </div>
      </div>
    </div>
  );
};

export default UpcomingHoliday;
