import back5 from "../../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import Nav from "../../../vendor/navbar";

import Table from "../table";
import { useSelector } from "react-redux";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <div className="bg-[#D9EFED] min-h-screen h-screen">
      <Nav />
      <div className="flex items-center justify-center bg-[#D9EFED] ">
        <div className="w-[95%]  px-10 rounded-md mb-12">
          <div className="cursor-pointer flex w-full items-center justify-center mt-3">
            <img
              onClick={() => {
                if (user === "vendor") {
                  router("/VendorDashboard");
                  return;
                }
                router("/SuperAdmin/InventoryDashboard");
              }}
              className="sm:w-[1.7rem] w-[1.3rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Select Vendor
            </h2>
          </div>
          <div className="mt-6 rounded-md p-2">
            <Table />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
