import React, { useEffect, useState } from "react";
import PageHeader from "../pageHeader/pageHeader";
import ErrorModal from "../../Merchant/Modals/errorModal";
import axios from "axios";
import moment from "moment";
import logo from "../../../imgs/logo12.png";
import { Select, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const SetSlot = () => {
  const router = useNavigate();
  const [showErr, setShowErr] = useState(false);
  const [err, setErr] = useState("");
  const [reason, setReason] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [showCancel, setShowCancel] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const [type, setType] = useState(null);
  const [slots, setSlots] = useState([]);
  const [fetch, setFetch] = useState(null);
  // useEffect(() => {
  //   setSlots([]);
  //   let url;
  //   if (type)
  //     url = `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/my-booked-slots?status=${type}`;
  //   else
  //     url = `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/my-booked-slots`;
  //   axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
  //       },
  //     })
  //     .then((data) => {
  //       console.log("Dataaa", data?.data?.rows);
  //       setSlots(data?.data?.rows);
  //     })
  //     .catch((err) => {
  //       setErr(err?.response?.data?.message);
  //       console.log(err);
  //     });
  // }, [fetch, type]);
  const isDateTimePassed = (date, time) => {
    const dateTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss");
    const now = moment();
    return dateTime.isBefore(now);
  };
  const cancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-book-slot/${cancelId}/cancel/by-self`,
        {
          cancelReason: reason,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((data) => {
        setShowCancel(false);
        setFetch(!fetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        console.log(err);
      });
  };
  useEffect(() => {
    setSlots([
      {
        id: 1,
        dateTime: "2024-10-15 10:00 AM",
        type: "Upcoming",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "John Doe",
        GID: "GID12345",
        DOB: "1980-01-01",
      },
      {
        id: 2,
        dateTime: "2024-10-16 11:00 AM",
        type: "Completed",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Jane Smith",
        GID: "GID12346",
        DOB: "1985-02-02",
        completedDate: "2024-10-16",
      },
      {
        id: 3,
        dateTime: "2024-10-17 12:00 PM",
        type: "Cancelled",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Alice Johnson",
        GID: "GID12347",
        DOB: "1990-03-03",
        cancellationDate: "2024-10-17",
        cancellationReason: "Personal reasons",
      },
      {
        id: 4,
        dateTime: "2024-10-18 01:00 PM",
        type: "Upcoming",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Bob Brown",
        GID: "GID12348",
        DOB: "1975-04-04",
      },
      {
        id: 5,
        dateTime: "2024-10-19 02:00 PM",
        type: "Completed",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Chris Green",
        GID: "GID12349",
        DOB: "1982-05-05",
        completedDate: "2024-10-19",
      },
      {
        id: 6,
        dateTime: "2024-10-20 03:00 PM",
        type: "Cancelled",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Diana White",
        GID: "GID12350",
        DOB: "1995-06-06",
        cancellationDate: "2024-10-20",
        cancellationReason: "Health issues",
      },
      {
        id: 7,
        dateTime: "2024-10-21 04:00 PM",
        type: "Upcoming",
        profileImage:
          "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid",
        name: "Eve Black",
        GID: "GID12351",
        DOB: "1999-07-07",
      },
    ]);
  }, []);
  const handleCheckboxChange = (id) => {
    setSelectedRecords((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((recordId) => recordId !== id)
        : [...prevSelected, id]
    );
  };
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-3  min-h-screen">
      <PageHeader title={"All Appointments"} route={-1} header={true} />
      <div className="flex justify-center flex-col items-center">
        <div className="flex-col pb-12 bg-white shadow-lg min-h-[80vh] rounded-sm mx-4 m-2 p-4 sm:w-[90%] w-full">
          <div className="flex justify-between items-center mb-4 w-full">
            <p className="font-semibold text-red-500 hover:underline cursor-pointer">
              Cancel Appointments
            </p>
            <div className="flex gap-2 w-[12rem] items-center justify-center">
              <label
                htmlFor="filter"
                className="font-semibold text-lg text-gray-700"
              >
                Filter:-
              </label>
              <Select
                placeholder="Type"
                id="filter"
                className="wow"
                value={type}
                onChange={(e) => setType(e)}
              >
                <option value="booked">Booked</option>
                <option value="cancelled">Cancelled</option>
              </Select>
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-4">
            {slots.map((record, index) => (
              <div
                key={index}
                className="border p-3 gap-1 bg-gray-100 rounded-md shadow-sm"
              >
                <div className="w-full flex gap-1 mb-4 justify-between">
                  <div>
                    <p className="font-semibold text-gray-500">
                      Slot booked For
                    </p>
                    <div className="px-4 py-1 bg-primary rounded-md text-white">
                      {record.dateTime}
                    </div>
                  </div>
                  <div>
                    {record.type === "Upcoming" && (
                      <Checkbox
                        className="checkk"
                        checked={selectedRecords.includes(record.id)}
                        onChange={() => handleCheckboxChange(record.id)}
                      />
                    )}
                    <p
                      className={`font-semibold text-right ${
                        record.type === "Cancelled"
                          ? "text-red-500"
                          : record?.type === "Completed"
                          ? "text-gray-500"
                          : "hidden"
                      }`}
                    >
                      {record.type}
                    </p>

                    <p className={`font-semibold`}>
                      {record.type === "Cancelled"
                        ? record?.cancellationDate
                        : record?.type === "Completed"
                        ? record?.completedDate
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="bg-white p-2 py-3 mb-4 rounded-md flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={record.profileImage}
                      alt="Profile"
                      className="w-14 h-14 rounded-full"
                    />
                    <div className="ml-3 w-full">
                      <div className="w-full flex items-center justify-between">
                        <p className="font-semibold text-gray-600">
                          {record.name}
                        </p>
                        <p className="text-gray-600">Female</p>
                      </div>
                      <p className="text-gray-600">
                        <span className="font-semibold">GID:</span> {record.GID}
                      </p>
                      <div className="w-full flex items-center justify-between">
                        <p className="text-gray-600">
                          <span className="font-semibold">DOB:</span>{" "}
                          {record.DOB}
                        </p>
                        <p
                          onClick={() => {
                            router("/pensionerVerification/AppoitmentDetails");
                          }}
                          className="text-primary font-semibold cursor-pointer hover:underline"
                        >
                          view Details
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {record.type === "Cancelled" && (
                  <div className="bg-white p-2 py-2 mb-2 rounded-md flex flex-col">
                    <p className="font-semibold text-red-500">Reason:</p>
                    <p>{record.cancellationReason}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`${
          showCancel ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[26rem]  flex items-center p-5 bg-white flex-col">
          <div className="w-16 h-16" onClick={() => setShowCancel(false)}>
            <img src={logo} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-6 mb-5 font-semibold">
            Are you sure to you want to cancel the appoitment?
          </p>
          <textarea
            type="text"
            name=""
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            placeholder="Note"
            className=" lg:text-[1.1rem] w-[85%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
          />
          <div className="w-full flex gap-2 items-center justify-center">
            <button
              onClick={() => {
                setShowCancel(false);
                setReason("");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={() => {
                if (reason === "") {
                  setErr("Please Enter a specific reason.");
                  setShowErr(true);
                } else cancel();
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
    </div>
  );
};

export default SetSlot;
