import React from "react";
import PageHeader from "../pageHeader/pageHeader";

const SelectProgram = () => {
  return (
    <div className="bg-[#ebfff3]  flex flex-col w-full p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Appointment Detail"}
        route={"/pensionerVerification/AllAppointments"}
      />

      <div className="flex justify-center w-full">
        <div
          className={`bg-gray-100 shadow-lg rounded-sm mx-4 m-5 p-6 w-full sm:w-[90%]`}
        >
          <div className="border p-3 gap-1 sm:w-[40%] bg-white px-6 rounded-md shadow-sm">
            <div className="w-full flex gap-1 mb-4 justify-between">
              <p className="font-semibold text-gray-500">Slot booked For</p>
              <div className="px-4 py-1 bg-primary rounded-md text-white">
                {"24/01/1996, 09:00 AM"}
              </div>
            </div>
            <div className="mb-3 flex gap-3">
              <img
                src={
                  "https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.1880011253.1728777600&semt=ais_hybrid"
                }
                alt="Profile"
                className="w-20 h-20 rounded-full"
              />
              <div>
                <h1 className="font-bold text-lg">Abu Bakar</h1>
                <p className="font-semibold text-gray-500">
                  <span className="font-semibold text-gray-500">GID: </span>
                  12345{" "}
                </p>
                <p className="font-semibold text-gray-500">
                  24/01/1997 {"(27 years)"}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between mb-1 px-3">
              <p className="font-semibold text-gray-600">Status</p>
              <p className="font-semibold text-red-500">
                {"Verification Pending"}
              </p>
            </div>
            <div className="bg-gray-100 p-3 py-5 mb-4 rounded-md flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Program</p>
                <p className="font-semibold">{"SCP"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Booklet#</p>
                <p className="font-semibold">{"986532(2024)"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">
                  Distribution Centre
                </p>
                <p className="font-semibold">{"Centre Name"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Gender</p>
                <p className="font-semibold">{"Male"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Phone</p>
                <p className="font-semibold">{"+9865655898"}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-semibold text-gray-600">Email</p>
                <p className="font-semibold">{"Test@yopmail.com"}</p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center mb-6">
              <button
                className={`bg-red-600 font-semibold text-white cursor-pointer flex w-[70%] items-center py-3 rounded-md justify-center mt-4 gap-2}`}
              >
                Cancel Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProgram;
