import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import loc from "../../../imgs/loc.png";
import back5 from "../../../imgs/back5.png";
import group from "../../../imgs/group.png";
import { Button } from "antd";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
const EventDetails = ({ setState, state, subId }) => {
  const { userId, eventId } = useParams();
  const dataUser = useSelector((state) => state.dataUser);
  const [fetch, setFetch] = useState(false);
  const [status, setStatus] = useState("");
  const [joined, setJoined] = useState(0);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [event, setEvent] = useState("");
  // const [err, setErr] = useState("");
  // const [show, setShow] = useState("");
  const containerStyle = {
    width: "100%",
    height: "250px",
  };
  useEffect(() => {
    if (event?.location) {
      const [lat, lng] = event.location.split(", ").map(parseFloat);
      setCenter({ lat, lng });
    }
  }, [event]);

  useEffect(() => {
    let url;
    if (state === "event")
      url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-get-event-detail/${eventId}?inviteeUserId=${userId}`;
    else
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/parent-event-invitee-get-sub-event/${subId}`;
    axios
      .get(url)
      .then((data) => {
        setEvent(data?.data?.event);
        setJoined(`${data?.data?.eventJoineeCount}`);
        // setGallary(data?.data?.event?.eventgallaries);
      })
      .catch(() => {
        // setErr(err.response.data.message);
        // setShow(true);
      });
  }, [fetch]);
  useEffect(() => {
    console.log("Events", event);
  }, [event]);
  useEffect(() => {
    const checkInviteeStatus = () => {
      if (userId && event?.eventinvitees?.length > 0) {
        const invitee = event.eventinvitees.find(
          (invitee) => invitee.inviteeUserId === userId
        );
        if (invitee) {
          console.log("Test", invitee);
          setStatus(invitee.status);
        } else {
          console.log("NotFound");
          setStatus("no");
        }
      } else {
        console.log("Not Found first");
        setStatus("no");
      }
    };
    console.log("EVENT", event, dataUser?.data?.user?.id);
    if (event !== "") {
      checkInviteeStatus();
    }
  }, [event, fetch]);
  useEffect(() => {
    console.log("STATUS", status);
  }, [status]);
  const Join = (type) => {
    let method = "put";
    let body;
    if (state === "event") {
      body = {
        inviteeUserId: userId,
        status: type,
      };
    } else {
      if (type === "joined") {
        body = {
          eventId: subId,
          inviteeUserId: userId,
          parantEventId: eventId,
        };
        method = "post";
      } else {
        body = {
          inviteeUserId: userId,
          status: "leaved",
        };
        method = "put";
      }
    }
    let url;
    if (state === "event")
      url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-update-event/${eventId}`;
    else {
      if (type === "joined")
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-join-sub-event`;
      else
        url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/invitee-update-event/${subId}`;
    }
    axios[method](url, body)
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex w-full pb-[5rem] p-2 items-center justify-center">
      <div className="w-full flex flex-col mt-4 w-full ">
        <div className="w-full flex items-center justify-between">
          {state !== "event" && (
            <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
              <div
                onClick={() => setState("subEvents")}
                className="cursor-pointer flex items-center justify-center"
              >
                <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
                <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                  Sub Event
                </h2>
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-between">
          <h1 className="text-2xl font-semibold ">
            {event?.name}{" "}
            <span
              className={`${
                event?.type === "private" ? "bg-[#6F42C1]" : "bg-[#3395FE]"
              } ml-3 text-sm rounded-full pl-4 p-1 pr-4 text-white`}
            >
              {event?.type}
            </span>
          </h1>
        </div>
        <div className="flex md:gap-10 gap-4 mt-2">
          <div className="flex gap-2">
            <img src={loc} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">{event?.address}</p>
          </div>
          <div className="flex gap-2">
            <img src={group} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">
              {event?.allowedPerson} Guest capacity
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end mt-4">
          <Button
            onClick={() => {
              if (state === "event") setState("gallary");
              else setState("subGallary");
            }}
            className="border border-dotted bg-gray-50 hover:bg-gray-200 cursor-pointer text-black font-semibold"
          >
            See All Photos
          </Button>
        </div>
        <div
          key={event.id}
          className="w-full flex md:flex-row flex-col gap-2 mt-2"
        >
          <div className="md:w-[60%] w-full h-[320px]">
            <img
              className="w-full h-full rounded-xl border bg-gray-50"
              src={event?.coverImage}
              alt={event?.id}
            />
          </div>
          <div className="md:w-[40%] w-full h-[320px] flex gap-2">
            {event?.eventgallaries?.length > 0 && (
              <div className="w-full h-[320px]">
                <Carousel arrows className="custom-carousel w-full h-[320px]">
                  {event?.eventgallaries.map((gallery, index) => (
                    <div key={index}>
                      <img
                        className="w-full h-[320px] rounded-xl border bg-gray-50"
                        src={gallery.image}
                        alt={gallery?.id}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex md:flex-row flex-col gap-4">
          <div className="mt-6 md:w-[80%] w-full">
            <div className="flex gap-5 mt-1 mb-2">
              <img
                src={event?.hostUser?.person?.image}
                alt=""
                className="w-12 h-12 border border-gray-50 rounded-md"
              />
              <div>
                <p className="font-semibold text-gray-700">
                  {event?.hostUser?.person?.firstName}{" "}
                  {event?.hostUser?.person?.lastName}
                </p>
                <p className="font-semibold text-gray-400">Organizer</p>
              </div>
            </div>
            <h1 className="font-semibold text-gray-800 text-lg">Description</h1>
            <p className="mb-10">{event?.detail}</p>
            <LoadScript googleMapsApiKey="AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="md:w-[20%] w-full p-6 flex flex-col items-center justify-center rounded-xl bg-gray-100 mt-6">
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Allowed members</p>
              <b className="underline">{event?.allowedPerson}</b>
            </p>
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Joined members</p>
              <b className="underline text-red-600">{joined}</b>
            </p>
            <div className="w-full mt-4">
              {/* <p className="pl-3 text-center font-semibold pr-3">
                From
              </p> */}
              <p className="pl-2 font-semibold pr-2 text-center whitespace-nowrap bg-secondary rounded-full">
                {moment(event?.startDateTime).format("MM/DD/YYYY, h:mm:ss a")}
              </p>
              <p className="pl-3 text-center font-semibold pr-3">-</p>
              <p className="pl-2 text-center font-semibold pr-2 bg-secondary whitespace-nowrap rounded-full">
                {moment(event?.endDateTime).format("MM/DD/YYYY, h:mm:ss a")}
              </p>
            </div>
            <div className="flex flex-col w-[70%] gap-6 mt-5">
              {event?.allowedPerson == joined && status !== "joined" ? (
                <p className="text-red-600 font-bold cursor-pointer hover:underline">
                  All Slots Filled
                </p>
              ) : status !== "joined" ? (
                <button
                  type=" button"
                  onClick={() => {
                    Join("joined");
                  }}
                  className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Join Now
                </button>
              ) : (
                <button
                  type=" button"
                  className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Joined
                </button>
              )}
              <button
                onClick={() => {
                  Join("leaved");
                }}
                className={`text-white w-full bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
              >
                Leave
              </button>
            </div>
            {!event?.parentEventId && (
              <div className="mt-4">
                <p
                  onClick={() => {
                    setState("subEvents");
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  View Sub Events
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default EventDetails;
