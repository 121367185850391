import { Avatar, Dropdown, Menu, Input, Pagination } from "antd";
import { useState } from "react";
import Card from "../dash/Card";
import totalEmp from "../../../imgs/totalEmp.png"
import empLeft from "../../../imgs/empOff.png"
import empAct from "../../../imgs/currEmp.png"
import { useNavigate } from "react-router-dom";
import AttendanceCards from "./AttendanceCards";

const columnHeaders = [
  "ID",
  "Name",
  "Position",
  "Department",
  "Type",
  "Note",
  "Request To",
  "Status",
  "Actions",
];

const staticData = [
  {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    type: "Leave",
    note: "123 Main St, Springfield, USA",
    // requestTo: "Manager",
    contact: "Manager",
  },
  {
    id: "2",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    type: "IOS Device",
    department: "IT",
    note: "456 Elm St, Springfield, USA",
    // requestTo: "Admin",
    contact: "Admin",
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    type: "Short Leave",
    department: "Medical",
    note: "789 Oak St, Springfield, USA",
    // requestTo: "Manager",
    contact: "Manager",
  },
  {
    id: "4",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Laptop",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    // requestTo: "Admin",
    contact: "Admin",
  },
  {
    id: "5",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Extra Time",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    // requestTo: "Admin",
    contact: "Admin",
  },
  {
    id: "6",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    position: "Product Manager",
    type: "Patrol Card",
    department: "Staff",
    note: "101 Maple St, Springfield, USA",
    // requestTo: "HR",
    contact: "HR",

  },
];

const cardData = [
  {
    label: "Total Employees",
    count: 420,
    icon: totalEmp
  },
  {
    label: "Current Employees",
    count: 400,
    icon: empAct
  },
  {
    label: "Employees Left",
    count: 20,
    icon: empLeft
  },
];

const StaticTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6); // Set the number of entries per page
  const router = useNavigate();
  const filteredData = staticData.filter((data) =>
    data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/EmployeeDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  const actionMenu = (id) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleAction("view", id)}>
        Accept
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleAction("edit", id)}>
        Reject
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleAction("remove", id)}>
        Transfer
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="h-[78vh] overflow-auto px-2">
      <div>
        <h2 className="text-xl font-semibold">Employee Requests</h2>
      </div>
      <div style={{margin:"20px 0px 20px 0px"}}>
      <AttendanceCards />
      </div>
      <div className="flex justify-between items-center mb-4">
        <div></div>
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-1/4"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columnHeaders.map((columnHeader, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap"
                >
                  {columnHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan="10" className="text-center text-gray-500 text-lg">
                  <p className="p-10">No data found</p>
                </td>
              </tr>
            ) : (
              currentData.map((data, index) => (
                <tr
                  key={index}
                  className="odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100 text-md"
                >
                  <td className="px-4 py-2 text-primary whitespace-nowrap">
                    {data.id}
                  </td>
                  <td className="px-4 flex items-center py-2 font-semibold text-primary whitespace-nowrap">
                    <Avatar
                      src={data.profile}
                      className="border-2 border-primary mr-2 w-10 h-10"
                    />
                    <p className="mr-2 text-primary capitalize">{data.name}</p>
                  </td>
                  <td className="px-4 py-2 text-primary whitespace-nowrap">
                    {data.position}
                  </td>
                  <td className="px-4 py-2 text-primary whitespace-nowrap">
                    {data.department}
                  </td>
                  <td className="px-4 py-2 text-primary whitespace-nowrap">
                    {data.type}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {data.note}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {data.contact}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {data.maritalStatus}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">{data.requestTo}</td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    <Dropdown overlay={actionMenu(data.id)} trigger={["click"]}>
                      <button className="text-primary bg-white border border-gray-300 px-3 py-1 rounded-md cursor-pointer">
                        Actions
                      </button>
                    </Dropdown>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="font-semibold pl-1 text-gray-600">
          Showing <b className="text-primary">{startIndex + 1}</b> to{" "}
          <b className="text-primary">{endIndex}</b> of{" "}
          <b className="text-primary">{filteredData.length}</b> entries
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default StaticTable;
