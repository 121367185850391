import { DatePicker, Select } from "antd";
import { useState, useMemo } from "react";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import trash from "../../../../../imgs/trash.png";
import { PlusCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import across from "../../../../../imgs/across.png";
import moment from "moment";
import cross from "../../../../../imgs/cross.png";
import { DeleteFilled } from "@ant-design/icons";
let file;
const IdentificationPage = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [display, setDisplay] = useState("");
  const [option, setOption] = useState(null);
  const [showErr, setShowErr] = useState(false);
  const [initial, setInitial] = useState(true);
  const [govId, setGovId] = useState("");
  const [govExp, setGovExp] = useState("");
  const [govCountry, setGovCountry] = useState("");
  const [govFront, setGovFront] = useState("");
  const [govBack, setGovBack] = useState("");
  const [passId, setPassId] = useState("");
  const [passExp, setPassExp] = useState("");
  const [passCountry, setPassCountry] = useState("");
  const [passFront, setPassFront] = useState("");
  const [passBack, setPassBack] = useState("");
  const [oneNo, setOneNo] = useState("");
  const [oneFront, setOneFront] = useState("");
  const options1 = useMemo(() => countryList().getData(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [iShow, setIShow] = useState(false);
  const [iShow1, setIShow1] = useState(false);
  const [iShow2, setIShow2] = useState(false);
  const [iShow3, setIShow3] = useState(false);
  const [iShow4, setIShow4] = useState(false);
  const [err, setErr] = useState("");
  const [birthNo, setBirthNo] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthFront, setBirthFront] = useState("");
  const [dirvNo, setDrivNo] = useState("");
  const [drivExp, setDrivExp] = useState("");
  const [dirvCountry, setDrivCountry] = useState("");
  const [drivFront, setDrivFront] = useState("");
  const [drivBack, setDrivBack] = useState("");
  const registration = useSelector((state) => state.option);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [GFront, setGFront] = useState("");
  const [GBack, setGBack] = useState("");
  const [PFront, setPFront] = useState("");
  const [PBack, setPBack] = useState("");
  const [DFront, setDFront] = useState("");
  const [DBack, setDBack] = useState("");
  const [BFront, setBFront] = useState("");
  const [NFront, setNFront] = useState("");
  const [oneExp, setOneExp] = useState(null);
  const [oneIssued, setOneIssued] = useState(null);
  const [oneCountry, setOneCountry] = useState(null);
  console.log(isLoading4);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleOptionDelete = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option)
    );
    if (option === "Government ID") {
      setGFront("");
      setGBack("");
      setDisplay("");
      setGovId("");
      setGovExp("");
      setGovCountry("");
      setGovFront("");
      setGovBack("");
    } else if (option === "Passport") {
      setPFront("");
      setPBack("");
      setPassId("");
      setPassExp("");
      setPassCountry("");
      setPassFront("");
      setPassBack("");
    } else if (option === "One Card") {
      setNFront("");
      setOneNo("");
      setOneFront("");
    } else if (option === "Driving License") {
      setDFront("");
      setDBack("");
      setDrivNo("");
      setDrivExp("");
      setDrivCountry("");
      setDrivFront("");
      setDrivBack("");
    } else if (option === "Birth certificate") {
      setBFront("");
      setBirthNo("");
      setBirthDate("");
      setBirthFront("");
    }
  };

  let optionI;
  optionI = [
    {
      label: "Government ID",
      value: "Government ID",
    },
    {
      label: "Passport",
      value: "Passport",
    },
    {
      label: "Driving License",
      value: "Driving License",
    },
    {
      label: "One Card",
      value: "One Card",
    },
  ];
  const imageHandler = (setState, setUrl, load) => async (e) => {
    load(true);
    file = e.target.files[0];
    if (file === undefined) {
      setIsLoading(false);
      setIsLoading1(false);
      setIsLoading2(false);
      setIsLoading3(false);
    } else {
      const base641 = await convertToBase64(file);
      setState(base641);
      var bodyFormData = new FormData();
      bodyFormData.append("file", file);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/merchat-files?fileType=merchantIdentificationDocument`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((data) => {
          setUrl(data.data.url);
          load(false);
          e.target.value = null;
        })
        .catch(() => {});
    }
  };
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const nextHandler = (e) => {
    e.preventDefault();
    const body = [];
    if (!govId && !passId && !dirvNo && !birthNo && !oneNo) {
      setErr(
        "Please provide one of Governament id, Passport, Birth Certificate"
      );
      setShowErr(true);
    } else {
      if (govId || govCountry || govExp || govFront !== "" || govBack !== "") {
        const govData = {
          type: "government id",
          docIDName: "govt id",
          docIDValue: `${govId}`,
          countryIssued: `${govCountry}`,
          docImages: [],
        };
        if (govFront !== "") {
          govData.docImages[0] = {
            type: "front image",
            imageUrl: `${govFront}`,
          };
        }
        if (govBack !== "") {
          govData.docImages[1] = {
            type: "back image",
            imageUrl: `${govBack}`,
          };
        }

        if (govData.docIDValue && govData.docImages.length < 2) {
          setErr("Please provide required images for the Government ID");
          setShowErr(true);
          return;
        }
        body.push(govData);
      }

      if (
        passId ||
        passCountry ||
        passExp ||
        passFront !== "" ||
        passBack !== ""
      ) {
        const passData = {
          type: "passport",
          docIDName: "passport no",
          docIDValue: `${passId}`,
          countryIssued: `${passCountry}`,
          expiryDate: `${passExp}`,
          docImages: [],
        };
        if (passFront !== "") {
          passData.docImages[0] = {
            type: "front image",
            imageUrl: `${passFront}`,
          };
        }
        if (passBack !== "") {
          passData.docImages[1] = {
            type: "back image",
            imageUrl: `${passBack}`,
          };
        }

        if (passData.docIDValue && passData.docImages.length < 2) {
          setErr("Please provide required images for the Passport");
          setShowErr(true);
          return;
        }
        body.push(passData);
      }
      if (oneFront !== "" || oneNo) {
        const oneData = {
          type: "one card",
          docIDName: "one card",
          docIDValue: `${oneNo}`,
          countryIssued: `${oneCountry}`,
          expiryDate: `${oneExp}`,
          issuedDate: `${oneIssued}`,
          docImages: [],
        };
        if (oneFront !== "") {
          oneData.docImages[0] = {
            type: "front image",
            imageUrl: `${oneFront}`,
          };
        }
        if (oneData.docIDValue && oneData.docImages.length === 0) {
          setErr("Please provide required image for the One Card");
          setShowErr(true);
          return;
        }

        body.push(oneData);
      }
      if (
        dirvNo ||
        dirvCountry ||
        drivExp ||
        drivFront !== "" ||
        drivBack !== ""
      ) {
        const drivData = {
          type: "driver license",
          docIDName: "drivers license no",
          docIDValue: `${dirvNo}`,
          countryIssued: `${dirvCountry}`,
          expiryDate: `${drivExp}`,
          docImages: [],
        };
        if (drivFront !== "") {
          drivData.docImages[0] = {
            type: "front image",
            imageUrl: `${drivFront}`,
          };
        }
        if (drivBack !== "") {
          drivData.docImages[1] = {
            type: "front image",
            imageUrl: `${drivBack}`,
          };
        }
        if (drivData.docIDValue && drivData.docImages.length < 2) {
          setErr("Please provide required images for the Driving License");
          setShowErr(true);
          return;
        }
        body.push(drivData);
      }
      if (birthNo || birthDate || birthFront !== "") {
        const birthData = {
          type: "birth certificate",
          docIDName: "birth cert",
          docIDValue: `${birthNo}`,
          issuedDate: `${birthDate}`,
          docImages: [],
        };
        if (birthFront !== "") {
          birthData.docImages[0] = {
            type: "front image",
            imageUrl: `${birthFront}`,
          };
        }
        if (birthData.docIDValue && birthData.docImages.length === 0) {
          setErr("Please provide required images for the Birth Certificate");
          setShowErr(true);
          return;
        }
        body.push(birthData);
      }

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-document`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch({ type: "IINCREMENT" });
        })
        .catch((err) => {
          if (
            err.response.data.message === '"expiryDate" must be a valid date'
          ) {
            setErr("Please verify if expiration date is available on card");
          } else {
            setErr(err.response.data.message);
          }

          setShowErr(true);
        });
    }
  };

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
    }
  }, []);

  const handleChange = (setState, check) => (event) => {
    if (event === null || event === undefined || event === "") {
      // Handle the case when the date picker is cleared
      setState(null);
      return;
    }
    if (check === "tg") {
      setState(event);
    } else if (check === "tgf") {
      let wow;
      if (event.$M <= 9) {
        if (event.$D <= 9) {
          wow = `${event.$y}-0${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-0${Number(event.$M) + 1}-${event.$D}`;
        }
      } else {
        if (event.$D <= 9) {
          wow = `${event.$y}-${Number(event.$M) + 1}-0${event.$D}`;
        } else {
          wow = `${event.$y}-${Number(event.$M) + 1}-${event.$D}`;
        }
      }
      setState(wow);
    } else {
      setState(event.target.value);
    }
  };
  const country1Handler = (setState, target) => {
    setState(target.label);
  };
  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date_raw = newDate.getDate();
    let month_raw = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var date, month;

    if (date_raw < 10) {
      date = "0" + date_raw.toString();
    } else {
      date = date_raw.toString();
    }
    if (month_raw < 10) {
      month = "0" + month_raw.toString();
    } else {
      month = month_raw.toString();
    }

    return (
      <div>
        {year}
        {separator}
        {month}
        {separator}
        {date}
      </div>
    );
  }

  const date = getCurrentDate();
  const disabledBeforeDate = moment(
    `${date.props.children[0]}-${date.props.children[2]}-${date.props.children[4]}`
  );
  const disabledDate = (current) => {
    return current && current < disabledBeforeDate;
  };
  const date1 = getCurrentDate();
  const disabledBeforeDate1 = moment(
    `${date1.props.children[0]}-${date1.props.children[2]}-${date1.props.children[4]}`
  );
  const disabledDate1 = (current) => {
    return current && current > disabledBeforeDate1;
  };
  return (
    <div className="flex items-center flex-col justify-center">
      <div className=" mt-6 sm:w-[60%] w-[80%] min-h-[70vh]">
        <h2
          className={`
              text-[0.9rem]
              
           text-center text-[#F25A29] font-semibold center mb-6`}
        >
          Please provide minimum of one identification detail of Merchant
          Owner.If you want to add more please select identification and click
          on Add Now
        </h2>
        <div className="flex justify-center mt-2 flex-col items-center">
          <div className="w-full flex items-center gap-4">
            <div className="w-3/4">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Select Document:
              </label>
              <Select
                placeholder="Document Type"
                optionFilterProp="children"
                // defaultValue={optionI[0].value}
                className="wow9"
                style={{ marginBottom: "1.2rem", marginTop: "5px" }}
                onChange={(val) => {
                  setOption(val);
                }}
                value={option}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="middle"
                options={optionI.filter(
                  (opt) => !selectedOptions.includes(opt.value)
                )}
              />
            </div>
            <button
              onClick={() => {
                setSelectedOptions([...selectedOptions, option]);
                setOption(null);
              }}
              className="w-1/4 lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2"
            >
              Add Now
            </button>
          </div>

          {selectedOptions.map((selectedOption, index) => (
            <div key={index} className="w-full">
              {/* Government ID */}
              {selectedOption === "Government ID" && (
                <div className="bg-gray-50 relative p-6 shadow-md rounded-lg mb-3">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                    <div className="flex flex-col mt-2">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Government ID
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="Government ID"
                        onChange={handleChange(setGovId)}
                        value={govId}
                        className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                      />
                    </div>

                    <div className="flex flex-col  mt-2">
                      <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Country Issued
                      </label>
                      <Select
                        placeholder="Country Issued"
                        optionFilterProp="children"
                        className="wow"
                        showSearch
                        style={{ marginBottom: "1.2rem" }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="middle"
                        options={options1}
                        onChange={(e, de) => country1Handler(setGovCountry, de)}
                        // onChange={handleChange(setGovCountry, "tg")}
                      />
                    </div>
                    <div>
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Image Front
                      </h2>
                      <label
                        htmlFor="special-input"
                        className={`${
                          isLoading || isLoading1 || isLoading2 || isLoading3
                            ? "pointer-events-none"
                            : ""
                        } ursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">Image Front</p>
                        </div>
                        <input
                          type="file"
                          className="hidden w-[100%] cursor-poniter"
                          id="special-input"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(
                            setGFront,
                            setGovFront,
                            setIsLoading
                          )}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                          GFront ? "" : "hidden"
                        }`}
                      >
                        <img
                          onClick={() => {
                            setIShow(true);
                          }}
                          className="w-[25rem] h-[13rem]"
                          src={GFront}
                          alt=""
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setDisplay("");
                            setGFront("");
                            setGovFront("");
                            setIsLoading(false);
                            setIsLoading1(false);
                            setIsLoading2(false);
                            setIsLoading3(false);
                            const wow =
                              document.getElementById("special-input");
                            wow.value = "";
                          }}
                        >
                          <img
                            className="sm:w-[3rem] w-[2rem]"
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          iShow ? "" : "hidden"
                        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                      >
                        <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                          <img
                            className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                            src={cross}
                            onClick={() => setIShow(false)}
                            alt=""
                          />
                          <img
                            className="w-[45rem] h-[28rem]"
                            src={GFront}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Image Back
                      </h2>
                      <label
                        htmlFor="special-input1"
                        className={`${
                          isLoading || isLoading1 || isLoading2 || isLoading3
                            ? "pointer-events-none"
                            : ""
                        } ursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">Image Back</p>
                        </div>
                        <input
                          type="file"
                          className="hidden w-[100%] cursor-poniter"
                          id="special-input1"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(
                            setGBack,
                            setGovBack,
                            setIsLoading1
                          )}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                          GBack ? "" : "hidden"
                        }`}
                      >
                        <img
                          onClick={() => {
                            setIShow1(true);
                          }}
                          className="w-[25rem] h-[13rem]"
                          src={GBack}
                          alt=""
                        />
                        {/* <div
                  className={`absolute ${
                    isLoading1 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div> */}
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setGBack("");
                            setGovBack("");
                            setIsLoading(false);
                            setIsLoading1(false);
                            setIsLoading2(false);
                            setIsLoading3(false);
                            const wow =
                              document.getElementById("special-input1");
                            wow.value = "";
                          }}
                        >
                          <img
                            className="sm:w-[3rem] w-[2rem]"
                            src={trash}
                            alt=""
                          />
                        </div>
                        <div
                          className={`${
                            iShow1 ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow1(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={GBack}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Passport */}
              {selectedOption === "Passport" && (
                <div className="bg-gray-50 relative p-6 shadow-md rounded-lg mb-3">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="grid sm:grid-cols-3 sm:gap-4 grid-cols-1 w-full">
                      <div className="flex flex-col  mt-2">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Passport
                        </label>
                        <input
                          type="text"
                          name=""
                          onChange={handleChange(setPassId)}
                          value={passId}
                          placeholder="Passport #"
                          className=" lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col  mt-2">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Expiration Date
                        </label>
                        <DatePicker
                          placeholder="Expiration Date"
                          style={{ marginBottom: "1.2rem", height: "45px" }}
                          disabledDate={disabledDate}
                          onChange={handleChange(setPassExp, "tgf")}
                          className="date-class"
                        />
                      </div>
                      <div className="flex flex-col  mt-2">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Country Issued
                        </label>
                        <Select
                          placeholder="Country Issued"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1.2rem" }}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={options1}
                          onChange={(e, de) =>
                            country1Handler(setPassCountry, de)
                          }
                          // onChange={handleChange(setPassCountry, "tg")}
                        />
                      </div>
                    </div>

                    <div>
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Image Front
                      </h2>
                      <label
                        htmlFor="special-input2"
                        className={`${
                          isLoading || isLoading1 || isLoading2 || isLoading3
                            ? "pointer-events-none"
                            : ""
                        } ursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">Image Front</p>
                        </div>
                        <input
                          type="file"
                          className="hidden w-[100%] cursor-poniter"
                          id="special-input2"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(
                            setPFront,
                            setPassFront,
                            setIsLoading2
                          )}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                          PFront ? "" : "hidden"
                        }`}
                      >
                        <img
                          onClick={() => {
                            setIShow2(true);
                          }}
                          className="w-[25rem] h-[13rem]"
                          src={PFront}
                          alt=""
                        />
                        {/* <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div> */}
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setPFront("");
                            setPassFront("");
                            setIsLoading(false);
                            setIsLoading1(false);
                            setIsLoading2(false);
                            setIsLoading3(false);
                            const wow =
                              document.getElementById("special-input2");
                            wow.value = "";
                          }}
                        >
                          <img
                            className="sm:w-[3rem] w-[2rem]"
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          iShow2 ? "" : "hidden"
                        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                      >
                        <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                          <img
                            className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                            src={cross}
                            onClick={() => setIShow2(false)}
                            alt=""
                          />
                          <img
                            className="w-[45rem] h-[28rem]"
                            src={PFront}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Signature page Image
                      </h2>
                      <label
                        htmlFor="special-input5"
                        className={`${
                          isLoading || isLoading1 || isLoading2 || isLoading3
                            ? "pointer-events-none"
                            : ""
                        } ursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">
                            Signature page Image
                          </p>
                        </div>
                        <input
                          type="file"
                          className="hidden w-[100%] cursor-poniter"
                          id="special-input5"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(
                            setPBack,
                            setPassBack,
                            setIsLoading3
                          )}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                          PBack ? "" : "hidden"
                        }`}
                      >
                        {/* <div
                  className={`absolute ${
                    isLoading3 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div> */}
                        <img
                          onClick={() => {
                            setIShow3(true);
                          }}
                          className="w-[25rem] h-[13rem]"
                          src={PBack}
                          alt=""
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setPBack("");
                            setPassBack("");
                            setIsLoading(false);
                            setIsLoading1(false);
                            setIsLoading2(false);
                            setIsLoading3(false);
                            const wow =
                              document.getElementById("special-input5");
                            wow.value = "";
                          }}
                        >
                          <img
                            className="sm:w-[3rem] w-[2rem]"
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          iShow3 ? "" : "hidden"
                        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                      >
                        <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                          <img
                            className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                            src={cross}
                            onClick={() => setIShow3(false)}
                            alt=""
                          />
                          <img
                            className="w-[45rem] h-[28rem]"
                            src={PBack}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full"></div>{" "}
                </div>
              )}

              {/* One Card     */}
              {selectedOption === "One Card" && (
                <div className="bg-gray-50 relative p-6 shadow-md rounded-lg mb-3">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          One Card
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder="One Card #"
                          onChange={handleChange(setOneNo)}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Country Issued
                        </label>
                        <Select
                          placeholder="Country Issued"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1.2rem" }}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={options1}
                          onChange={(e, de) =>
                            country1Handler(setOneCountry, de)
                          }
                          // onChange={handleChange(setDrivCountry, "tg")}
                        />
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Issue Date
                        </label>
                        <DatePicker
                          placeholder="Issue Date"
                          disabledDate={disabledDate1}
                          style={{ marginBottom: "1.2rem", height: "45px" }}
                          className="date-class"
                          onChange={handleChange(setOneIssued, "tgf")}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Expiration Date
                        </label>
                        <DatePicker
                          placeholder="Expiration Date"
                          disabledDate={disabledDate}
                          style={{ marginBottom: "1.2rem", height: "45px" }}
                          className="date-class"
                          onChange={handleChange(setOneExp, "tgf")}
                        />
                      </div>
                    </div>

                    <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                      Image Front
                    </h2>
                    <label
                      htmlFor="special-input0"
                      className={`${
                        isLoading1 || isLoading2 || isLoading3
                          ? "pointer-events-none"
                          : ""
                      } cursor-pointer relative`}
                    >
                      <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                        <div>
                          <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                        </div>
                        <p className="text-[#9CA6C2] ml-2">Image Front</p>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        id="special-input0"
                        accept="image/png, image/gif, image/jpeg"
                        onInput={imageHandler(
                          setNFront,
                          setOneFront,
                          setIsLoading4
                        )}
                      />
                    </label>
                    <div
                      className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                        NFront ? "" : "hidden"
                      }`}
                    >
                      <img
                        onClick={() => {
                          setIShow4(true);
                        }}
                        className="w-[25rem] h-[13rem]"
                        src={NFront}
                        alt=""
                      />
                      {/* <div
                    className={`absolute ${
                      isLoading4 ? "" : "hidden"
                    } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                  >
                    <Spin size="large" />
                  </div> */}
                      <div
                        className="absolute bottom-0 right-0 bg-white cursor-pointer"
                        onClick={() => {
                          setNFront("");
                          setOneFront("");
                          setIsLoading(false);
                          setIsLoading1(false);
                          setIsLoading2(false);
                          setIsLoading3(false);
                          const wow = document.getElementById("special-input0");
                          wow.value = "";
                        }}
                      >
                        <img
                          className="sm:w-[3rem] w-[2rem]"
                          src={trash}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        iShow4 ? "" : "hidden"
                      } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                    >
                      <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                        <img
                          className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                          src={cross}
                          onClick={() => setIShow4(false)}
                          alt=""
                        />
                        <img
                          className="w-[45rem] h-[28rem]"
                          src={NFront}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}

              {/*Driving License     */}
              {selectedOption === "Driving License" && (
                <div className="bg-gray-50 relative p-6 shadow-md rounded-lg mb-3">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="grid sm:grid-cols-3 sm:gap-4 grid-cols-1 w-full">
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Driver's License #
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder="Driver's License #"
                          onChange={handleChange(setDrivNo)}
                          value={dirvNo}
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Expiration Date
                        </label>
                        <DatePicker
                          placeholder="Expiration Date"
                          disabledDate={disabledDate}
                          style={{ marginBottom: "1.2rem", height: "45px" }}
                          className="date-class"
                          onChange={handleChange(setDrivExp, "tgf")}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Country Issued
                        </label>
                        <Select
                          placeholder="Country Issued"
                          optionFilterProp="children"
                          className="wow"
                          style={{ marginBottom: "1.2rem" }}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="middle"
                          options={options1}
                          onChange={(e, de) =>
                            country1Handler(setDrivCountry, de)
                          }
                          // onChange={handleChange(setDrivCountry, "tg")}
                        />
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Front
                        </h2>
                        <label
                          // htmlFor="special-input1"
                          className={`${
                            isLoading1 || isLoading2 || isLoading3
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Front</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            id="special-input1"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDFront,
                              setDrivFront,
                              setIsLoading1
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            DFront ? "" : "hidden"
                          }`}
                        >
                          <img
                            onClick={() => {
                              setIShow(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={DFront}
                            alt=""
                          />

                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setDFront("");
                              setDrivFront("");
                              setIsLoading1(false);
                              setIsLoading2(false);
                              setIsLoading3(false);
                              // const wow = document.getElementById("special-input1");
                              // wow.value = "";
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className={`${
                            iShow ? "" : "hidden"
                          } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                        >
                          <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                            <img
                              className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                              src={cross}
                              onClick={() => setIShow(false)}
                              alt=""
                            />
                            <img
                              className="w-[45rem] h-[28rem]"
                              src={DFront}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Image Back
                        </h2>
                        <label
                          // htmlFor="special-input2"
                          className={`${
                            isLoading1 || isLoading2 || isLoading3
                              ? "pointer-events-none"
                              : ""
                          } cursor-pointer relative`}
                        >
                          <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                            <div>
                              <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                            </div>
                            <p className="text-[#9CA6C2] ml-2">Image Back</p>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            // id="special-input2"
                            accept="image/png, image/gif, image/jpeg"
                            onInput={imageHandler(
                              setDBack,
                              setDrivBack,
                              setIsLoading2
                            )}
                          />
                        </label>
                        <div
                          className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                            DBack ? "" : "hidden"
                          }`}
                        >
                          {/* <div
                  className={`absolute ${
                    isLoading2 ? "" : "hidden"
                  } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                >
                  <Spin size="large" />
                </div> */}
                          <img
                            onClick={() => {
                              setIShow1(true);
                            }}
                            className="w-[25rem] h-[13rem]"
                            src={DBack}
                            alt=""
                          />
                          <div
                            className="absolute bottom-0 right-0 bg-white cursor-pointer"
                            onClick={() => {
                              setDBack("");
                              setDrivBack("");
                              setIsLoading1(false);
                              setIsLoading2(false);
                              setIsLoading3(false);
                              // const wow = document.getElementById("special-input2");
                              // wow.value = "";
                            }}
                          >
                            <img
                              className="sm:w-[3rem] w-[2rem]"
                              src={trash}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              )}

              {/* Birth certificate     */}
              {selectedOption === "Birth certificate" && (
                <div className="bg-gray-50 relative p-6 shadow-md rounded-lg mb-3">
                  <h1 className="text-center text-[1.1rem] mb-2 font-semibold text-[#596F96]">
                    {selectedOption}
                  </h1>
                  <DeleteFilled
                    style={{ fontSize: "20px", color: "text-primary" }}
                    onClick={() => handleOptionDelete(selectedOption)}
                    className="absolute top-3 right-3 cursor-pointer"
                  />
                  <div className="w-full">
                    <div className="grid sm:grid-cols-2 sm:gap-4 grid-cols-1 w-full">
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Birth Certificate #
                        </label>
                        <input
                          type="text"
                          name=""
                          onChange={handleChange(setBirthNo)}
                          value={birthNo}
                          placeholder="Birth Certificate #"
                          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                          Date Issued
                        </label>
                        <DatePicker
                          placeholder="Date Issued"
                          disabledDate={disabledDate1}
                          onChange={handleChange(setBirthDate, "tgf")}
                          style={{ marginBottom: "1.2rem", height: "45px" }}
                          className="date-class"
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                        Image Front
                      </h2>
                      <label
                        // htmlFor="special-input"
                        className={`${
                          isLoading1 || isLoading2 || isLoading3
                            ? "pointer-events-none"
                            : ""
                        } cursor-pointer relative`}
                      >
                        <div className="lg:mb-5 lg:text-[1.1rem] w-[100%] bg-[white] border-[1px] border-[#c6cfe5] rounded-[0.5rem] p-2 mb-4 flex items-center">
                          <div>
                            <PlusCircleFilled className="text-primary text-2xl flex items-center justify-center" />
                          </div>
                          <p className="text-[#9CA6C2] ml-2">Image Front</p>
                        </div>
                        <input
                          type="file"
                          className="hidden"
                          // id="special-input"
                          accept="image/png, image/gif, image/jpeg"
                          onInput={imageHandler(
                            setBFront,
                            setBirthFront,
                            setIsLoading3
                          )}
                        />
                      </label>
                      <div
                        className={`relative mb-4 flex items-center justify-center bg-[#F8F8F8] ${
                          BFront ? "" : "hidden"
                        }`}
                      >
                        {/* <div
                    className={`absolute ${
                      isLoading3 ? "" : "hidden"
                    } w-[100%] top-0 h-[100%] bg-slate-400 bg-opacity-[50%] flex justify-center items-center`}
                  >
                    <Spin size="large" />
                  </div> */}
                        <img
                          onClick={() => {
                            setIShow2(true);
                          }}
                          className="w-[25rem] h-[13rem]"
                          src={BFront}
                          alt=""
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-white cursor-pointer"
                          onClick={() => {
                            setBFront("");
                            setBirthFront("");
                            setIsLoading1(false);
                            setIsLoading2(false);
                            setIsLoading3(false);
                            const wow =
                              document.getElementById("special-input");
                            wow.value = "";
                          }}
                        >
                          <img
                            className="sm:w-[3rem] w-[2rem]"
                            src={trash}
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          iShow2 ? "" : "hidden"
                        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center z-[100]`}
                      >
                        <div className="relative rounded-xl flex items-center p-8 bg-white flex-col">
                          <img
                            className="scale-[0.7] absolute top-2 right-2 cursor-pointer"
                            src={cross}
                            onClick={() => setIShow2(false)}
                            alt=""
                          />
                          <img
                            className="w-[45rem] h-[28rem]"
                            src={display}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center sm:w-[60%] w-[80%]`}
      >
        <button
          // type="submit"
          onClick={() =>
            // dispatch({ type: "IINCREMENT" })
            {
              dispatch({ type: "ISET", num: 0 });
              router("/SuperAdmin/Inventory/VendorListing");
            }
          }
          className={`lg:text-[1.1rem] bg-gradient-to-tr bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
        >
          Next
        </button>
      </div>
      {/* <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div> */}
      <div
        className={`${
          showErr ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShowErr(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShowErr(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdentificationPage;
