import Ellipse from "../../../imgs/backg.svg";
import Vegetables from "../../../imgs/Vegetables.png";
import Fruits from "../../../imgs/Fruits.png";
import Meat from "../../../imgs/Meat.png";
import Sea from "../../../imgs/Sea.png";
import back5 from "../../../imgs/back5.png";
import Bakery from "../../../imgs/Bakery.png";
import Dairy from "../../../imgs/Dairy.png";
import Juices from "../../../imgs/Juices.png";
import Snacks from "../../../imgs/Snacks.png";
import img from "../../../imgs/impLogo.png";
import { useNavigate } from "react-router";
import { message } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCategory from "./addCategory";
let items;
const AdminPanel = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const router = useNavigate();
  const PlaceOrderCheck = useSelector((state) => state.PlaceOrderCheck);
  
  const role = useSelector((state) => state.role);
  const [messageApi, contextHolder] = message.useMessage();
  const [addCategory, setAddCategory] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  items = [
    {
      key: "1",
      title: `Vegetables`,
      img: Vegetables,
      items: "47",
      route: "/Inventory-Products",
    },
    {
      key: "2",
      title: `Fruits`,
      img: Fruits,
      items: "32",
      route: "/Inventory-Products",
    },
    {
      key: "3",
      title: `Meat`,
      img: Meat,
      items: "43",
      route: "/Inventory-Products",
    },
    {
      key: "4",
      title: `Sea Food`,
      img: Sea,
      items: "65",
      route: "/Inventory-Products",
    },
    {
      key: "5",
      title: `Bakery`,
      img: Bakery,
      items: "11",
      route: "/Inventory-Products",
    },
    {
      key: "6",
      title: `Dairy`,
      img: Dairy,
      items: "12",
      route: "/Inventory-Products",
    },
    {
      key: "7",
      title: `Juices`,
      img: Juices,
      items: "55",
      route: "/Inventory-Products",
    },
    {
      key: "8",
      title: `Snacks`,
      img: Snacks,
      items: "56",
      route: "/Inventory-Products",
    },
  ];

  return (
    <div>
      <div>
        {contextHolder}
        <div className={`flex items-center justify-center bg-[#D9EFED]`}>
          <div className="md:w-[95%] mt-[1rem] rounded-md w-[95%] px-20">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor") {
                    router("/VendorDashboard");
                    return;
                  }

                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Categories
              </h2>
            </div>
            <div className="flex justify-end mt-4 gap-3">
              <div className="flex items-center bg-[#f9fbfb] border border-gray-300 rounded-md px-4 py-3 w-[300px] shadow-sm">
                <input
                  type="text"
                  placeholder="Search Category"
                  className="bg-transparent focus:outline-none text-gray-500 placeholder-gray-400 flex-grow"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-[#00b0a6]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 19a8 8 0 100-16 8 8 0 000 16zm5-1l5 5"
                  />
                </svg>
              </div>

              {user === "vendor" && (
                <button
                  type="button"
                  onClick={() => {
                    setAddCategory(true);
                    // router("/Inventory-AddProduct");
                  }}
                  className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                >
                  Add Category
                </button>
              )}
            </div>

            <div className="mt-3  rounded-md  p-[4rem] pt-[2rem] mb-16  sm:min-h-[360px] bg-[#F6F4F4] shadow-lg">
              <div className="w-full flex flex-wrap mt-[4.5rem] justify-center items-center gap-8 md:gap-x-8 gap-y-10 sm:min-h-[200px]">
                {items?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (item.route === "") {
                        Success();
                      } else {
                        if (PlaceOrderCheck) {
                          dispatch({ type: "Cat", num: item.title });
                          router("/SuperAdmin/Inventory/SelectVendor");
                          return;
                        }
                        dispatch({ type: "Cat", num: item.title });
                        router(item?.route);
                      }
                    }}
                    className="w-[160px] rounded-md mb-[30px] h-[150px] p-2 cursor-pointer  hover:bg-[#8BD6B5] bg-[#E9F8EE] hover:text-white custom-shadow"
                  >
                    <div className="w-full h-[80px] flex items-center justify-center rounded-xl">
                      <img
                        src={item?.img}
                        alt=""
                        className="h-[60px] w-[60px]"
                      />
                    </div>
                    <div className="flex items-center justify-center flex-col w-full">
                      <div className="flex items-center h-full gap-y-3 justify-center w-full flex-col">
                        <div className="text-center h-[20px] ">
                          <h1
                            className="text-black text-[17px] hover:text-white font-bold"
                            style={{ fontFamily: "poppins", fontWeight: "600" }}
                          >
                            {item?.title}
                          </h1>
                          <h1
                            className="text-gray-500 text-[15px] font-bold"
                            style={{ fontFamily: "poppins", fontWeight: "600" }}
                          >
                            {item?.items} Items
                          </h1>
                        </div>
                        {/* <div className="text-center min-h-[60px]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {showAllContentIndex === index ||
                        item?.content.length <= 45
                          ? item?.content
                          : `${item?.content.substring(0, 45)}...`}
                        {item?.content.length > 45 && (
                          <p
                            className="text-[#F35929] z-10"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleContent(index);
                            }}
                          >
                            {showAllContentIndex === index
                              ? "Read Less"
                              : "Read More"}
                          </p>
                        )}
                      </p>
                    </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addCategory && <AddCategory setShow={setAddCategory} />}
    </div>
  );
};

export default AdminPanel;
