import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Initial from "./pages/initial";
import Dashboard from "./pages/dashboard";
import ApplicationPage from "./pages/ApplicationPage";
import BeneficiaryPage from "./pages/BeneficiaryPage";
import ListingPage from "./pages/listingPage";
import ListingGro from "./pages/groAdminListing";
import WalletDash from "./pages/ewalletDash.js";
import Wallet from "./pages/wallet.js";
import "./App.css";
import EDashboard from "./pages/Edashoard";
import PensionL from "./pages/PensionL";
import BeneficiaryP from "./pages/BeneficiaryP";
import ApplicationsP from "./pages/ApplicationsP";
import PensionP from "./pages/PensionP";
import Account from "./pages/Account";
import AboutPage from "./pages/About";
import BAccount from "./pages/BAccount";
import PAccount from "./pages/PAccount";
import AAccount from "./pages/AAccount";
import ViewPage from "./pages/ViewPage";
import PViewPage from "./pages/PViewCard";
import CardPage from "./pages/cardPage";
import CardBackPage from "./pages/cardBackPage";
import Transactions from "./pages/Transactions";
import History from "./pages/history";
import HistoryStatus from "./pages/historyStatus";
import PListingGRO from "./pages/pListingGRO";
import GroEmployees from "./pages/groAdminEListing";
import GroAdminDash from "./pages/groAdminDash";
import GroEprofileEdit from "./pages/GroEaccount";
import PProfileGRO from "./pages/pProfileGRO";
import EProfileGROAdmin from "./pages/groAdminEprof";
import EViewGROAdmin from "./pages/groAdminEview.js";
import ContactPage from "./pages/ContactPage";
import PolPage from "./pages/polPage";
import StatusPage from "./pages/StatusPage";
import MapsPage from "./pages/mapsPage";
import Protected from "./components/protectedRoutes";
import { useSelector } from "react-redux";
import AddPensionerPage from "./pages/AddPensionerPage";
import DistriutionsPage from "./pages/DistributionsPage";
import AllApplications from "./pages/AllApplications";
import ProfPage from "./pages/ProfPage";
import PowerBiPage from "./pages/PowerBi";
import Tablo from "./pages/tablo.js";
import AditionalDocuments from "./pages/AditionalDocuments";
import AllEmployee from "./pages/AllEmployeePages";
import AdminPanel from "./pages/adminPanel";
import PowerBiLinkPage from "./pages/PowerBi1";
import RolesPrivileges from "./pages/rolesPrivileges";
import EmployeeProfile from "./pages/EmployeeProfile";
import ViewEmployee from "./pages/EmployeeCardView";
import EditEmployee from "./pages/EditEmployee";
import Video from "./pages/VideoMeeting";
import AddNewBank from "./pages/AddNewBank";
import AddAdminPage from "./pages/AddAdminPage";
import AddGroAdmin from "./pages/addGroAdmin";
import AddGroEmp from "./pages/addGroEmp";
import Notifications from "./pages/notifications.js";
// import firebase from "./utlis/firebase"
import ChatPanel from "./pages/ChatPanel";
import Notification from "./components/Notification/index.js";
import Program from "./pages/Programs.js";
// import MerchantDashboard from "./pages/MerchantDashboard.js"
import MerchantDashboard from "./components/Merchant/index.js";
import PensionerMerchant from "./components/Merchant/Pensioner/index.js";
import CashGrant from "./pages/CashGrant.js";
import TransactionHistory from "./components/Merchant/Transactions";
import MerchantReports from "./components/Merchant/Reports";
import SingleReport from "./components/Merchant/Reports/SingleReport";
import MerchantProfile from "./components/Merchant/Profile";
import PensionerProfileMerchant from "./components/Merchant/Pensioner/Profile";
import PensionerVouchersMerchant from "./components/Merchant/Pensioner/Voucher";
import PensionerPayMerchant from "./components/Merchant/Pensioner/Pay";
import PensionerVerifyMerchant from "./components/Merchant/Pensioner/Verify";
import PensionerVerifyQuestionsMerchant from "./components/Merchant/Pensioner/questions/index.jsx";
import PensionerRecieptMerchant from "./components/Merchant/Pensioner/Reciept";
import ContactPerson from "./components/Merchant/Register/ThirdStepContactPerson.jsx";
import ImageClassification from "./pages/ImageClassification.js";
// import PensionerTransactionDetail from "./components/Merchant/Transactions/TransactionDetail";
import CompareImage from "./pages/CompareImage.js";
import SelectVouchers from "./components/Merchant/Pensioner/NewPay";
import MhsssDash from "./pages/mhsssDash";
import MhsssPayment from "./pages/mhsssPayment.js";
import MhsssOutStanding from "./pages/mhsssoutstanding.js";
import PersonOutstanding from "./components/MHSSS Accounting/outstanding/PersonTransaction";
import MhsssPayDetals from "./pages/mhssspaydetails.js";
import Vouchers from "./pages/mhsssvouchers.js";
import PayOutStandings from "./pages/mhsssPayOutstanding.js";
// import EWallet from "./components/EWallet1"
import PaymentReceipt from "./pages/mhsssReceipt.js";
import CashPay from "./pages/mhsssCashPay.js";
import EWallet from "./components/EWallet1/Dashboard";
import SAPMoneyProfile from "./components/EWallet1/Profile";
import SAPMoneyTransactions from "./components/EWallet1/Transactions";
import SAPMoneyNotifications from "./components/EWallet1/Notifications";
import SAPMoneyBanking from "./components/EWallet1/Banking";
// import SAPMoneyWallet from "./components/EWallet1/Wallet";
import SAPMoneyAddBank from "./components/EWallet1/Banking/AddBank";
import SAPPayMobile from "./components/EWallet1/PayPhoneNumber";
import QuickPay from "./components/EWallet1/QuickPay";
import AttendanceEmployees from "./components/attendance/employees/dashboard.jsx";
import ERequests from "./components/attendance/eRequests/dashboard.jsx";
import AttendanceEmployeesDetails from "./components/attendance/employees/empDetails/index.jsx";
import Attendance from "./components/attendance/attendance/index.jsx";
import AttendanceDash from "./components/attendance/dash/dashboard.jsx";
import ChildResponseQuickPay from "./components/EWallet1/QuickPay/childResponse";
import ChildResponseRequestMoney from "./components/EWallet1/requestMoney/childResponse";
import PensionerResponseRequestMoney from "./components/EWallet1/requestMoney/childResponse";
import AddMerchantEmployee from "./components/Merchant/Employee/Registration/index.jsx";
import EwalletCashPay from "./pages/mhsssEwalletCash.js";
import EwalletPay from "./pages/mhsssewalletPay.js";
import ChequePay from "./pages/mhsssChequePay.js";
import BankPay from "./components/MHSSS Accounting/outstanding/PayOutstanding/Bank";
import TransactionMethod from "./components/MHSSS Accounting/transactionMethod";
import MhhsMerchantProfile from "./components/MHSSS Accounting/merchantProfile";
import Reports from "./pages/mhsssReports.js";
import Notificationsmhsss from "./pages/mhsssNotifications.js";
import Pfinancials from "./pages/mhsssPlistings.js";
import Ppayment from "./pages/mhsssPensionerPayment.js";
import MhsssProfile from "./pages/mhsssProfile.js";
import Banking from "./pages/mhsssBanking.js";
import BankingStatment from "./pages/mhsssBstatment.js";
import MerchantListing from "./pages/MerchantEmployees.js";
import MerchantEmployeeProfile from "./pages/MerchantEmployeeProfile.js";
import MerchantNearMe from "./pages/MerchantNearBy.js";
import MerchantOwnEmployees from "./components/Merchant/Employee";
import MerchantEmployeeRolesAndPrivileges from "./components/Merchant/Employee/RolesAndPrivileges";
import PensionerTransactionDetail from "./pages/transactionDetail.js";
import DetailTransactionMerchant from "./components/Merchant/Transactions/transDetail";
import MerchantMhsssPayment from "./components/Merchant/mhsssPayment";
import MerchantMhsssPaymentDetail from "./components/Merchant/mhsssPayment/detail";
import MhsssGov from "./pages/mhsssGovAgency.js";
import MhsssGovDetails from "./pages/mhsssGovAgencyDetails.js";
import MhsssGovDetailsListing from "./pages/mhsssGovAgencyDetailsListing.js";
import MhsssGovRequests from "./pages/mhsssGovRequest.js";
import MhsssGovRequestsDetails from "./components/MHSSS Accounting/govAgencies/govBalance/requests/detailIndex.jsx";
import MhsssGovAddMoney from "./pages/mhsssGovAgencyMoney.js";
import MhsssGovVCash from "./pages/mhsssGovVcash.js";
import Biometric from "./pages/biometric.js";
import BiometricAdd from "./pages/biometricAdd.js";
import Events from "./pages/eventsListing.js";
import EventsFav from "./pages/eventsFavlisting.js";
import EventUpdate from "./pages/eventUpdate.js";
import AddEvents from "./pages/addEvent.js";
import EventDetails from "./pages/eventDetails.js";
import Maintenance from "./pages/maintenanceInitial.js";
import MaintenanceVList from "./pages/maintenanceVListing.js";
import MaintenanceDList from "./pages/maintenanceDListing.js";
import MaintenanceVanList from "./pages/maintenanceVenListing.js";
import MaintenanceTripsList from "./pages/maintenanceTripsListing.js";
import MaintenanceMList from "./pages/maintenanceMListing.js";
import MaintenanceAddVehical from "./pages/maintenanceAddVehical.js";
import MaintenanceAddDriver from "./pages/maintenanceAddDriver.js";
import MaintenanceAddVendor from "./pages/maintenanceAddVendor.js";
import Inventory from "./pages/inventoryInitial.js";
import InventoryCat from "./pages/inventoryCategories.js";
import InventoryProduct from "./pages/inventoryProduct.js";
import InventoryPro from "./pages/InventoryPro.js";
import AppHistory from "./pages/historyApp.js";
import InventoryVPro from "./pages/inventoryVProductDetails.js";
import InventoryVDash from "./pages/inventoryVendorDashboard.js";
import Cart from "./pages/inventoryCart.js";
import Buy from "./pages/inventoryBuy.js";
import PlaceOrder from "./pages/inventoryPlaceOrder.js";
import Vendors from "./pages/inventoryVendor.js";
import Orders from "./pages/inventoryVOrders.js";
import OrderDetail from "./components/inventory/vendor/orders/detail";
import Favorite from "./pages/inventoryFav.js";
import VendorProducts from "./pages/inventoryVproduct.js";
import PlaceOrderBuy from "./pages/inventoryBuyCheckout.js";
import MHSSSMerchantProfile from "./components/MHSSS Accounting/transactionMethod/profile";
import AddSapMoney from "./components/EWallet1/merchant/addSapMoney";
import MerchantSapMoney from "./components/Merchant/sapMoney/main.jsx";
import SelectVouchersSapMoney from "./components/EWallet1/merchant/voucherCashing/selectVoucher/index.jsx";
import SapMoneyPensionerRecieptMerchant from "./components/EWallet1/merchant/voucherCashing/reciept/index.jsx";
import SelectTypeSapMoney from "./components/EWallet1/merchant/voucherCashing/selectType";
import VerifySapMoney from "./components/EWallet1/merchant/voucherCashing/verification";
import PaySapMoney from "./components/EWallet1/merchant/voucherCashing/paymentScreen";
import AllVouchersSapMoney from "./components/EWallet1/merchant/voucherCashing/allVouchers";
import SelectStorePensioner from "./components/Merchant/Pensioner/selectStore";
import RequestMoney from "./components/EWallet1/requestMoney";
import QuickPayMerch from "./pages/merchantQuick.js";
import AddProduct from "./pages/inventoryAddProduct.js";
import SapPensionerAllTransactions from "./components/EWallet1/Transactions/pensioner";
import SapPensionerAllRequest from "./components/EWallet1/request/pensioner";
import SapMerchantAllRequest from "./components/EWallet1/request/merchant";
import SapWalletPesnionerTransactions from "./components/EWallet1/Transactions/pensioner/allTransaction";
import BookSlot from "./pages/pensionerDashVerify.js";
import AllSlots from "./pages/pensionerDashVeriSlots.js";
import ProgramTransactions from "./components/EWallet1/programTransaction";
import AllProgramTransactions from "./components/EWallet1/programTransaction/allprogramTransactions";
import GeneralTransactionDetail from "./components/EWallet1/Transactions/pensioner/detailIndex.jsx";
import ProgramTransactionDetail from "./components/EWallet1/Transactions/pensioner/proDetailIndex.jsx";
import MHSSSTransactionDetail from "./components/EWallet1/Transactions/pensioner/mhsssDetailIndex.jsx";
import MerchantPayMoney from "./components/EWallet1/PayPhoneNumber/merchantPayment";
import ParentRespondToRequest from "./components/EWallet1/QuickPay/merchantQuickPay/index.jsx";
import RequestDetail from "./components/EWallet1/request/pensioner/detailIndex.jsx";
import SapMoneyMHSSSMerchantListing from "./components/MHSSS Accounting/sapWallet/merchantListing";
import SapMoneyMHSSSMerchantOutstanding from "./components/MHSSS Accounting/sapWallet/personTransaction/index.jsx";
import SapMoneyMHSSSMerchantVouchers from "./components/MHSSS Accounting/sapWallet/mhsssVouchers/index.jsx";
import SapMoneyMHSSSMerchantPayment from "./components/MHSSS Accounting/sapWallet/payment/index.jsx";
import SapMoneyMHSSSMerchantReceipt from "./components/MHSSS Accounting/sapWallet/receipt/index.jsx";
import SapMoneyRequestPensionerVerification from "./components/EWallet1/request/merchant/verfication.jsx";
import SapMoneyRequestPensionerListing from "./components/EWallet1/request/merchantRespondPensioner";
import PensionerVerificationSelectLocation from "./components/pensionerVerification/selectLocation.jsx";
import PensionerVerificationSchedulingSelectLocation from "./components/schduleVerification/selectLocation.jsx";
import PensionerVerificationSelectProgram from "./components/pensionerVerification/selectProgram.jsx";
import PensionerVerificationSelectedProgram from "./components/pensionerVerification/selectedProgramScreen.jsx";
import PensionerVerificationAllAppointments from "./components/pensionerVerification/appointments/allAppointments.jsx";
import AppointmentDetails from "./components/pensionerVerification/appointments/profile.jsx";
import AppointmentSearch from "./components/pensionerVerification/appointments/search.jsx";
import PensionerVerificationListing from "./components/pensionerVerification/listing.jsx";
import PensionerVerificationListingSearchResult from "./components/pensionerVerification/searchResult.jsx";
import PensionerVerificationProfile from "./components/pensionerVerification/profile.jsx";
import VerifyBasicInfo from "./components/pensionerVerification/verifications/basic.jsx";
import VerifyIdentification from "./components/pensionerVerification/verifications/identification.jsx";
import UpdateIdentification from "./components/pensionerVerification/verifications/identificationUpdate.jsx";
import AddIdentification from "./components/pensionerVerification/verifications/newIdentification.jsx";
import BiometricVerification from "./components/pensionerVerification/verifications/biometrics.jsx";
import BiometricUpdate from "./components/pensionerVerification/verifications/biometricUpdate.jsx";
import VerifyPriorityPensioners from "./components/pensionerVerification/priorityPensioners";
import PensionBookVerification from "./components/pensionerVerification/verifications/pensionbook.jsx";
import VerifyPriorityRequest from "./components/pensionerVerification/priorityRequests";
import VerifyPriorityRequestDetails from "./components/pensionerVerification/priorityRequests/detailIndex.jsx";
import SetTransactionLimits from "./pages/transactionLimits.js";
import Questions from "./components/secuirtyQuestions/questionPage.jsx";
import MerchantReqMHSSS from "./components/Merchant/govAgency/requestMoney";
import SchedualHistory from "./components/schduleVerification/history/schedules.jsx";
import SchedualHistoryDetails from "./components/schduleVerification/history/schedulesDetails.jsx";
import SchedualEmployeeDetails from "./components/schduleVerification/history/employeeDetails.jsx";
import SchedualPensionerDetails from "./components/schduleVerification/history/PensionerDetails.jsx";
import MHSSSPayRequestMoney from "./components/MHSSS Accounting/govAgencies/govBalance/requests/paymentIndex.jsx";
import MHSSSGovAgencyCreditDetails from "./components/MHSSS Accounting/govAgencies/govtAgencyDetails/creditTransactions";
import MerchantReqMHSSSHistory from "./components/Merchant/govAgency/requestMoney/history";
import MerchantReqMHSSSHistoryDetail from "./components/Merchant/govAgency/requestMoney/history/detail";
import SchedulePensionerVerificationSelectProgram from "./components/schduleVerification/selectProgram.jsx";
import VerificationEditSchedule from "./components/schduleVerification/history/editSchedule.jsx";
import SchedulePensionerVerificationSelectEmployees from "./components/schduleVerification/selectEmployees.jsx";
import InventoryVendorReg from "./pages/inventoryVendorReg.js";
import SchedulePensionerVerificationSelectedEmployees from "./components/schduleVerification/selectedEmployees.jsx";
import PensionerVerificationSchedulingStatus from "./components/schduleVerification/verificationStatus.jsx";
import RevokeAccessSetting from "./components/accessSetting/revokeAccess/index.jsx";
import AccessSetting from "./components/accessSetting";
import HolidaySetting from "./components/holidaySetting";
import Gallary from "./pages/eventGallary.js";
import EventInvite from "./components/events/invite/index.jsx";
import SchedulerTimeSetting from "./components/schedulerSetting";
import VendorDashboard from "./components/inventory/vendor/vendorDashboard";
import SuperAdminInventoryVDash from "./components/inventory/superAdmin/dashboard";
import SuperAdminInventoryVendorListing from "./components/inventory/superAdmin/vendor";
import SuperAdminInventorySelectVendor from "./components/inventory/superAdmin/vendor/selectVendor";
// import VendorProductPage from "./components/inventory/vendor/products/index.jsx"
// import Test from "./pages/testtest.jsx";
import axios from "axios";
import AttendanceDashBoard from "./components/attendance/AttendanceDashBoard/AttendanceDashBoard.jsx";
import Devices from "./components/attendance/Devices/Devices.jsx";
import PensionerVerificationSearchDistribution from "./components/pensionerVerification/searchDistribution.jsx";
import AddVendor from "./components/inventory/superAdmin/vendor/addVendor.jsx";

localStorage.setItem("authen", "false");
function App() {
  // useEffect(()=>{
  //   const msg=firebase.messaging();
  //   msg.requestPermission().then(()=>{
  //     return msg.getToken();
  //   }).then((data)=>{
  //     console.warn("token",data)
  //   })
  // })
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const router = useNavigate();
  // const fetchUpdatedPrivileges = () => {
  //   if (dataUser?.data?.user?.roles[0] === "employee") {
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/v1/privileges/my-privileges`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${dataUser.data.tokens.access.token}`,
  //           },
  //         }
  //       )
  //       .then((data) => {
  //         const privileges = data?.data.map(item => item.privilege);
  //         dispatch({ type: "myPrivileges", num: privileges });

  //       })

  //       .catch((err) => {
  //       });
  //   }
  // };

  // useEffect(() => {
  //   // Initial fetch of privileges on login
  //   const checkUser = localStorage.getItem("authen");
  //   if(checkUser ===  'true'){
  //     fetchUpdatedPrivileges();
  //   }

  //  // Set up a timer to periodically fetch privileges (every 1 minute)
  //  const timer = setInterval(fetchUpdatedPrivileges, 60 * 1000);

  //   // Clean up the timer when the component unmounts
  //   if(checkUser ===  'true'){
  //     return () => clearInterval(timer);
  //   }
  // }, []);
  const location = useLocation();
  const dash = location.pathname;
  const authState = useSelector((state) => state.authState);
  // const token = useSelector((state) => state.token);
  // const tokenRef = useSelector((state) => state.tokenRef);
  // const privilege = useSelector((state) => state.myPrivileges);
  // useEffect(() => {
  //   if (user === "superadmin" && dash === "/" && authState) {
  //     router("/superAdminDashboard");
  //   } else if (user === "pensioner" && dash === "/" && authState) {
  //     router("/Dashboard");
  //   } else if (user === "employee" && dash === "/" && authState) {
  //     router("/EDashboard");
  //   } else if (user === "admin" && dash === "/" && authState) {
  //     router("/adminDashboard");
  //   } else if (user === "merchant-admin" && dash === "/" && authState) {
  //     router("/Merchant/dashboard");
  //   } else if (user === "merchant-owner" && dash === "/" && authState) {
  //     router("/Merchant/dashboard");
  //   }
  //   if (user === "merchant-employee" && dash === "/" && authState) {
  //     router("/Merchant/dashboard");
  //   } else if (user === "gro-employee" && dash === "/" && authState) {
  //     router("/GRO");
  //   } else if (user === "gro-admin" && dash === "/" && authState) {
  //     router("/GroAdminDash");
  //   }
  // }, []);
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIp();
  }, []);

  const isAuthenticated = authState;

  const handleRedirect = () => {
    if (isAuthenticated === "false") {
      return <Initial />;
    } else {
      switch (user) {
        case "admin":
          return <Navigate to="/adminDashboard" />;
        case "superadmin":
          return <Navigate to="/superAdminDashboard" />;
        case "pensioner":
          return <Navigate to="/Dashboard" />;
        case "employee":
          return <Navigate to="/EDashboard" />;
        case "gro-admin":
          return <Navigate to="/GroAdminDash" />;
        case "gro-employee":
          return <Navigate to="/GRO" />;
        default:
          return <Initial />;
      }
    }
  };
  return (
    <div>
      <Routes>
        <Route path="/" element={<Initial />} exact></Route>
        {/* <Route path="/test" element={<Test />} exact></Route> */}
        <Route
          path="/superadmin/setTransactionLimits"
          element={
            <Protected isSignedIn={authState}>
              <SetTransactionLimits />
            </Protected>
          }
          // exact
        ></Route>
        <Route
          path="/Dashboard"
          element={
            <Protected isSignedIn={authState}>
              <Dashboard />
            </Protected>
          }
          // exact
        ></Route>

        <Route
          path="/Edashboard"
          element={
            <Protected isSignedIn={authState}>
              <EDashboard />
            </Protected>
          }
          // exact
        ></Route>
        <Route
          path="/dashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/dashboard"
          element={
            <Protected isSignedIn={authState}>
              <MhsssDash />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/payment"
          element={
            <Protected isSignedIn={authState}>
              <MhsssPayment />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/payment/detals"
          element={
            <Protected isSignedIn={authState}>
              <MhsssPayDetals />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/outstanding"
          element={
            <Protected isSignedIn={authState}>
              <MhsssOutStanding />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/vouchers"
          element={
            <Protected isSignedIn={authState}>
              <Vouchers />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/Profile"
          element={
            <Protected isSignedIn={authState}>
              <MhsssProfile />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/BankingStatment"
          element={
            <Protected isSignedIn={authState}>
              <BankingStatment />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/BalanceBanking"
          element={
            <Protected isSignedIn={authState}>
              <Banking />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/payoutstandings"
          element={
            <Protected isSignedIn={authState}>
              <PayOutStandings />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/Notifications"
          element={
            <Protected isSignedIn={authState}>
              <Notificationsmhsss />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/pensionerFinancials"
          element={
            <Protected isSignedIn={authState}>
              <Pfinancials />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/pensionerPayments"
          element={
            <Protected isSignedIn={authState}>
              <Ppayment />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/paymentReceipt"
          element={
            <Protected isSignedIn={authState}>
              <PaymentReceipt />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/cashPay"
          element={
            <Protected isSignedIn={authState}>
              <CashPay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/ewalletcashPay/verify"
          element={
            <Protected isSignedIn={authState}>
              <CashPay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/ewalletcashPay"
          element={
            <Protected isSignedIn={authState}>
              <EwalletCashPay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/ewalletPay"
          element={
            <Protected isSignedIn={authState}>
              <EwalletPay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/Reports"
          element={
            <Protected isSignedIn={authState}>
              <Reports />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/chequePay"
          element={
            <Protected isSignedIn={authState}>
              <ChequePay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/bankPay"
          element={
            <Protected isSignedIn={authState}>
              <BankPay />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/outstanding/person"
          element={
            <Protected isSignedIn={authState}>
              <PersonOutstanding />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/dashboard/outstanding/person"
          element={
            <Protected isSignedIn={authState}>
              <PersonOutstanding />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/transMethod"
          element={
            <Protected isSignedIn={authState}>
              <TransactionMethod />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/MHSSS/merchProfile"
          element={
            <Protected isSignedIn={authState}>
              <MhhsMerchantProfile />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/allGroAdmins"
          element={
            <Protected isSignedIn={authState}>
              <ListingGro />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionL"
          element={
            <Protected isSignedIn={authState}>
              <PensionL />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryP"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/ApplicationP"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationsP />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/Edashboard/Pensioner_HistoryP"
          element={
            <Protected isSignedIn={authState}>
              <PensionP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/dashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/application"
          element={
            <Protected isSignedIn={authState}>
              <ApplicationPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/dashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/Account"
          element={
            <Protected isSignedIn={authState}>
              <Account />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerAccount/Biometric"
          element={
            <Protected isSignedIn={authState}>
              <Biometric />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerAccount/Biometric/Add"
          element={
            <Protected isSignedIn={authState}>
              <BiometricAdd />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/PensionerAccount/Biometric"
          element={
            <Protected isSignedIn={authState}>
              <Biometric />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/PensionerAccount/Biometric/Add"
          element={
            <Protected isSignedIn={authState}>
              <BiometricAdd />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/PensionerAccount/Biometric"
          element={
            <Protected isSignedIn={authState}>
              <Biometric />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/PensionerAccount/Biometric/Add"
          element={
            <Protected isSignedIn={authState}>
              <BiometricAdd />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Edashboard/PensionerApplication"
          element={
            <Protected isSignedIn={authState}>
              <AAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GRO/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/About"
          element={
            <Protected isSignedIn={authState}>
              <AboutPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route path="/card" element={<CardPage />} exact></Route>
        <Route path="/cardBack" element={<CardBackPage />} exact></Route>
        <Route
          path="/Dashboard/transaction_history"
          element={
            <Protected isSignedIn={authState}>
              <Transactions />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/transaction_history/detail"
          element={
            <Protected isSignedIn={authState}>
              <PensionerTransactionDetail />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Transactions/detail"
          element={
            <Protected isSignedIn={authState}>
              <DetailTransactionMerchant />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/MHSSS/Payment/detail"
          element={
            <Protected isSignedIn={authState}>
              <MerchantMhsssPaymentDetail />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/PcardView"
          element={
            <Protected isSignedIn={authState}>
              <PViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/PcardView"
          element={
            <Protected isSignedIn={authState}>
              <PViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Application/History"
          element={
            <Protected isSignedIn={authState}>
              <AppHistory />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/PcardView"
          element={
            <Protected isSignedIn={authState}>
              <PViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/contact"
          element={
            <Protected isSignedIn={authState}>
              <ContactPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/polList"
          element={
            <Protected isSignedIn={authState}>
              <PolPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/statusPage"
          element={
            <Protected isSignedIn={authState}>
              <StatusPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/statusPage"
          element={
            <Protected isSignedIn={authState}>
              <StatusPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/addPensioner"
          element={
            <Protected isSignedIn={authState}>
              <AddPensionerPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/distributionsPage"
          element={
            <Protected isSignedIn={authState}>
              <DistriutionsPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/profPage"
          element={
            <Protected isSignedIn={authState}>
              <ProfPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/allEmployees"
          element={
            <Protected isSignedIn={authState}>
              <AllEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route path="/Maps" element={<MapsPage />} exact></Route>
        <Route
          path="/EDashboard/PowerBi"
          element={<PowerBiPage />}
          exact
        ></Route>
        <Route
          path="/EDashboard/adminPanel"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/adminDashboard"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/adminPanel"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/PowerBiLinkPage/:id"
          element={<PowerBiLinkPage />}
          exact
        ></Route>
        <Route
          path="/AdminDashboard/allEmployees"
          element={
            <Protected isSignedIn={authState}>
              <AllEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/adminDashboard/ECardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/rolesPrivileges"
          element={
            <Protected isSignedIn={authState}>
              <RolesPrivileges />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/employeeProfile"
          element={
            <Protected isSignedIn={authState}>
              <EmployeeProfile />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/editEmployee"
          element={
            <Protected isSignedIn={authState}>
              <EditEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/Pensioner_HistoryP"
          element={
            <Protected isSignedIn={authState}>
              <PensionP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard"
          element={
            <Protected isSignedIn={authState}>
              <AdminPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/rolesPrivileges"
          element={
            <Protected isSignedIn={authState}>
              <RolesPrivileges />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/allEmployees"
          element={
            <Protected isSignedIn={authState}>
              <AllEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/employeeProfile"
          element={
            <Protected isSignedIn={authState}>
              <EmployeeProfile />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/ECardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/ACardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/editEmployee"
          element={
            <Protected isSignedIn={authState}>
              <EditEmployee />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/Pensioner_HistoryP"
          element={
            <Protected isSignedIn={authState}>
              <PensionP />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/history"
          element={
            <Protected isSignedIn={authState}>
              <History />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/historyStatus"
          element={
            <Protected isSignedIn={authState}>
              <HistoryStatus />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/historyStatus"
          element={
            <Protected isSignedIn={authState}>
              <HistoryStatus />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/historyStatus"
          element={
            <Protected isSignedIn={authState}>
              <HistoryStatus />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GRO"
          element={
            <Protected isSignedIn={authState}>
              <PListingGRO />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Pensioners"
          element={
            <Protected isSignedIn={authState}>
              <PListingGRO />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash"
          element={
            <Protected isSignedIn={authState}>
              <GroAdminDash />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GRO/profile"
          element={
            <Protected isSignedIn={authState}>
              <PProfileGRO />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Pensioners/profile"
          element={
            <Protected isSignedIn={authState}>
              <PProfileGRO />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Employees"
          element={
            <Protected isSignedIn={authState}>
              <GroEmployees />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Employees/Profile"
          element={
            <Protected isSignedIn={authState}>
              <EProfileGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GroempProfile"
          element={
            <Protected isSignedIn={authState}>
              <EProfileGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GroAdminProfile"
          element={
            <Protected isSignedIn={authState}>
              <EProfileGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Employees/Profile/EditEmployee"
          element={
            <Protected isSignedIn={authState}>
              <GroEprofileEdit />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GroempProfile/EditEmployee"
          element={
            <Protected isSignedIn={authState}>
              <GroEprofileEdit />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GroAdminProfile/EditAdmin"
          element={
            <Protected isSignedIn={authState}>
              <GroEprofileEdit />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/Employees/Profile/ViewEmployee"
          element={
            <Protected isSignedIn={authState}>
              <EViewGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GROEmpCardView"
          element={
            <Protected isSignedIn={authState}>
              <EViewGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/GROAdminCardView"
          element={
            <Protected isSignedIn={authState}>
              <EViewGROAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/addBank"
          element={
            <Protected isSignedIn={authState}>
              <AddNewBank />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Dashboard/addBank"
          element={
            <Protected isSignedIn={authState}>
              <AddNewBank />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/meeting"
          element={
            // <Protected isSignedIn={authState}>
            <Video />
            // </Protected>
          }
          exact
        ></Route>
        <Route
          path="/meeting/:id"
          element={
            // <Protected isSignedIn={authState}>
            <Video />
            // </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/profPage"
          element={
            <Protected isSignedIn={authState}>
              <ProfPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/profPage"
          element={
            <Protected isSignedIn={authState}>
              <ProfPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/PensionerAccount"
          element={
            <Protected isSignedIn={authState}>
              <PAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/distributionsPage"
          element={
            <Protected isSignedIn={authState}>
              <DistriutionsPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/distributionsPage"
          element={
            <Protected isSignedIn={authState}>
              <DistriutionsPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/additionalDocs"
          element={
            <Protected isSignedIn={authState}>
              <AditionalDocuments />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/listing"
          element={
            <Protected isSignedIn={authState}>
              <ListingPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/history"
          element={
            <Protected isSignedIn={authState}>
              <History />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/history"
          element={
            <Protected isSignedIn={authState}>
              <History />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/addBank"
          element={
            <Protected isSignedIn={authState}>
              <AddNewBank />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/addBank"
          element={
            <Protected isSignedIn={authState}>
              <AddNewBank />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/BeneficiaryAccount"
          element={
            <Protected isSignedIn={authState}>
              <BAccount />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/cardView"
          element={
            <Protected isSignedIn={authState}>
              <ViewPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/eWallet/wallet"
          element={
            <Protected isSignedIn={authState}>
              <Wallet />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/eWallet"
          element={
            <Protected isSignedIn={authState}>
              <WalletDash />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/BeneficiaryQuestions"
          element={
            <Protected isSignedIn={authState}>
              <BeneficiaryPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/allApplications"
          element={
            <Protected isSignedIn={authState}>
              <AllApplications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/addAdmin"
          element={
            <Protected isSignedIn={authState}>
              <AddAdminPage />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/addGroAdmin"
          element={
            <Protected isSignedIn={authState}>
              <AddGroAdmin />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/GroAdminDash/AddGroEmp"
          element={
            <Protected isSignedIn={authState}>
              <AddGroEmp />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/notifications"
          element={
            <Protected isSignedIn={authState}>
              <Notifications />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/EDashboard/chatPanel"
          element={
            <Protected isSignedIn={authState}>
              <ChatPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/AdminDashboard/PowerBi"
          element={<PowerBiPage />}
          exact
        ></Route>
        <Route path="/PowerBiReports" element={<PowerBiPage />} exact></Route>
        <Route path="/tableauReports" element={<Tablo />} exact></Route>
        <Route
          path="/superAdminDashboard/addCashGrant"
          element={<CashGrant />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/Programs"
          element={<Program />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/AccessSetting"
          element={<AccessSetting />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/HolidaySetting"
          element={<HolidaySetting />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/SchedulerTimeSetting"
          element={<SchedulerTimeSetting />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/Revoke/AccessSetting"
          element={<RevokeAccessSetting />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/Merchant/Listing"
          element={<MerchantListing />}
          exact
        ></Route>
        <Route
          path="/adminDashboard/Merchant/Listing"
          element={<MerchantListing />}
          exact
        ></Route>
        <Route
          path="/EDashboard/Merchant/Listing"
          element={<MerchantListing />}
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/Merchant/Profile"
          element={<MerchantEmployeeProfile />}
          exact
        ></Route>
        <Route
          path="/adminDashboard/Merchant/Profile"
          element={<MerchantEmployeeProfile />}
          exact
        ></Route>
        <Route
          path="/EDashboard/Merchant/Profile"
          element={<MerchantEmployeeProfile />}
          exact
        ></Route>
        <Route
          path="/MHSSS/Merchant/Profile"
          element={<MHSSSMerchantProfile />}
          exact
        ></Route>
        <Route
          path="/AdminDashboard/chatPanel"
          element={
            <Protected isSignedIn={authState}>
              <ChatPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/chatPanel"
          element={
            <Protected isSignedIn={authState}>
              <ChatPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/superAdminDashboard/chatPanel/:number"
          element={
            <Protected isSignedIn={authState}>
              <ChatPanel />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/dashboard"
          element={
            <Protected isSignedIn={authState}>
              <MerchantDashboard />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/SapMoney/dashboard"
          element={
            <Protected isSignedIn={authState}>
              <MerchantSapMoney />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/SapMoney/SelectVouchers"
          element={
            <Protected isSignedIn={authState}>
              <SelectVouchersSapMoney />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/SapMoney/SelectType"
          element={
            <Protected isSignedIn={authState}>
              <SelectTypeSapMoney />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/SapMoney/Verify"
          element={
            <Protected isSignedIn={authState}>
              <VerifySapMoney />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/SapMoney/Pay"
          element={
            <Protected isSignedIn={authState}>
              <PaySapMoney />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Pensioner_HistoryP"
          element={
            <Protected isSignedIn={authState}>
              <PensionerMerchant />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Transactions"
          element={
            <Protected isSignedIn={authState}>
              <TransactionHistory />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/MHSSS/Payments"
          element={
            <Protected isSignedIn={authState}>
              <MerchantMhsssPayment />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Reports"
          element={
            <Protected isSignedIn={authState}>
              <MerchantReports />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Profile"
          element={
            <Protected isSignedIn={authState}>
              <MerchantProfile />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/PensionerReport"
          element={
            <Protected isSignedIn={authState}>
              <SingleReport />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/:id"
          element={
            <Protected isSignedIn={authState}>
              <PensionerProfileMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/Pensioner/SelectStore"
          element={
            <Protected isSignedIn={authState}>
              <SelectStorePensioner />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/Vouchers"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVouchersMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/SapMoney/allVouchers"
          element={
            <Protected isSignedIn={authState}>
              <AllVouchersSapMoney />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/Pay"
          element={
            <Protected isSignedIn={authState}>
              <PensionerPayMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/Verify"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerifyMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/Verify/Questions"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerifyQuestionsMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/PensionerProfile/Reciept"
          element={
            <Protected isSignedIn={authState}>
              <PensionerRecieptMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/SapMoney/PensionerProfile/Reciept"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyPensionerRecieptMerchant />
            </Protected>
          }
        ></Route>
        <Route
          path="/Merchant/ThirdPerson"
          element={
            <Protected isSignedIn={authState}>
              <ContactPerson />
            </Protected>
          }
        ></Route>
        <Route
          path="/Image"
          element={
            // <Protected isSignedIn={authState}>
            <ImageClassification />
            // </Protected>
          }
        ></Route>
        {/* <Route
          path="/Merchant/Transactions/TransactionDetail"
          element={
            <Protected isSignedIn={authState}>
              <PensionerTransactionDetail />
            </Protected>
          }
        /> */}
        <Route
          path="/CompareImage"
          element={
            <Protected isSignedIn={authState}>
              <CompareImage />
            </Protected>
          }
        />
        <Route
          path="/Merchant/Pay/SelectVouchers"
          element={
            <Protected isSignedIn={authState}>
              <SelectVouchers />
            </Protected>
          }
        />
        <Route
          path="/Sap/Money"
          element={
            // <Protected isSignedIn={authState}>
            <EWallet />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney"
          element={
            // <Protected isSignedIn={authState}>
            <EWallet />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Profile"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyProfile />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Transactions"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyTransactions />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/merchant/addSapMoney"
          element={
            // <Protected isSignedIn={authState}>
            <AddSapMoney />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Notifications"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyNotifications />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Banking"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyBanking />
            // </Protected>
          }
        />
        {/* <Route
          path="/SapMoney/Wallet"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyWallet />
            // </Protected>
          }
          /> */}
        <Route
          path="/SapMoney/AddBank"
          element={
            // <Protected isSignedIn={authState}>
            <SAPMoneyAddBank />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Pay/PhoneNumber"
          element={
            <Protected isSignedIn={authState}>
              <SAPPayMobile />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Parent/Respond/Child"
          element={
            <Protected isSignedIn={authState}>
              <ParentRespondToRequest />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/RequestDetail"
          element={
            <Protected isSignedIn={authState}>
              <RequestDetail />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/Pay/PhoneNumber"
          element={
            <Protected isSignedIn={authState}>
              <MerchantPayMoney />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/QuickPay"
          element={
            // <Protected isSignedIn={authState}>
            <QuickPay />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Sender"
          element={
            // <Protected isSignedIn={authState}>
            <QuickPay />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/Pensioner/AllTransactions"
          element={
            <Protected isSignedIn={authState}>
              <SapPensionerAllTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Pensioner/AllRequest"
          element={
            <Protected isSignedIn={authState}>
              <SapPensionerAllRequest />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/AllRequest"
          element={
            <Protected isSignedIn={authState}>
              <SapMerchantAllRequest />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/Request/Verification"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyRequestPensionerVerification />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/Pensioner/Request/Listing"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyRequestPensionerListing />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/WalletTransactions"
          element={
            <Protected isSignedIn={authState}>
              <SapWalletPesnionerTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/AllTransactions"
          element={
            <Protected isSignedIn={authState}>
              <SapPensionerAllTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Pensioner/ProgramTransactions"
          element={
            <Protected isSignedIn={authState}>
              <ProgramTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/Pensioner/ProgramTransactions"
          element={
            <Protected isSignedIn={authState}>
              <ProgramTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/AllPensioner/ProgramTransactions"
          element={
            <Protected isSignedIn={authState}>
              <AllProgramTransactions />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Pensioner/generalTransaction/detail"
          element={
            <Protected isSignedIn={authState}>
              <GeneralTransactionDetail />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Pensioner/programTransaction/detail"
          element={
            <Protected isSignedIn={authState}>
              <ProgramTransactionDetail />
            </Protected>
          }
        />
        <Route
          path="/SapMoney/Merchant/MHSSSTransaction/detail"
          element={
            <Protected isSignedIn={authState}>
              <MHSSSTransactionDetail />
            </Protected>
          }
        />
        <Route
          path="/Inventory-AddProduct"
          element={
            // <Protected isSignedIn={authState}>
            <AddProduct />
            // </Protected>
          }
        />
        <Route path="/quickPay/:id" element={<ChildResponseQuickPay />} />
        <Route
          path="/event/invite/:userId/:eventId"
          element={<EventInvite />}
        />
        <Route
          path="/child/response/:id"
          element={<ChildResponseRequestMoney />}
        />
        <Route
          path="/pensioner/response/:id"
          element={<PensionerResponseRequestMoney />}
        />
        {/* <Route
          path="/quickPay/:id"
          element={
            // <Protected isSignedIn={authState}>
            <ChildResponseQuickPay />
            // </Protected>
          }
        /> */}
        <Route
          path="/SapMoney/QuickPayMerch"
          element={
            // <Protected isSignedIn={authState}>
            <QuickPayMerch />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/RequestMoney"
          element={
            // <Protected isSignedIn={authState}>
            <RequestMoney />
            // </Protected>
          }
        />
        <Route
          path="/SapMoney/MHSSS/Merchants"
          element={
            // <Protected isSignedIn={authState}>
            <SapMoneyMHSSSMerchantListing />
            // </Protected>
          }
        />

        <Route
          path="/SapMoney/MHSSS/outstanding/person"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyMHSSSMerchantOutstanding />
            </Protected>
          }
          exact
        ></Route>

        <Route
          path="/SapMoney/MHSSS/outstanding/vouchers"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyMHSSSMerchantVouchers />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/SapMoney/MHSSS/outstanding/payment"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyMHSSSMerchantPayment />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/SapMoney/MHSSS/outstanding/payment/Receipt"
          element={
            <Protected isSignedIn={authState}>
              <SapMoneyMHSSSMerchantReceipt />
            </Protected>
          }
          exact
        ></Route>
        <Route
          path="/Merchant/Employee/List"
          element={
            <Protected isSignedIn={authState}>
              <MerchantOwnEmployees />
            </Protected>
          }
        />
        <Route
          path="/Merchant/Add/Employee"
          element={
            <Protected isSignedIn={authState}>
              <AddMerchantEmployee />
            </Protected>
          }
        />
        <Route
          path="/Merchant/NearMe"
          element={
            <Protected isSignedIn={authState}>
              <MerchantNearMe />
            </Protected>
          }
        />
        <Route
          path="/Merchant/Employee/RolesAndPrivileges"
          element={
            <Protected isSignedIn={authState}>
              <MerchantEmployeeRolesAndPrivileges />
            </Protected>
          }
        />
        <Route
          path="/Event/Gallary"
          element={
            <Protected isSignedIn={authState}>
              <Gallary />
            </Protected>
          }
        />
        <Route
          path="/SubEvent/Gallary"
          element={
            <Protected isSignedIn={authState}>
              <Gallary />
            </Protected>
          }
        />
        <Route
          path="/EmployeeDashBoard/events"
          element={
            <Protected isSignedIn={authState}>
              <Events />
            </Protected>
          }
        />
        <Route
          path="/EmployeeDashBoard/FavoriteEvents"
          element={
            <Protected isSignedIn={authState}>
              <EventsFav />
            </Protected>
          }
        />
        <Route
          path="/DashBoard/FavoriteEvents"
          element={
            <Protected isSignedIn={authState}>
              <EventsFav />
            </Protected>
          }
        />
        <Route
          path="/EmployeeDashBoard/Subevents"
          element={
            <Protected isSignedIn={authState}>
              <Events />
            </Protected>
          }
        />
        <Route
          path="/PensionerDashBoard/events"
          element={
            <Protected isSignedIn={authState}>
              <Events />
            </Protected>
          }
        />
        <Route
          path="/PensionerDashBoard/Subevents"
          element={
            <Protected isSignedIn={authState}>
              <Events />
            </Protected>
          }
        />
        <Route
          path="/EventDetails"
          element={
            <Protected isSignedIn={authState}>
              <EventDetails />
            </Protected>
          }
        />
        <Route
          path="/SubEventDetails"
          element={
            <Protected isSignedIn={authState}>
              <EventDetails />
            </Protected>
          }
        />
        <Route
          path="/SubEventUpdate"
          element={
            <Protected isSignedIn={authState}>
              <EventUpdate />
            </Protected>
          }
        />
        <Route
          path="/eventUpdate"
          element={
            <Protected isSignedIn={authState}>
              <EventUpdate />
            </Protected>
          }
        />
        <Route
          path="/addEvent"
          element={
            <Protected isSignedIn={authState}>
              <AddEvents />
            </Protected>
          }
        />
        <Route
          path="/addSubEvent"
          element={
            <Protected isSignedIn={authState}>
              <AddEvents />
            </Protected>
          }
        />
        <Route
          path="/Maintenance"
          element={
            <Protected isSignedIn={authState}>
              <Maintenance />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Vehicles"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceVList />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Drivers"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceDList />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Vendors"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceVanList />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Records"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceMList />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Trips"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceTripsList />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Add-Driver"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceAddDriver />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Add-Vendor"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceAddVendor />
            </Protected>
          }
        />
        <Route
          path="/Maintenance-Add-Vehicle"
          element={
            <Protected isSignedIn={authState}>
              <MaintenanceAddVehical />
            </Protected>
          }
        />
        <Route
          path="/Inventory"
          element={
            // <Protected isSignedIn={authState}>
            <Inventory />
            // </Protected>
          }
        />

        <Route
          path="/Inventory-Categories"
          element={
            // <Protected isSignedIn={authState}>
            <InventoryCat />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Products"
          element={
            // <Protected isSignedIn={authState}>
            <InventoryProduct />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Product"
          element={
            // <Protected isSignedIn={authState}>
            <InventoryPro />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Vendor-Product"
          element={
            // <Protected isSignedIn={authState}>
            <InventoryVPro />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Vendor-Orders"
          element={
            // <Protected isSignedIn={authState}>
            <Orders />
            // </Protected>
          }
        />

        <Route
          path="/Inventory-Vendor-Orders-Details"
          element={
            // <Protected isSignedIn={authState}>
            <OrderDetail />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Cart"
          element={
            // <Protected isSignedIn={authState}>
            <Cart />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Buy"
          element={
            // <Protected isSignedIn={authState}>
            <Buy />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-CheckOut"
          element={
            // <Protected isSignedIn={authState}>
            <PlaceOrder />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-CheckOutBuy"
          element={
            // <Protected isSignedIn={authState}>
            <PlaceOrderBuy />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Vendors"
          element={
            // <Protected isSignedIn={authState}>
            <Vendors />
            // </Protected>
          }
        />
        <Route
          path="/VendorDashboard"
          element={
            // <Protected isSignedIn={authState}>
            <VendorDashboard />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Vendor-DashBoard"
          element={
            <Protected isSignedIn={authState}>
              <InventoryVDash />
            </Protected>
          }
        />
        <Route
          path="/SuperAdmin/InventoryDashboard"
          element={
            <Protected isSignedIn={authState}>
              <SuperAdminInventoryVDash />
            </Protected>
          }
        />
        <Route
          path="/SuperAdmin/Inventory/SelectVendor"
          element={
            <Protected isSignedIn={authState}>
              <SuperAdminInventorySelectVendor />
            </Protected>
          }
        />
        <Route
          path="/SuperAdmin/Inventory/VendorListing"
          element={
            <Protected isSignedIn={authState}>
              <SuperAdminInventoryVendorListing />
            </Protected>
          }
        />
          <Route
          path="/SuperAdmin/Inventory/AddVendor"
          element={
            <Protected isSignedIn={authState}>
              <AddVendor />
            </Protected>
          }
        />
        <Route
          path="/Inventory-Favorite"
          element={
            // <Protected isSignedIn={authState}>
            <Favorite />
            // </Protected>
          }
        />
        <Route
          path="/Inventory-Vendor-Products"
          element={
            // <Protected isSignedIn={authState}>
            <VendorProducts />
            // </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGov />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovDetails />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details/CreditedTransactions"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovDetailsListing />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details/CreditedTransactions/detail"
          element={
            <Protected isSignedIn={authState}>
              <MHSSSGovAgencyCreditDetails />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details/InternalFundTransfer"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovDetailsListing />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details/VoucherCashing"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovDetailsListing />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Details/SAPWalletTransacions"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovDetailsListing />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/AddMoney"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovAddMoney />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Requests"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovRequests />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Requests/detail"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovRequestsDetails />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/Requests/payment"
          element={
            <Protected isSignedIn={authState}>
              <MHSSSPayRequestMoney />
            </Protected>
          }
        />
        <Route
          path="/MHSSS/GovernmentAgency/VoucherDetails"
          element={
            <Protected isSignedIn={authState}>
              <MhsssGovVCash />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/selectLocation"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSelectLocation />
            </Protected>
          }
        />
          <Route
          path="/pensionerVerification/searchDistribution"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSearchDistribution />
            </Protected>
          }
        />
        
        <Route
          path="/pensionerVerificationScheduling/status"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSchedulingStatus />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerificationScheduling/selectLocation"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSchedulingSelectLocation />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/selectProgram"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSelectProgram />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/selectProgram"
          element={
            <Protected isSignedIn={authState}>
              <SchedulePensionerVerificationSelectProgram />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/EditSchedule"
          element={
            <Protected isSignedIn={authState}>
              <VerificationEditSchedule />
            </Protected>
          }
        />
        <Route
          path="/Devices"
          element={
            <Protected isSignedIn={authState}>
              <Devices />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/schedules"
          element={
            <Protected isSignedIn={authState}>
              <SchedualHistory />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/schedulesDetails"
          element={
            <Protected isSignedIn={authState}>
              <SchedualHistoryDetails />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/EmployeeDetails"
          element={
            <Protected isSignedIn={authState}>
              <SchedualEmployeeDetails />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/PensionerDetails"
          element={
            <Protected isSignedIn={authState}>
              <SchedualPensionerDetails />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/selectEmployees"
          element={
            <Protected isSignedIn={authState}>
              <SchedulePensionerVerificationSelectEmployees />
            </Protected>
          }
        />
        <Route
          path="/scheduleVerification/selectedEmployees"
          element={
            <Protected isSignedIn={authState}>
              <SchedulePensionerVerificationSelectedEmployees />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/BookSlot"
          element={
            <Protected isSignedIn={authState}>
              <BookSlot />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/AllSlots"
          element={
            <Protected isSignedIn={authState}>
              <AllSlots />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/AppoitmentDetails"
          element={
            <Protected isSignedIn={authState}>
              <AppointmentDetails />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/SearchAppoitments"
          element={
            <Protected isSignedIn={authState}>
              <AppointmentSearch />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/BookSlot/SelectLocation"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSchedulingSelectLocation />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/selectedProgram"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationSelectedProgram />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/AllAppointments"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationAllAppointments />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/listing"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationListing />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/listing/SearchResult"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationListingSearchResult />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Profile"
          element={
            <Protected isSignedIn={authState}>
              <PensionerVerificationProfile />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Profile/VerifyBasicInfo"
          element={
            <Protected isSignedIn={authState}>
              <VerifyBasicInfo />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Profile/VerifyIdentification"
          element={
            <Protected isSignedIn={authState}>
              <VerifyIdentification />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Profile/UpdateIdentification"
          element={
            <Protected isSignedIn={authState}>
              <UpdateIdentification />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Profile/AddIdentification"
          element={
            <Protected isSignedIn={authState}>
              <AddIdentification />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/PensionBook"
          element={
            <Protected isSignedIn={authState}>
              <PensionBookVerification />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Verify/PriorityPensioners"
          element={
            <Protected isSignedIn={authState}>
              <VerifyPriorityPensioners />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/PriorityRequests"
          element={
            <Protected isSignedIn={authState}>
              <VerifyPriorityRequest />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/PriorityRequests/Detail"
          element={
            <Protected isSignedIn={authState}>
              <VerifyPriorityRequestDetails />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/Biometrics"
          element={
            <Protected isSignedIn={authState}>
              <BiometricVerification />
            </Protected>
          }
        />
        <Route
          path="/pensionerVerification/BiometricsUpdate"
          element={
            <Protected isSignedIn={authState}>
              <BiometricUpdate />
            </Protected>
          }
        />
        <Route
          path="/Merchant/GovAgency/Request/MHSSS"
          element={
            <Protected isSignedIn={authState}>
              <MerchantReqMHSSS />
            </Protected>
          }
        />
        <Route
          path="/SecurityQuestions"
          element={
            <Protected isSignedIn={authState}>
              <Questions />
            </Protected>
          }
        />
        <Route
          path="/Merchant/GovAgency/Request/MHSSS/History"
          element={
            <Protected isSignedIn={authState}>
              <MerchantReqMHSSSHistory />
            </Protected>
          }
        />
        <Route
          path="/Merchant/GovAgency/Request/MHSSS/History/Detail"
          element={
            <Protected isSignedIn={authState}>
              <MerchantReqMHSSSHistoryDetail />
            </Protected>
          }
        />
        <Route
          path="/VendorRegistration"
          element={
            <Protected isSignedIn={authState}>
              <InventoryVendorReg />
            </Protected>
          }
        />
        {/* <Route path="*" element={<Initial />}></Route> */}
        <Route path="*" element={handleRedirect()} />
        <Route
          path="/Attendance"
          element={
            <Protected isSignedIn={authState}>
              <Attendance />
            </Protected>
          }
        />
        <Route
          path="/ERequests"
          element={
            <Protected isSignedIn={authState}>
              <ERequests />
            </Protected>
          }
        />
        <Route
          path="/AttendanceEmployees"
          element={
            <Protected isSignedIn={authState}>
              <AttendanceEmployees />
            </Protected>
          }
        />
        <Route
          path="/EmployeeDetails"
          element={
            <Protected isSignedIn={authState}>
              <AttendanceEmployeesDetails />
            </Protected>
          }
        />
        <Route
          path="/AttendanceDashBoard"
          element={
            <Protected isSignedIn={authState}>
              <AttendanceDashBoard />
            </Protected>
          }
        />
      </Routes>
      <Notification />
    </div>
  );
}

export default App;
