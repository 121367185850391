import React, { useEffect, useState } from "react";
import loc from "../../../imgs/loc.png";
import group from "../../../imgs/group.png";
import logo from "../../../imgs/logo12.png";
import { Button } from "antd";
import moment from "moment";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const EventDetails = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);
  const eventId = useSelector((state) => state.eventId);
  const token = useSelector((state) => state.token);
  const dataUser = useSelector((state) => state.dataUser);
  const subEventId = useSelector((state) => state.subEventId);
  const location = useLocation();
  const dash = location.pathname;
  const [countryCode, setCountryCode] = useState("");
  const [phoneNoo, setPhoneNoo] = useState("");
  const [invitee, setInvitee] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [status, setStatus] = useState("");
  const [joined, setJoined] = useState(0);
  const [showDone, setShowDone] = useState(false);
  const [showInviteDone, setShowInvteDone] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [event, setEvent] = useState("");
  const [phone, setPhone] = useState("");
  const [contactMethod, setContactMethod] = useState("gid");
  const [err, setErr] = useState("");
  const [show, setShow] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [gid, setGid] = useState("");
  const handleOptionChange = (event) => {
    setContactMethod(event.target.value);
    if (event.target.value === "phone") {
      setEmailValue("");
      setGid("");
    }
    if (event.target.value === "email") {
      setGid("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "gid") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "import") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
    if (event.target.value === "batch") {
      setEmailValue("");
      setPhone("");
      setCountryCode("");
      setPhoneNoo("");
    }
  };
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPhone(event);
      if (event) {
        const wow = parsePhoneNumber(event);
        if (wow) {
          let count = wow.countryCallingCode;
          let num = wow.nationalNumber;
          setCountryCode(count);
          setPhoneNoo(num);
        }
      }
    } else {
      setState(event.target.value);
    }
  };
  const containerStyle = {
    width: "100%",
    height: "250px",
  };
  useEffect(() => {
    if (event?.location) {
      const [lat, lng] = event.location.split(", ").map(parseFloat);
      setCenter({ lat, lng });
    }
  }, [event]);
  useEffect(() => {
    let url;
    if (dash === "/SubEventDetails") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${subEventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${eventId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        console.log("DataEvent", data);
        setEvent(data?.data?.event);
        setJoined(`${data?.data?.eventJoineeCount}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetch]);
  useEffect(() => {
    const checkInviteeStatus = () => {
      const userId = dataUser?.data?.user?.id;
      if (userId && event?.eventinvitees?.length > 0) {
        console.log("Testing Val", userId, event?.eventinvitees);
        const invitee = event.eventinvitees.find(
          (invitee) => invitee.inviteeUserId === userId
        );
        if (invitee) {
          console.log("Test", invitee);
          setStatus(invitee.status);
        } else {
          console.log("NotFound");
          setStatus("no");
        }
      } else {
        console.log("Not Found first");
        setStatus("no");
      }
    };
    console.log("EVENT", event, dataUser?.data?.user?.id);
    if (event !== "") {
      checkInviteeStatus();
    }
  }, [event]);
  const Join = (id, type) => {
    let url;
    let method;
    if (event?.type === "private") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-private-event`;
      method = "put";
    } else if (event?.type === "public") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/update-public-event`;
      method = "post";
    }
    axios[method](
      url,
      {
        eventId: id,
        status: type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Delete = () => {
    let url;
    if (dash === "/SubEventDetails") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event/${subEventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event/${eventId}`;
    }
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setFetch(!fetch);
        dash === "/EventDetails"
          ? router("/EmployeeDashBoard/events")
          : router("/EmployeeDashBoard/Subevents");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Invite = () => {
    if (invitee === "") {
      setErr("Invitee Name Is Required");
      setShow(true);
    } else if (contactMethod === "gid" && gid === "") {
      setErr("GID Is Required");
      setShow(true);
    } else if (contactMethod === "email" && emailValue === "") {
      setErr("Email Is Required");
      setShow(true);
    } else if (contactMethod === "phone" && phoneNoo === "") {
      setErr("Phone Is Required");
      setShow(true);
    } else {
      let body = {
        eventId: dash === "/SubEventDetails" ? subEventId : eventId,
        status: "invited",
        name: invitee,
      };
      if (contactMethod === "gid") body.gid = gid;
      else if (contactMethod === "email") body.email = emailValue;
      else if (contactMethod === "phone") {
        body.countryCode = `+${countryCode}`;
        body.phoneNo = phoneNoo;
      }
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/v1/event-invitee/initee-event-users`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setShowPopup(false);
          setShowInvteDone(true);
          setErr("Invitation Sent.");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="flex w-full pb-[5rem] items-center justify-center">
      <div className="w-full flex flex-col mt-4 w-full ">
        <div className="w-full flex items-center justify-between">
          <h1 className="text-2xl font-semibold ">
            {event?.name}{" "}
            <span
              className={`${
                event?.type === "private" ? "bg-[#6F42C1]" : "bg-[#3395FE]"
              } sm:ml-3 text-sm rounded-full pl-4 p-1 pr-4 text-white`}
            >
              {event?.type}
            </span>
          </h1>
          {dataUser?.data?.user?.id === event?.hostUserId && (
            <div className="flex gap-4">
              <h1
                onClick={() => {
                  dash === "/EventDetails"
                    ? router("/eventUpdate")
                    : router("/SubEventUpdate");
                }}
                className="text-lg cursor-pointer w-[6rem] bg-green-100 px-3 flex items-center justify-center rounded-md font-semibold text-primary hover:underline"
              >
                Edit
              </h1>
              <h1
                onClick={() => {
                  setShowDone(true);
                }}
                className="text-lg px-3 rounded-md w-[6rem] bg-red-100 flex items-center justify-center cursor-pointer font-semibold text-red-600 hover:underline"
              >
                Delete
              </h1>
            </div>
          )}
        </div>
        <div className="flex sm:gap-10 gap-4 mt-2">
          <div className="flex gap-2">
            <img src={loc} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">{event?.address}</p>
          </div>
          <div className="flex gap-2">
            <img src={group} alt="" className="w-5 h-5" />
            <p className="font-semibold text-gray-700">
              {event?.allowedPerson} Guest capacity
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end mt-4">
          <Button
            onClick={() => {
              dash === "/EventDetails"
                ? router("/Event/Gallary")
                : router("/SubEvent/Gallary");
            }}
            className="border border-dotted bg-gray-50 hover:bg-gray-200 cursor-pointer text-black font-semibold"
          >
            See All Photos
          </Button>
        </div>
        <div key={event.id} className="w-full flex md:flex-row flex-col gap-2 mt-2">
          <div className="md:w-[60%] w-full h-[320px]">
            <img
              className="w-full h-full rounded-xl border bg-gray-50"
              src={event?.coverImage}
              alt={event?.id}
            />
          </div>
          <div className="md:w-[40%] w-full h-[320px] grid grid-cols-2 grid-rows-2 gap-2">
            {event?.eventgallaries?.length > 0 && (
              <img
                className="w-full h-full rounded-xl border bg-gray-50"
                src={event?.eventgallaries[0]?.image}
                alt={event?.eventgallaries[0]?.id}
              />
            )}
            {event?.eventgallaries?.length > 1 && (
              <img
                className="w-full h-full rounded-xl border bg-gray-50"
                src={event?.eventgallaries[1]?.image}
                alt={event?.eventgallaries[1]?.id}
              />
            )}
            {event?.eventgallaries?.length > 2 && (
              <img
                className="w-full h-full rounded-xl border bg-gray-50"
                src={event?.eventgallaries[2]?.image}
                alt={event?.eventgallaries[2]?.id}
              />
            )}
            {event?.eventgallaries?.length > 3 && (
              <img
                className="w-full h-full rounded-xl border bg-gray-50"
                src={event?.eventgallaries[3]?.image}
                alt={event?.eventgallaries[3]?.id}
              />
            )}
          </div>
        </div>
        <div className="w-full flex md:flex-row flex-col gap-4">
          <div className="mt-6 md:w-[80%] w-full">
            <div className="flex gap-5 mt-1 mb-2">
              <img
                src={event?.hostUser?.person?.image}
                alt=""
                className="w-12 h-12 border border-gray-50 rounded-md"
              />
              <div>
                <p className="font-semibold text-gray-700">
                  {event?.hostUser?.person?.firstName}{" "}
                  {event?.hostUser?.person?.lastName}
                </p>
                <p className="font-semibold text-gray-400">Organizer</p>
              </div>
            </div>
            <h1 className="font-semibold text-gray-800 text-lg">Description</h1>
            <p className="mb-10">{event?.detail}</p>
            <LoadScript googleMapsApiKey="AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="md:w-[20%] w-full p-6 flex flex-col items-center justify-center rounded-xl bg-gray-100 mt-6">
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Allowed members</p>
              <b className="underline">{event?.allowedPerson}</b>
            </p>
            <p className="font-semibold w-full flex items-center justify-between">
              <p>Joined members</p>
              <b className="underline text-red-600">{joined}</b>
            </p>
            <div className="w-full mt-4">
              {/* <p className="pl-3 text-center font-semibold pr-3">
                From
              </p> */}
              <p className="pl-2 font-semibold pr-2 text-center whitespace-nowrap bg-secondary rounded-full">
                {moment(event?.startDateTime).format("MM/DD/YYYY, h:mm:ss a")}
              </p>
              <p className="pl-3 text-center font-semibold pr-3">-</p>
              <p className="pl-2 text-center font-semibold pr-2 bg-secondary whitespace-nowrap rounded-full">
                {moment(event?.endDateTime).format("MM/DD/YYYY, h:mm:ss a")}
              </p>
            </div>
            <div className="flex flex-col w-[70%] gap-6 mt-5">
              {dataUser?.data?.user?.id === event?.hostUserId ? (
                <p className="text-primary font-bold w-full text-center cursor-pointer hover:underline">
                  Created by you
                </p>
              ) : event?.allowedPerson == joined && status !== "joined" ? (
                <p className="text-red-600 font-bold  w-full text-center cursor-pointer hover:underline">
                  All Slots Filled
                </p>
              ) : status !== "joined" ? (
                <button
                  type=" button"
                  onClick={() => {
                    Join(event.id, "joined");
                  }}
                  className={`text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Join Now
                </button>
              ) : (
                <button
                  type=" button"
                  onClick={() => {
                    Join(event?.id, "leaved");
                  }}
                  className={`text-white w-full bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Leave
                </button>
              )}
              {dataUser?.data?.user?.id === event?.hostUserId && (
                <button
                  onClick={() => {
                    setShowPopup(true);
                  }}
                  className={`${
                    event?.type === "private" ? "" : "hidden"
                  } text-white w-full bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
                >
                  Invite
                </button>
              )}
            </div>
            {!event?.parentEventId && (
              <div className="mt-4">
                <p
                  onClick={() => {
                    // dispatch({ type: "SUB" });
                    if (user === "pensioner")
                      router("/PensionerDashBoard/Subevents");
                    else router("/EmployeeDashBoard/Subevents");
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  View Sub Events
                </p>
              </div>
            )}
            {/* {!sub && (
              <div className="mt-2">
                <p
                  onClick={() => {
                    router("/addSubEvent");
                  }}
                  className="font-semibold  text-primary cursor-pointer hover:underline"
                >
                  Add Sub Event
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 z-100 flex items-center justify-center bg-black bg-opacity-50">
          <div className="my-4 sm:w-2/5 w-full py-8 gap-3 px-6 bg-[#F7F7F7] rounded-md">
            <h1 className="font-semibold text-[1.8rem] mb-4 w-full text-center">
              Invite
            </h1>
            <input
              type="text"
              name=""
              value={invitee}
              onChange={(e) => setInvitee(e.target.value)}
              placeholder="Invitee Name"
              className="lg:text-[1.1rem] w-[100%] border border-gray-300 mb-4  bg-white rounded-[0.5rem] p-2"
            />
            <div className="flex gap-4">
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="gidOption"
                  name="contactMethod"
                  value="gid"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={contactMethod === "gid"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="gidOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  GID
                </label>
              </div>
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="phoneOption"
                  name="contactMethod"
                  value="phone"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={contactMethod === "phone"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="phoneOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Phone Number
                </label>
              </div>

              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="emailOption"
                  name="contactMethod"
                  value="email"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={contactMethod === "email"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="emailOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Email
                </label>
              </div>
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="batchOption"
                  name="contactMethod"
                  value="batch"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={contactMethod === "batch"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="emailOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Batch
                </label>
              </div>
              <div className="mb-2 flex items-center">
                <input
                  type="radio"
                  id="importOption"
                  name="contactMethod"
                  value="import"
                  className="mr-2 h-4 w-4 cursor-pointer text-green-500 focus:ring-green-400 focus:ring-opacity-50 rounded-full custom-radio"
                  checked={contactMethod === "import"}
                  onChange={handleOptionChange}
                />
                <label
                  htmlFor="emailOption"
                  className="cursor-pointer text-sm inline-flex items-center ml-2"
                >
                  Import
                </label>
              </div>
            </div>

            {contactMethod === "gid" && (
              <div className="w-full mb-3 mt-2">
                <input
                  type="text"
                  name=""
                  onChange={(e) => setGid(e.target.value)}
                  placeholder="Enter GID"
                  className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                />
              </div>
            )}
            {contactMethod === "phone" && (
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                value={phone}
                onChange={handleChange(setPhone, "tt")}
                countryCallingCodeEditable={false}
                className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow"
              />
            )}

            {contactMethod === "email" && (
              <div className="w-full mb-3 mt-2">
                <input
                  type="text"
                  name=""
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  placeholder="Enter Email"
                  className="lg:text-[1.1rem] w-[100%] border border-gray-300  bg-white rounded-[0.5rem] p-2"
                />
              </div>
            )}
            <div className="w-full flex items-center mt-8 gap-4 justify-center">
              <button
                onClick={() => {
                  setShowPopup(false);
                  setInvitee("");
                  setGid("");
                  setCountryCode("");
                  setPhoneNoo("");
                  setEmailValue("");
                }}
                className={`text-white w-[40%] bg-red-600 pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
              >
                Cancel
              </button>
              <button
                onClick={Invite}
                className={`text-white w-[40%] bg-primary pl-5 pr-5 pt-1 pb-1 rounded-lg sm:text-lg text-md `}
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">
            Are you sure you want to delete this event?
          </p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShowDone(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              NO
            </button>
            <button
              onClick={Delete}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showInviteDone ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowInvteDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">{err}</p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShowInvteDone(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[40%]"
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowDone(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center font-semibold mt-[1.5rem] mb-5">{err}</p>
          <div className="flex w-full items-center justify-center gap-2">
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-red-600 w-[40%]"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
