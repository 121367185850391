import { useState } from "react";
import { Avatar, Dropdown, Menu, Select, DatePicker, Input } from "antd";
import totalEmp from "../../../imgs/total-employee.png";
import onLeave from "../../../imgs/user.png";
import workingEmp from "../../../imgs/working-employee.png";
import pendingTasks from "../../../imgs/pending.png";
import DetailCard from "../../card/DetailCard";
import AttendanceTable from "./components/AttendanceTable";

const { Option } = Select;

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "254",
    subTitle: "Total Employees",
  },
  {
    id: 2,
    icon: onLeave,
    title: "20",
    subTitle: "Working Employees",
  },
  {
    id: 3,
    icon: workingEmp,
    title: "20",
    subTitle: "On Leave",
  },
  {
    id: 4,
    icon: pendingTasks,
    title: "4",
    subTitle: "On Break",
  },
];

const columnHeaders = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name, row) => (
      <div className="flex items-center">
        <Avatar src={row?.profile} className="border-2 border-primary mr-2 w-10 h-10" />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },

  {
    title: "Position",
    dataIndex: "position",
    key: "position",
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
  },
  {
    title: "Device",
    dataIndex: "device",
    key: "device",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Hours",
    dataIndex: "hours",
    key: "hours",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <div
        className={`p-1 px-3 text-center ${
          status === "Onsite" ? "bg-primary" : "bg-red-600"
        } text-white font-semibold rounded-lg`}>
        {status}
      </div>
    ),
  },
];

const dummyData = [
  {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    device: "Clock-In",
    type: "Fingerprint",
    hours: "06",
    status: "Onsite",
  },
  {
    id: "2",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    device: "Visitor",
    department: "Mechanical",
    type: "Facial",
    hours: "04",
    status: "Onsite",
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    device: "Clock-Out",
    department: "Medical",
    type: "Manual Punch",
    hours: "08",
    status: "Shift Complete",
  },
  {
    id: "4",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    device: "Clock-In",
    type: "Fingerprint",
    hours: "06",
    status: "Onsite",
  },
  {
    id: "5",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    device: "Visitor",
    department: "Mechanical",
    type: "Facial",
    hours: "04",
    status: "Onsite",
  },
  {
    id: "6",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    device: "Clock-Out",
    department: "Medical",
    type: "Manual Punch",
    hours: "08",
    status: "Shift Complete",
  },
  {
    id: "7",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    device: "Clock-In",
    type: "Fingerprint",
    hours: "06",
    status: "Onsite",
  },
  {
    id: "8",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    position: "Developer",
    device: "Visitor",
    department: "Mechanical",
    type: "Facial",
    hours: "04",
    status: "Onsite",
  },
  {
    id: "9",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    position: "Designer",
    device: "Clock-Out",
    department: "Medical",
    type: "Manual Punch",
    hours: "08",
    status: "Shift Complete",
  },
  {
    id: "10",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    position: "Manager",
    department: "IT",
    device: "Clock-In",
    type: "Fingerprint",
    hours: "06",
    status: "Onsite",
  },
];

const AttendancePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleMonthChange = (date, dateString) => setSelectedMonth(dateString);
  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleStatusChange = (value) => setStatusSelected(value);

  const filteredData = dummyData.filter((record) => {
    const matchesStatus = statusSelected ? record.status === statusSelected : true;
    const matchesSearch = record.name?.toLowerCase().includes(searchTerm.toLowerCase());
    return record && matchesStatus && matchesSearch;
  });

  return (
    <div className="px-2">
      <h2 className="text-xl font-semibold">Attendance</h2>
      <div className="my-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 place-items-center">
          {cardsData.map((card) => (
            <DetailCard
              key={card.id}
              icon={card.icon}
              title={card.title}
              subTitle={card.subTitle}
            />
          ))}
        </div>
      </div>
      <div className="grid gap-4 grid-cols-4 mb-4 mt-5">
        <div className="flex gap-4">
          <h2 className="text-xl font-semibold">Filter:</h2>
          <Select
            placeholder="Status"
            onChange={handleStatusChange}
            className="w-full h-[32px]"
            allowClear>
            {Array.from(new Set(dummyData.map((data) => data.status))).map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </div>
        <DatePicker.MonthPicker
          placeholder="Select Month"
          onChange={handleMonthChange}
          className="w-[20%] h-[32px]"
        />
        <div></div>
        <Input
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full h-[32px]"
        />
      </div>
      <AttendanceTable
        columns={columnHeaders}
        data={filteredData}
        pageSize={10}
        actions={
          <Menu>
            <Menu.Item key="1">View</Menu.Item>
            <Menu.Item key="2">Edit</Menu.Item>
            <Menu.Item key="3">Delete</Menu.Item>
          </Menu>
        }
      />
    </div>
  );
};

export default AttendancePage;
