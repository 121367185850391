import React, { useEffect, useState } from "react";
import PageHeader from "../../pensionerVerification/pageHeader/pageHeader";
import { useNavigate } from "react-router-dom";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

const SelectLocation = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const SelectedSlot = useSelector((state) => state.SelectedSlot);
  const dataUser = useSelector((state) => state.dataUser);
  const [graphData, setGraphData] = useState([]);
  const [employeeCount, setEmployeeCount] = useState("----");
  const [pensionerCount, setPensionerCount] = useState("----");

  //Chart Data
  useEffect(() => {
    const api = `v1/scheduler-verification-count/status-counts-by-coverage?distributioncoverageId=${distributionCenter?.id}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        setGraphData(data?.data);
      })
      .catch(() => {});
  }, []);

  //Get Employeee Count
  useEffect(() => {
    const api = `v1/scheduler-verification-count/center-employees-count?distributioncoverageId=${distributionCenter?.id}`;
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
      .then((data) => {
        setEmployeeCount(data?.data);
      })
      .catch(() => {});
  }, []);


    //Get Pensioner Count
    useEffect(() => {
      const api = `v1/scheduler-verification-count/pensioners-by-coverage?distributioncoverageId=${distributionCenter?.id}`;
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/${api}`, {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        })
        .then((data) => {
          setPensionerCount(data?.data);
        })
        .catch(() => {});
    }, []);

  const formatProgramNames = (data) => {
    return data
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter after space or hyphen
  };

  const data1 = [
    {
      title: "Verified",
      value: graphData?.verifiedCount,
      bg: "#1BA397",
    },
    {
      title: "In-process",
      value: graphData?.inProcessCount,
      bg: "#FFCD69",
    },
    {
      title: "Rejected",
      value: graphData?.rejectedCount,
      bg: "#CE1126",
    },
    {
      title: "Pending",
      value: graphData?.pendingCount,
      bg: "#C1C0BC",
    },
  ];

  console.log(data1);
  // const LegendComponent = ({ data }) => (
  //   <div className="flex flex-col items-start">
  //     {data.map((entry, index) => (
  //       <div
  //         key={`legend-${index}`}
  //         className="flex items-center mb-2 justify-between bg-red-600"
  //       >
  //         <div className="flex">
  //           <div
  //             className="w-4 h-4 rounded-full"
  //             style={{ backgroundColor: entry.bg }}
  //           />
  //           <span className="ml-2">{entry.title}</span>{" "}
  //         </div>
  //         <div
  //           className={`
  //           ${
  //             entry.title === "In-process"
  //               ? "font-semibold ml-8"
  //               : "font-semibold ml-12"
  //           }
  //           `}
  //         >
  //           {entry.value}
  //         </div>
  //       </div>
  //     ))}
  //   </div>
  // );

  const LegendComponent = ({ data }) => (
    <div className="flex flex-col items-start">
      {data.map((entry, index) => (
        <div
          key={`legend-${index}`}
          className="flex items-center mb-2 justify-between w-full"
        >
          <div className="flex items-center">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: entry.bg }}
            />
            <span className="ml-2">{entry.title}</span>
          </div>
          <div className="flex-grow"></div> {/* Add flexible space here */}
          <div className="font-semibold ml-6">{entry.value}</div>
        </div>
      ))}
    </div>
  );

  const COLORS = data1.map((item) => item.bg);
  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4  min-h-screen">
      <PageHeader
        title={"Schedule Detail"}
        route={"/scheduleVerification/schedules"}
        header={true}
      />
      <div className="flex justify-center flex-col items-center">
        <div
          className={`bg-[#F6F4F4] shadow-lg rounded-sm mx-4 m-5 p-6 sm:w-[80%] w-full`}
        >
          <form action="">
            <div className="flex justify-center items-center p-4 flex-col">
              <div className="grid cursor-pointer sm:grid-cols-2 grid-cols-1 gap-4 grid-cols-1  w-full">
                <div className="flex flex-col gap-2">
                  <div className="w-full">
                    <div className="flex justify-between">
                      <span className="font-medium">Selected Center</span>
                      <span className="font-semibold text-gray-500">
                        {distributionCenter?.coverage}{" "}
                        {
                          distributionCenter?.distributioncenter
                            ?.distributionlocation?.distributionregion?.regionNo
                        }
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium">Program</span>
                      <span className=" font-semibold text-gray-500">
                        {formatProgramNames(
                          SelectedSlot?.program === "plwd"
                            ? "PLWD"
                            : SelectedSlot?.program === "pa-medical"
                            ? "PA Medical"
                            : SelectedSlot?.program === "pa-economical"
                            ? "PA Economical"
                            : SelectedSlot?.program
                        )}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-medium">Status</span>
                      <span
                        className={`font-semibold  text-[0.93rem] ${
                          SelectedSlot?.status === "In-Process"
                            ? "text-[#E5A608]"
                            : SelectedSlot?.status === "Completed"
                            ? "text-primary"
                            : "text-gray-500"
                        }`}
                      >
                        {SelectedSlot?.status}
                      </span>
                    </div>
                  </div>
                  <div className="bg-white p-6  pb-8 shadow-md rounded-md w-full max-w-lg mx-auto">
                    <label className="block font-medium mb-2">
                      Date & Time
                    </label>
                    <div className="bg-[#FAFAFA] p-4">
                      <div className="flex space-x-4 mb-4 ">
                        <div
                          // onClick={() => setSelectedDate(tab)}
                          className={`p-2 rounded-md bg-primary text-white`}
                        >
                          {moment(SelectedSlot?.date).format("MM/DD/YYYY")}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4  bg-white p-2">
                        <div>
                          <label className="font-semibold">Open Time</label>
                          <div className="p-2 bg-[#FAFAFA] rounded-md mt-1">
                            {SelectedSlot?.openTime
                              ? moment(
                                  `${SelectedSlot?.date} ${SelectedSlot?.openTime}`,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("hh:mm A")
                              : "No Time Provided"}
                          </div>
                        </div>
                        <div>
                          <label className="font-semibold">Close Time</label>
                          <div className="p-2 bg-[#FAFAFA] rounded-md mt-1">
                            {SelectedSlot?.closeTime
                              ? moment(
                                  `${SelectedSlot?.date} ${SelectedSlot?.closeTime}`,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("hh:mm A")
                              : "No Time Provided"}
                          </div>
                        </div>
                      </div>
                      <h1 className="py-2">Break Time</h1>
                      <div className="grid grid-cols-2 gap-4 bg-white p-2">
                        <div>
                          <label className="font-semibold">Start</label>
                          <div className="p-2 bg-[#FAFAFA] rounded-md mt-1">
                            {SelectedSlot?.breakStart
                              ? moment(
                                  `${SelectedSlot?.date} ${SelectedSlot?.breakStart}`,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("hh:mm A")
                              : "No Time Provided"}
                          </div>
                        </div>
                        <div>
                          <label className="font-semibold">End</label>
                          <div className="p-2 bg-[#FAFAFA] rounded-md mt-1">
                            {SelectedSlot?.breakEnd
                              ? moment(
                                  `${SelectedSlot?.date} ${SelectedSlot?.breakEnd}`,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("hh:mm A")
                              : "No Time Provided"}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-4 mt-7">
                        <h1 className="font-semibold whitespace-nowrap">
                          Verification slot duration (Min)
                        </h1>
                        <div className="flex gap-2 bg-[#F6F4F4] px-2">
                          <input
                            type="text"
                            value={SelectedSlot?.slotDuration}
                            className="w-[4rem] h-[2rem] px-2 rounded-lg text-center bg-[#F6F4F4] text-[1rem]"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-2 bg-[#FAFAFA] pl-4 pr-4 rounded-md gap-2">
                  <div className="w-full">
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col gap-1">
                        <span className="font-medium">Total Pensioner</span>
                        <span className="font-semibold bg-white pl-12 pr-12 p-2 text-center">
                          {pensionerCount}
                        </span>
                      </div>
                      {/* <span
                        onClick={() =>
                          router("/scheduleVerification/PensionerDetails")
                        }
                        className="font-medium cursor-pointer text-primary"
                      >
                        View detail
                      </span> */}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-shrink-0 flex items-center">
                      <LegendComponent data={data1} />
                    </div>
                    <div className="flex-grow">
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                          <Pie
                            data={data1}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                          >
                            {data1.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full p-4 mt-6 bg-white rounded-md flex gap-12 ">
                <div className="flex justify-between w-[25%]">
                  <span className="font-medium">Employees</span>
                  <span className="font-medium">{employeeCount}</span>
                </div>
                {/* <span
                  onClick={() =>
                    router("/scheduleVerification/EmployeeDetails")
                  }
                  className="font-medium cursor-pointer text-primary"
                >
                  View detail
                </span> */}
              </div>
            </div>
          </form>
        </div>
        <div
          className={`${
            SelectedSlot?.status === "Completed"
              ? "hidden"
              : "mt-3 flex  items-center sm:justify-center justify-center w-full mb-5"
          } `}
        >
          <button
            type="button"
            onClick={() => {
              router("/scheduleVerification/EditSchedule");
              dispatch({ type: "EDIT_SLOT", num: true });
            }}
            className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectLocation;
