import { useMemo, useState } from "react";
import { Avatar, Input, Select } from "antd";

import { useNavigate } from "react-router-dom";
import EmployeTable from "./components/EmployeTable";
import { EmployeeData } from "../../../constants/EmployeesData";
import DetailCard from "../../card/DetailCard";
import totalEmp from "../../../imgs/total-employee.png";
import onLeave from "../../../imgs/user.png";
import workingEmp from "../../../imgs/working-employee.png";
import pendingTasks from "../../../imgs/pending.png";

const { Option } = Select;

const cardsData = [
  {
    id: 1,
    icon: totalEmp,
    title: "254",
    subTitle: "Total Employees",
  },
  {
    id: 2,
    icon: workingEmp,
    title: "230",
    subTitle: "Current Employees",
  },
  {
    id: 3,
    icon: onLeave,
    title: "10",
    subTitle: "Employees Left",
  },
  {
    id: 4,
    icon: onLeave,
    title: "05",
    subTitle: "In Hiring Queu",
  },
];

const columns = [
  { title: "ID", key: "id" },
  {
    title: "Name",
    key: "name",
    render: (name, row) => (
      <div className="flex items-center">
        <Avatar src={row.profile} className="border-2 border-primary mr-2 w-10 h-10" />
        <p className="text-primary capitalize">{name}</p>
      </div>
    ),
  },
  { title: "Position", key: "position" },
  { title: "Department", key: "department" },
  { title: "Rate", key: "rate" },
  { title: "Address", key: "address" },
  { title: "Contact", key: "contact" },
  { title: "Marital Status", key: "maritalStatus" },
  { title: "Gender", key: "gender" },
];

const StaticTable = () => {
  const router = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleAction = (action, id) => {
    switch (action) {
      case "view":
        router(`/EmployeeDetails`);
        break;
      case "edit":
        console.log(`Edit details for employee ID: ${id}`);
        break;
      case "remove":
        console.log(`Remove employee ID: ${id}`);
        break;
      default:
        console.log("Unknown action");
    }
  };

  const filteredData = useMemo(() => {
    return EmployeeData.filter((data) => {
      const matchesSearch = data.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesPosition = selectedPosition ? data.position === selectedPosition : true;
      const matchesDepartment = selectedDepartment ? data.department === selectedDepartment : true;
      return matchesSearch && matchesPosition && matchesDepartment;
    });
  }, [searchTerm, selectedPosition, selectedDepartment]);

  return (
    <div className="h-[78vh] overflow-auto px-2">
      <div>
        <h2 className="text-xl font-semibold">Employees</h2>
      </div>

      <div className="my-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:ml-0 place-items-center ">
          {cardsData.map((card, index) => (
            <DetailCard key={index} icon={card.icon} title={card.title} subTitle={card.subTitle} />
          ))}
        </div>
      </div>
      <div className="grid gap-4 grid-cols-5 mb-4 mt-5">
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full"
        />
        <Select
          placeholder="Position"
          size="medium"
          onChange={setSelectedPosition}
          className="w-full"
          allowClear>
          {Array.from(new Set(EmployeeData.map((data) => data.position))).map((position) => (
            <Option key={position} value={position}>
              {position}
            </Option>
          ))}
        </Select>
        <Select
          placeholder="Department"
          size="medium"
          onChange={setSelectedDepartment}
          className="w-full"
          allowClear>
          {Array.from(new Set(EmployeeData.map((data) => data.department))).map((department) => (
            <Option key={department} value={department}>
              {department}
            </Option>
          ))}
        </Select>
      </div>
      <div className="">
        <EmployeTable columns={columns} data={filteredData} pageSize={5} onAction={handleAction} />
      </div>
    </div>
  );
};

export default StaticTable;
