import Ellipse from "../../../imgs/backg.svg";
import Grocery from "../../../imgs/Grocery.png";
import Pharmacy from "../../../imgs/Pharmacy.png";
import Electronics from "../../../imgs/Electronics.png";
import Books from "../../../imgs/Books.png";
import back5 from "../../../imgs/back5.png";
import Bakery from "../../../imgs/Bakery.png";
import Butcher from "../../../imgs/restaurant.png";
import Antique from "../../../imgs/furniture.png";
import Footwear from "../../../imgs/Footwear.png";
import Hardware from "../../../imgs/Hardware.png";
import Clothes from "../../../imgs/Clothes.png";
import Sports from "../../../imgs/Sports.png";
import Stationery from "../../../imgs/Stationery.png";
import img from "../../../imgs/impLogo.png";
import { useNavigate } from "react-router";
import { message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Cards from "../products/products";
let items;
const AdminPanel = () => {
  const user = useSelector((state) => state.user);
  const router = useNavigate();
  const role = useSelector((state) => state.role);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const Success = () => {
    messageApi.open({
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  items = [
    {
      key: "1",
      title: `Grocery`,
      img: Grocery,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "2",
      title: `Pharmacy`,
      img: Pharmacy,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "3",
      title: `Electronics`,
      img: Electronics,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "4",
      title: `Books`,
      img: Books,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "5",
      title: `Bakery`,
      img: Bakery,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "6",
      title: `Restaurants`,
      img: Butcher,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "7",
      title: `Furniture`,
      img: Antique,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "8",
      title: `Footwear`,
      img: Footwear,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "9",
      title: `Hardware`,
      img: Hardware,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "10",
      title: `Clothes`,
      img: Clothes,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "11",
      title: `Sports`,
      img: Sports,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
    {
      key: "12",
      title: `Stationery`,
      img: Stationery,
      routeV: "/Inventory-Categories",
      route: "/Inventory-Vendors",
    },
  ];

  return (
    <div
    // className="bg-[#D9EFED]"
    // style={{
    //   width: "100%",
    //   height: "100%",
    //   backgroundImage: `url(${Ellipse})`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "center 10%",
    //   backgroundPositionY: "-80px",
    // }}
    >
      {contextHolder}
      <div className="flex items-center justify-center bg-[#D9EFED]">
        <div className="w-[95%] mt-[1rem] px-20 rounded-md">
          <div className="cursor-pointer flex w-full items-center justify-center">
            <img
              onClick={() => {
                if (user === "vendor") {
                  router("/VendorDashboard");
                  return;
                }

                if (role !== "vendor") router("/superAdminDashboard");
                else router("/Inventory-Vendor-DashBoard");
              }}
              className="sm:w-[1.7rem] w-[1.3rem]"
              src={back5}
              alt=""
            />
            <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
              Products
            </h2>
          </div>

          <div className=" ">
            <Cards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
