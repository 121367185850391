import back5 from "../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../../../imgs/logo12.png";
import across from "../../../imgs/across.png";
import { FaTrash, FaPlus } from "react-icons/fa";
import axios from "axios";
let file;
const Gallary = () => {
  const router = useNavigate();
  const location = useLocation();
  const dash = location.pathname;
  const [images, setImages] = useState([]);
  const [err, setErr] = useState("");
  const [show, setShow] = useState(false);
  const [hostId, setHostId] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [showMsgAll, setShowMsgAll] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [fetch, setFetch] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const eventId = useSelector((state) => state.eventId);
  const token = useSelector((state) => state.token);
  const subEventId = useSelector((state) => state.subEventId);

  useEffect(() => {
    let url;
    if (dash === "/SubEvent/Gallary") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event-gallary/${subEventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event-gallary/${eventId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        console.log("GallaryEvent", data);
        setImages(data?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetch]);
  useEffect(() => {
    console.log("Images", images);
  }, [images]);

  const handleDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event-image/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setFetch(!fetch);
        setShowMsg(false);
      })
      .catch((err) => {
        console.log("errrrr", err);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  useEffect(() => {
    let url;
    if (dash === "/SubEvent/Gallary") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${subEventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/get-event/${eventId}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((data) => {
        setHostId(data?.data?.event?.hostUserId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const imageHandlerSub = async (e) => {
    file = e.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/v1/file/images?fileType=eventImage`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        UpdateGallary(data?.data?.url);
        // const newImage = {
        //   image: data.data.url,
        // };
        // setImages((prevImages) => [...prevImages, newImage]);
      })
      .catch(() => {});
  };
  const UpdateGallary = (url) => {
    const body = {
      images: [url],
    };
    if (dash === "/SubEvent/Gallary") {
      body.eventId = subEventId;
    } else {
      body.eventId = eventId;
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/event/create-event-gallary`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setFetch(!fetch);
      })
      .catch((err) => {
        console.log("errrrr", err);
        setErr(err.response.data.message);
        setShow(true);
      });
  };
  const deleteAll = () => {
    let url;
    if (dash === "/SubEvent/Gallary") {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event-gallary/${subEventId}`;
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/delete-event-gallary/${eventId}`;
    }
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setFetch(!fetch);
        setShowMsgAll(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex items-center justify-center p-2 pt-[2rem] flex-col">
      <div className="sm:w-[80%] w-full p-2 flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => {
                if (dash === "/Event/Gallary") router("/EventDetails");
                else router("/SubEventDetails");
              }}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Images Gallary
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-[80%] w-full bg-[#D9EFED] pt-8 pb-8 min-h-[50vh] mt-6 rounded-lg shadow-md p-4 ">
        {dataUser?.data?.user?.id === hostId && (
          <div className="mb-4 w-full flex items-center justify-end">
            <h1
              onClick={() => setShowMsgAll(true)}
              className="font-semibold cursor-pointer text-red-600 hover:underline p-1 pl-3 pr-3 bg-gray-50 rounded-lg text-center hover:bg-gray-100"
            >
              Delete all
            </h1>
          </div>
        )}
        <div className="flex gap-2 grid sm:grid-cols-4 gap-y-8">
          {images.map((image) => (
            <div
              key={image.id}
              className="relative h-[15rem] border bg-gray-50 rounded-md w-full"
            >
              <img
                src={image.image}
                alt="Event"
                className="w-full h-full rounded-md"
              />
              {dataUser?.data?.user?.id === hostId && (
                <button
                  className="absolute bottom-2 right-2"
                  onClick={() => {
                    setErr("Are you sure you want to delete this Image.");
                    setDeleteId(image.id);
                    setShowMsg(true);
                  }}
                >
                  <FaTrash color="#FF0000" />
                </button>
              )}
            </div>
          ))}
          {dataUser?.data?.user?.id === hostId && (
            <label
              htmlFor="specia"
              className="cursor-pointer w-full bg-gray-50 h-[15rem] flex items-center justify-center"
            >
              <div className="w-full h-full flex items-center justify-center border-2 border-dashed border-gray-400 rounded-md">
                <FaPlus className="text-gray-400" size={40} />
              </div>
              <input
                type="file"
                className="hidden w-[100%] cursor-pointer"
                id="specia"
                accept="image/png, image/gif, image/jpeg"
                onChange={imageHandlerSub}
              />
            </label>
          )}
        </div>
      </div>
      <div
        className={`${
          showMsg ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowMsg(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center mt-[1.5rem] mb-5">{err}</p>
          <div className="w-full flex items-center justify-center gap-2 ">
            <button
              onClick={() => setShowMsg(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={handleDelete}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showMsgAll ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl w-[20rem] pb-6 flex items-center justify-center p-3 bg-white flex-col">
          <img
            onClick={() => setShowMsgAll(false)}
            src={logo}
            alt=""
            className="w-20 h-20"
          />
          <p className="text-center mt-[1.5rem] mb-5">
            Are you sure you want to delete complete gallary?
          </p>
          <div className="w-full flex items-center justify-center gap-2 ">
            <button
              onClick={() => setShowMsgAll(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-white bg-red-600 w-[40%]"
            >
              No
            </button>
            <button
              onClick={deleteAll}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-white bg-primary w-[40%]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-[-11%] left-[42%]"
            onClick={() => setShow(false)}
          >
            <img src={across} alt="" />
          </div>
          <p className="text-center mt-[3rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
