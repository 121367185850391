import React, { useState } from "react";
import { useSelector } from "react-redux";
import ErrorModal from "../../Merchant/Modals/errorModal";
import { Dropdown, Button, Menu } from "antd";
import vector from "../../../imgs/Vector.svg";
import vector1 from "../../../imgs/Vector-1.svg";
import vector2 from "../../../imgs/Vector-2.svg";
import vector3 from "../../../imgs/Vector-3.svg";
import vector4 from "../../../imgs/Vector-4.svg";
import { SettingFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const items4 = [
    {
      label: "Program Payment",
      key: "1",
      icon: (
        <img
          src={vector4}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Sap Money Payment",
      key: "2",
      icon: (
        <img
          src={vector}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Access Setting",
      key: "3",
      icon: (
        <img
          src={vector1}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Holidays Setting",
      key: "4",
      icon: (
        <img
          src={vector2}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Scheduler Time Management",
      key: "5",
      icon: (
        <img
          src={vector3}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
  ];
  const router = useNavigate();

  const dataUser = useSelector((state) => state.dataUser);
  const profile = dataUser?.data?.user?.person?.image;
  const Name =
    dataUser?.data?.user?.person?.firstName + " " + dataUser?.data?.user?.person?.lastName;
  const [showModal, setShowModal] = useState(false);
  const [err, setErr] = useState("");

  const onClickMenu4 = ({ key }) => {
    if (key === "1") {
      router("/superAdminDashboard/Programs");
    } else if (key === "2") {
      router("/superadmin/setTransactionLimits");
    } else if (key === "3") {
      router("/superAdminDashboard/AccessSetting");
    } else if (key === "4") {
      router("/superAdminDashboard/holidaySetting");
    } else if (key === "5") {
      router("/superAdminDashboard/SchedulerTimeSetting");
    }
  };

  return (
    <div className="">
      <div className="flex items-center h-[3rem] w-[95%] justify-between text-lg">
        <h2 className="mb-3 font-bold">
          Welcome <span className="mr-2 font-semibold text-gray-500">{Name}</span>
        </h2>
        <div className="flex items-center gap-4">
          <Dropdown
            menu={{
              items: items4,
              onClick: onClickMenu4,
            }}
            placement="bottomRight"
            arrow
            className="custom-dropdown">
            <SettingFilled className="text-[22px] flex items-center justify-center" />
          </Dropdown>
          {/* <span className="mr-2 font-semibold ">{Name}</span> */}
          <img src={profile} alt="Profile" className="w-10 h-10 rounded-full" />
        </div>
      </div>
      {showModal && <ErrorModal setShow={setShowModal} message={err} />}
    </div>
  );
};

export default Header;
