import logo1 from "../../imgs/logo12.png";
import L3 from "../../imgs/languages 1.png";
import gro from "../../imgs/groImg.png";
import arrow from "../../imgs/Vector.png";
import trash from "../../imgs/trash.png";
import hamB from "../../imgs/hamBlack.png";
import hamW from "../../imgs/hamWhite.png";
import cam1 from "../../imgs/cam1.png";
import Webcam from "react-webcam";
import cross from "../../imgs/cross.png";
import across from "../../imgs/across.png";
import { Dropdown, Button, Menu } from "antd";
import PhoneInput from "react-phone-number-input";
import eye from "../../imgs/eyee.png";
import schulericon from "../../imgs/ri_time-line.png";
import vector from "../../imgs/Vector.svg";
import vector1 from "../../imgs/Vector-1.svg";
import vector2 from "../../imgs/Vector-2.svg";
import vector3 from "../../imgs/Vector-3.svg";
import vector4 from "../../imgs/Vector-4.svg";
import {
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  AuditOutlined,
  CompassOutlined,
  SnippetsOutlined,
  LaptopOutlined,
  RedEnvelopeOutlined,
  HomeOutlined,
  InteractionOutlined,
  BellFilled,
  MessageOutlined,
  SettingFilled,
  ClockCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmpRegisterPol from "../Pol/empRegisterPol";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
const { SubMenu } = Menu;
let items = [];
let file;
const Nav = () => {
  const dataUser = useSelector((state) => state.dataUser);
  const pol = useSelector((state) => state.pol);
  const polStatus = localStorage.getItem("pol");
  const privilege = useSelector((state) => state.myPrivileges);
  const universalPenId = useSelector((state) => state.universalPenId);
  const [test, setTest] = useState(false);
  const [err, setErr] = useState(false);
  const [openKey, setOpenKey] = useState(null);
  const popupRef = useRef(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [navC, setNavC] = useState("home");
  const [showx, setShowx] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showR, setShowR] = useState(false);
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const PesnionerLinkedCenterCheck = useSelector(
    (state) => state.PesnionerLinkedCenterCheck
  );
  const PesnionerLinkedCenter = useSelector(
    (state) => state.PesnionerLinkedCenter
  );
  const user = dataUser?.data?.user?.roles[0];
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const dash = location.pathname;
  const resetStates = () => {
    dispatch({ type: "USER", num: null });
    dispatch({ type: "authStateSet", num: "false" });
    dispatch({ type: "tokenSet", num: "0" });
    dispatch({ type: "tokenRef", num: "0" });
    dispatch({ type: "myPrivileges", num: "0" });
    dispatch({ type: "pensionerName", num: "" });
    dispatch({ type: "pensionerId", num: "" });
    dispatch({ type: "IdDoneSet", num: "" });
    dispatch({ type: "universalPenSet", num: "" });
    dispatch({ type: "RSET", num: 0 });
    dispatch({ type: "ISET", num: 1 });
    dispatch({ type: "BRSET", num: 0 });
    dispatch({ type: "BISET", num: 0 });
    dispatch({ type: "ASET", num: 0 });
    dispatch({ type: "setUserData", num: [] });
    dispatch({ type: "userDataSet", num: "" });
    dispatch({ type: "penId", num: "0" });
    dispatch({ type: "benId", num: "0" });
    dispatch({ type: "setProfileData", num: [] });
    dispatch({ type: "polIdSet", num: "" });
    dispatch({ type: "polNameSet", num: "" });
    dispatch({ type: "polStatusSet", num: "" });
    dispatch({ type: "popDataSet", num: "" });
    dispatch({ type: "appQuestionsSet", num: [] });
    dispatch({ type: "data1Set", num: "" });
    dispatch({ type: "data2Set", num: "" });
    dispatch({ type: "data3Set", num: "" });
    dispatch({ type: "nameSet", num: "" });
    dispatch({ type: "imageSet", num: "" });
    dispatch({ type: "setData", num: [] });
    dispatch({ type: "CHAT_SELECTED_PHONE_NO", num: null });
    dispatch({ type: "CHAT_SELECTED_USER", num: null });
    dispatch({ type: "CHAT_SELECTED_NEW_USER", data: null });
    dispatch({ type: "CHAT_MESSAGES", messages: [] });
    dispatch({ type: "PENSIONER_INFO", num: "" });
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };
  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };
  const handleSubMenuClick = (key) => {
    setOpenKey(openKey === key ? null : key);
  };
  useEffect(() => {
    if (PesnionerLinkedCenterCheck === false) {
      dispatch({
        type: "PENSIONER_LINKED_CENTER",
        num: null,
      });
    }
    if (dataUser?.data?.user?.roles[0] === "employee") {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Live Verification ",
          key: "4",
          icon: <LaptopOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },

        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else if (
      dataUser?.data?.user?.roles[0] === "admin" ||
      dataUser?.data?.user?.roles[0] === "superadmin"
    ) {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Schedule Verification ",
          key: "20",
          icon: <LaptopOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else if (
      dataUser?.data?.user?.roles[0] === "gro-employee" ||
      dataUser?.data?.user?.roles[0] === "gro-admin"
    ) {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Message Panel ",
          key: "6",
          icon: <MessageOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    } else {
      items = [
        {
          label: "Profile ",
          key: "1",
          icon: <UserOutlined className="some" />,
        },
        {
          label: "Account Status ",
          key: "3",
          icon: <CompassOutlined className="some" />,
        },
        {
          label: "Book a Slot",
          key: "21",
          icon: <CalendarOutlined className="some" />,
        },
        {
          label: "Documents",
          key: "10",
          icon: <SnippetsOutlined className="some" />,
        },
        {
          label: "Logout",
          key: "2",
          icon: <LogoutOutlined className="some" />,
        },
      ];
    }
    if (!test) {
      setTest(true);
    } else {
      setTest(false);
    }
  }, []);
  const items1 = [
    {
      label: "English",
      key: "5",
    },
    {
      label: "Spanish",
      key: "6",
    },
    {
      label: "Dutch",
      key: "7",
    },
    {
      label: "Portuguese",
      key: "8",
    },
  ];
  const items2 = [
    {
      label: "Capture Image",
      key: "9",
    },
  ];

  const items3 = [
    {
      label: "POL Already Uploaded",
    },
  ];

  const items4 = [
    {
      label: "Program Payment",
      key: "1",
      icon: (
        <img
          src={vector4}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Sap Money Payment",
      key: "2",
      icon: (
        <img
          src={vector}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Access Setting",
      key: "3",
      icon: (
        <img
          src={vector1}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Holidays Setting",
      key: "4",
      icon: (
        <img
          src={vector2}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Scheduler Time Management",
      key: "5",
      icon: (
        <img
          src={vector3}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
  ];

  const onClick1 = ({ key }) => {
    if (key === "10") {
      dispatch({ type: "pol" });
    } else if (key === "9") {
      setWebcamOpen(!webcamOpen);
    }
  };
  const onClick = ({ key }) => {
    if (key === "2") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`, {
          refreshToken: `${dataUser?.data.tokens.refresh.token}`,
        })
        .then(() => {
          localStorage.removeItem("EPhone");
          localStorage.removeItem("role");
          localStorage.removeItem("addPenEmp");
          localStorage.removeItem("status");
          localStorage.removeItem("app");
          localStorage.removeItem("pol");
          localStorage.removeItem("authen");
          localStorage.removeItem("penNum");
          resetStates();
          router("/");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    } else if (key === "1") {
      // alert("Hello")
      // if(user === "admin"){
      //   router("/EDashboard/Account");
      // }
      // return
      if (location.pathname[1] === "E") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        dispatch({ type: "Employee" });
        router("/EDashboard/Account");
        dispatch({ type: "PENSIONER" });
        dispatch({ type: "editSet", num: "editEmployee" });
      } else if (location.pathname[1] === "D") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/Dashboard/PensionerAccount");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "admin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "superadmin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-employee") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-admin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      }
    } else if (key === "4") {
      if (dataUser?.data?.user?.status[0] !== "pending") {
        router("/EDashboard/polList");
      }
    } else if (key === "3") {
      if (location.pathname[1] === "D") {
        router("/Dashboard/statusPage");
      } else if (location.pathname[1] === "E") {
        router("/EDashboard/statusPage");
      }
    } else if (key === "5") {
      if (
        user === "employee" &&
        privilege &&
        privilege?.includes("View history")
      ) {
        router("/EDashboard/PowerBi");
      } else if (user === "admin" || user === "superadmin") {
        // Handle navigation for admin users
        user === "admin"
          ? router("/AdminDashboard/PowerBi")
          : router("/superAdminDashboard/PowerBi");
      } else {
        // Handle navigation for other roles or non-privileged users
        setShowR(true);
      }
    } else if (key === "10") {
      router("/Dashboard/additionalDocs");
    } else if (key === "6") {
      user === "employee"
        ? router("/EDashboard/chatPanel")
        : user === "admin"
        ? router("/AdminDashboard/chatPanel")
        : router("/superAdminDashboard/chatPanel");
    } else if (key === "7") {
      router("/meeting");
    } else if (key === "20") {
      router("/pensionerVerificationScheduling/status");
    } else if (key === "21") {
      if (PesnionerLinkedCenter !== null) {
        router("/pensionerVerification/BookSlot");
        return;
      }
      dispatch({ type: "PENSIONER_BOOK_SLOT", num: true });
      router("/pensionerVerification/BookSlot/SelectLocation");
    }
  };
  const onClickMenu = (key) => {
    if (key === "2") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/logout`, {
          refreshToken: `${dataUser?.data.tokens.refresh.token}`,
        })
        .then(() => {
          localStorage.removeItem("EPhone");
          localStorage.removeItem("role");
          localStorage.removeItem("addPenEmp");
          localStorage.removeItem("status");
          localStorage.removeItem("app");
          localStorage.removeItem("pol");
          localStorage.removeItem("authen");
          localStorage.removeItem("penNum");
          resetStates();
          router("/");
        })
        .catch((err) => {
          setErr(err?.response?.data?.message);
          setShow(true);
        });
    } else if (key === "1") {
      if (location.pathname[1] === "E") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        dispatch({ type: "Employee" });
        router("/EDashboard/Account");
        dispatch({ type: "PENSIONER" });
        dispatch({ type: "editSet", num: "editEmployee" });
      } else if (location.pathname[1] === "D") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/Dashboard/PensionerAccount");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (location.pathname[1] === "a") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (location.pathname[1] === "s") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-employee") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      } else if (dataUser.data.user.roles[0] === "gro-admin") {
        dispatch({ type: "universalPenSet", num: dataUser?.data?.user?.id });
        router("/EDashboard/Account");
        dispatch({ type: "editSet", num: "editPensioner" });
      }
    } else if (key === "4") {
      if (dataUser?.data?.user?.status[0] !== "pending") {
        router("/EDashboard/polList");
      }
    } else if (key === "3") {
      if (location.pathname[1] === "D") {
        router("/Dashboard/statusPage");
      } else if (location.pathname[1] === "E") {
        router("/EDashboard/statusPage");
      }
    } else if (key === "5") {
      if (
        user === "employee" &&
        privilege &&
        privilege?.includes("View history")
      ) {
        router("/EDashboard/PowerBi");
      } else if (user === "admin" || user === "superadmin") {
        // Handle navigation for admin users
        user === "admin"
          ? router("/AdminDashboard/PowerBi")
          : router("/superAdminDashboard/PowerBi");
      } else {
        // Handle navigation for other roles or non-privileged users
        setShowR(true);
      }
    } else if (key === "10") {
      router("/Dashboard/additionalDocs");
    } else if (key === "6") {
      user === "employee"
        ? router("/EDashboard/chatPanel")
        : user === "admin"
        ? router("/AdminDashboard/chatPanel")
        : router("/superAdminDashboard/chatPanel");
    } else if (key === "7") {
      router("/meeting");
    }
  };

  const onClickMenu4 = ({ key }) => {
    if (key === "1") {
      router("/superAdminDashboard/Programs");
    } else if (key === "2") {
      router("/superadmin/setTransactionLimits");
    } else if (key === "3") {
      router("/superAdminDashboard/AccessSetting");
    } else if (key === "4") {
      router("/superAdminDashboard/holidaySetting");
    } else if (key === "5") {
      router("/superAdminDashboard/SchedulerTimeSetting");
    }
  };

  useEffect(() => {}, [universalPenId]);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const captureImage = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // file = await (await fetch(imageSrc)).blob();
    //
    file = dataURLtoFile("data:text/plain;base64,aGVsbG8=", "hello.txt");

    setCapturedImage(imageSrc);
  };
  const [webcamOpen, setWebcamOpen] = useState(false);

  const uploadHandler = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);

    if (dataUser?.data?.user?.polStatus === "no-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/face-image?name=${dataUser?.data?.user?.person.firstName} ${dataUser?.data?.user?.person.lastName}`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser?.data.tokens.access.token}`,
        },
      })
        .then((data) => {
          if (data.data.faceDetection === false) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            localStorage.setItem("pol", "active-pol");
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === "Failed to register proof of life"
          ) {
            setErr(
              "The quality of your webcam quality is not up to the mark. Please upgrade your webcam quality or use the mobile application for POL. "
            );
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    } else if (dataUser?.data?.user?.polStatus === "expired-pol") {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/v1/file/detect-image`,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      })
        .then((data) => {
          if (!data.data.faceDetection) {
            setErr("Sorry the picture did not match");
            setShow(true);
          } else {
            localStorage.setItem("pol", "active-pol");
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === "Failed to register proof of life"
          ) {
            setErr(
              "The quality of your webcam quality is not up to the mark. Please upgrade your webcam quality or use the mobile application for POL. "
            );
          } else {
            setErr(err.response.data.message);
          }
          setShow(true);
        });
    }
  };
  const togglePopup = () => {
    setPopupOpen((prevPopupOpen) => !prevPopupOpen);
  };
  const notifications = [
    { type: "Confirmation", message: "Transaction successful!" },
    { type: "Informational", message: "New message received." },
    { type: "Alert", message: "Critical update required!" },
    { type: "Error", message: "Error: Unable to connect to server." },
  ];
  const handleHomeClick = () => {
    let route = "/Dashboard";
    if (user === "employee") route = "/EDashboard";
    else if (user === "superadmin") route = "/superAdminDashboard";
    else if (user === "admin") route = "/AdminDashboard";
    else if (user === "gro-admin") route = "/GroAdminDash";
    else if (user === "gro-employee") route = "/GRO";
    router(route);
    setShowx(false);
  };
  useEffect(() => {
    if (
      dash === "/Dashboard" ||
      dash === "/EDashboard" ||
      dash === "/superAdminDashboard" ||
      dash === "/AdminDashboard" ||
      dash === "/GroAdminDash" ||
      dash === "/GRO"
    ) {
      setNavC("home");
    } else if (
      dash === "/EDashboard/About" ||
      dash === "/superAdminDashboard/About" ||
      dash === "/Dashboard/About" ||
      dash === "/AdminDashboard/About" ||
      dash === "/GroAdminDash/About" ||
      dash === "/GRO/About"
    ) {
      setNavC("about");
    } else if (
      dash === "/AdminDashboard/rolesPrivileges" ||
      dash === "/superAdminDashboard/rolesPrivileges"
    ) {
      setNavC("privileges");
    } else {
      setNavC("");
    }
  }, [dash]);
  // Handle About click
  const handleAboutClick = () => {
    let route = "/Dashboard/About";
    if (user === "employee") route = "/EDashboard/About";
    else if (user === "superadmin") route = "/superAdminDashboard/About";
    else if (user === "admin") route = "/AdminDashboard/About";
    else if (user === "gro-admin") route = "/GroAdminDash/About";
    else if (user === "gro-employee") route = "/GRO/About";
    router(route);
  };
  // Handle Roles & Privileges click
  const handleRolesPrivilegesClick = () => {
    let route = "/AdminDashboard/rolesPrivileges";
    if (user === "superadmin") route = "/superAdminDashboard/rolesPrivileges";
    router(route);
    setShowx(false);
  };
  useEffect(() => {
    if (showx) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showx]);
  return (
    <div className="relative flex items-center justify-center">
      <nav className="sm:w-[85%] h-[16vh] w-[95%] flex  justify-between items-center">
        {webcamOpen && (
          <div className="w-[100%] h-[100vh] absolute top-0 left-0 flex justify-center items-center bg-slate-400 bg-opacity-[40%] z-[100] ">
            <div className="relative w-[35rem] h-[25rem] bg-white p-6 rounded-2xl flex flex-col justify-center items-center">
              <h2 className="font-semibold mb-4 text-[1.5rem]">
                {capturedImage === null ? " Capture Image" : "Upload Image"}
              </h2>
              <div
                onClick={() => {
                  setCapturedImage(null);
                  setWebcamOpen(false);
                }}
                className="absolute top-3 right-3"
              >
                <img className="scale-[0.7] cursor-pointer" src={cross} />
              </div>
              {capturedImage === null && (
                <div className="w-[20rem] h-[15rem]">
                  <Webcam ref={webcamRef} />
                </div>
              )}
              {capturedImage !== null && (
                <div className="relative w-[20rem] h-[15rem]">
                  <img src={capturedImage} />
                  <div
                    className="absolute bottom-0 right-0 cursor-pointer bg-white"
                    onClick={() => setCapturedImage(null)}
                  >
                    <img className="sm:w-[3rem] w-[2rem]" src={trash} alt="" />
                  </div>
                </div>
              )}
              <button
                onClick={() => {
                  if (capturedImage === null) {
                    captureImage();
                  } else {
                    uploadHandler();
                  }
                }}
                className={`lg:text-[1.1rem] bg-gradient-to-tr mt-5 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[20rem]`}
              >
                {capturedImage === null ? "Capture" : "Upload"}
              </button>
            </div>
          </div>
        )}

        {showR && (
          <div className="w-[100%] h-[100vh] absolute top-0 left-0 flex justify-center items-center bg-slate-400 bg-opacity-[40%] z-[100] ">
            <div className="relative w-[20rem] bg-white p-6 rounded-2xl flex flex-col justify-center items-center">
              <div>
                <img
                  src={logo1}
                  alt=""
                  style={{ width: "65px", height: "65px" }}
                />
              </div>
              <p className="text-center mt-[1rem] mb-[2rem]">
                You do not have access to reports. Please contact your program
                administrator.
              </p>
              <button
                onClick={() => setShowR(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-5 pr-5 text-white bg-primary w-[30%]"
              >
                OK
              </button>
            </div>
          </div>
        )}
        <div onClick={handleHomeClick} className="cursor-pointer">
          <img className="w-[5.2rem]" src={logo1} alt="" />
        </div>
        <div className="flex justify-center items-center">
          <li
            className={`text-[0rem] animate-bounce font-bold cursor-pointer md:hidden ${
              dataUser?.data?.user?.roles[0] === "pensioner" &&
              dataUser?.data?.user?.status === "approved"
                ? ""
                : "hidden"
            }`}
          >
            <Dropdown
              menu={{
                items: items2,
                onClick: onClick1,
              }}
              placement="bottomRight"
              arrow
            >
              <img src={cam1} className="w-[3rem]" alt="" />
            </Dropdown>
          </li>
          <div
            onClick={() => setShowx(true)}
            className="w-[2.5rem] h-[2.5rem] cursor-pointer flex flex-col justify-center md:hidden"
          >
            <img src={hamB} alt="" />
            {/* <div className="w-full h-[2px] bg-black"></div>
              <div className="w-full h-[2px] bg-black"></div>
              <div className="w-full h-[2px] bg-black"></div> */}
          </div>
        </div>
        <ul className="md:flex items-center gap-x-6 justify-center hidden">
          <li
            className={`text-[16px] font-bold cursor-pointer hover:text-[#F35929] ${
              navC === "home" ? "text-[#F35929]" : "text-black"
            }`}
            style={{ fontFamily: "poppins", fontWeight: "600" }}
            onClick={handleHomeClick}
          >
            Home
          </li>
          {/* <li
            className="text-[1.1rem] font-bold cursor-pointer "
            onClick={() => {
              if (
                location.pathname === "/Dashboard" ||
                location.pathname === "/EDashboard"
              ) {
                setShow1(true);
              }
            }}
          >
            Admin
          </li> */}
          {dataUser?.data?.user?.roles[0] === "admin" ||
          dataUser?.data?.user?.roles[0] === "superadmin" ? (
            <li
              className={`text-[16px] font-bold cursor-pointer hover:text-[#F35929] ${
                navC === "privileges" ? "text-[#F35929]" : "text-black"
              }`}
              style={{ fontFamily: "poppins", fontWeight: "600" }}
              onClick={handleRolesPrivilegesClick}
            >
              Roles & Privileges
            </li>
          ) : (
            ""
          )}
          <li
            className={`text-[16px] font-bold cursor-pointer hover:text-[#F35929] ${
              navC === "about" ? "text-[#F35929]" : "text-black"
            }`}
            style={{ fontFamily: "poppins", fontWeight: "600" }}
            onClick={handleAboutClick}
          >
            About Us
          </li>
          {/* <li
            onClick={() => {
              if (user === "employee") {
                router("/EDashboard/contact");
              } else if (user === "superadmin") {
                router("/superAdminDashboard/contact");
              } else if (user === "admin") {
                router("/AdminDashboard/contact");
              } else {
                router("/Dashboard/contact");
              }
            }}
            className="text-black mb-1 text-[18px] font-bold cursor-pointer"
            style={{fontFamily:"poppins", fontWeight:"600"}}
          >
            Contact
          </li> */}

          <li className="text-[1.1rem] font-bold cursor-pointer">
            <Dropdown
              menu={{
                items: items1,
              }}
              placement="bottomRight"
              arrow
            >
              <img src={L3} className="w-[1.6rem]" alt="" />
            </Dropdown>
          </li>

          {(polStatus === "no-pol" || polStatus === "expired-pol") && (
            <li
              className={`text-[1.1rem] animate-bounce font-bold cursor-pointer ${
                dataUser?.data?.user?.roles[0] === "pensioner" &&
                dataUser?.data?.user?.status === "approved"
                  ? ""
                  : "hidden"
              }
              
              `}
            >
              <Dropdown
                menu={{
                  // items: items2,
                  items:
                    polStatus === "no-pol" || polStatus === "expired-pol"
                      ? items2
                      : items3,
                  onClick: onClick1,
                }}
                placement="bottomRight"
                arrow
              >
                <img src={cam1} alt="" />
              </Dropdown>
            </li>
          )}
          <li
            onClick={togglePopup}
            onMouseDown={(e) => e.preventDefault()}
            className=" relative text-[1.1rem]  font-bold cursor-pointer"
          >
            <BellFilled className="text-[22px] flex items-center justify-center" />
            {/* <div className="absolute top-0 right-[-2px] flex items-center justify-center w-3 h-3 bg-red-500 rounded-full text-white text-xs">
              2
            </div> */}
          </li>
          <li
            className={`${
              user === "superadmin"
                ? "text-[1.6rem] font-bold cursor-pointer hover:text-[#F35929] "
                : "hidden"
            }`}
          >
            <Dropdown
              menu={{
                items: items4,
                onClick: onClickMenu4,
              }}
              placement="bottomRight"
              arrow
              className="custom-dropdown"
            >
              <SettingFilled className="text-[22px] flex items-center justify-center" />
            </Dropdown>
          </li>
          {isPopupOpen && (
            <div
              className="absolute border-t border-grey-50 right-[195px] top-[85px] bg-white p-0 m-0 rounded-lg shadow-xl flex flex-col text-left z-50"
              ref={popupRef}
            >
              <div className="w-full h-[50px] border-b border-black-200 flex items-center justify-center rounded-t-lg">
                <h1 className="w-full text-center text-xl font-bold">
                  Notifications
                </h1>
              </div>
              {notifications &&
                notifications.map((item, index) => (
                  <div key={index}>
                    <div className="p-2 w-full h-[20%] bg-[#fff]">
                      <h2 className=" font-bold m-1 text-gray-700">
                        {item.type}
                      </h2>
                      <p className=" mb-1 text-gray-500">{item.message}</p>
                    </div>
                    <hr />
                  </div>
                ))}
              <Button
                type="default"
                block
                onClick={() => {
                  router("/notifications");
                }}
                className="w-full text-white bg-primary h-[40px] rounded-b-lg rounded-t-none m-0 border-none"
              >
                View All
              </Button>
            </div>
          )}
          <li className="relative mt-2 flex items-center justify-center">
            <div className="flex flex-col flex items-center justify-center">
              <img
                src={dataUser?.data?.user?.person?.image}
                className="w-[3.3rem] h-[3.3rem] border-2 border-primary p-[1px] rounded-full"
                alt=""
              />
            </div>
            <div className="mt-1 ml-2">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                isOpen={dropdownOpen}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <div className="min-w-[100px] flex items-center capitalize justify-center">
                    {dataUser?.data?.user?.person && (
                      <h2 className="font-bold">
                        {dataUser?.data?.user?.person.firstName}&nbsp;
                        {dataUser?.data?.user?.person.lastName}
                      </h2>
                    )}
                    <h2
                      className={`font-bold ${
                        dataUser?.data?.user?.person ? "hidden" : ""
                      }`}
                    >
                      John Doe
                    </h2>
                    <img
                      src={arrow}
                      alt=""
                      className="ml-1 mt-1 flex items-center justify-center"
                    />
                  </div>
                </a>
              </Dropdown>
              <p className="text-[0.8rem] ml-1.5 font-bold text-[#F25A29]">
                {dataUser?.data?.user?.roles[0] === "employee"
                  ? "Employee"
                  : dataUser?.data?.user?.roles[0] === "pensioner"
                  ? "Pensioner"
                  : dataUser?.data?.user?.roles[0] === "admin"
                  ? "Admin"
                  : dataUser?.data?.user?.roles[0] === "superadmin"
                  ? "Super Admin"
                  : dataUser?.data?.user?.roles[0] === "gro-admin"
                  ? "GRO Admin"
                  : dataUser?.data?.user?.roles[0] === "gro-employee"
                  ? "GRO Employee"
                  : ""}
              </p>
            </div>
          </li>
          {(user === "gro-admin" || user === "gro-employee") && (
            <li className="w-[52px] h-[52px]">
              <img
                className="w-[3.3rem] mt-1 h-[3.3rem] border-2 border-primary p-[1px] rounded-full"
                src={gro}
                alt="logoGro"
              />
            </li>
          )}
        </ul>
        <div
          className={`${
            show ? "" : "hidden"
          } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
            <div
              className="absolute top-[-11%] left-[42%]"
              onClick={() => setShow(false)}
            >
              <img src={across} alt="" />
            </div>
            <p className="text-center mt-[3rem] mb-5">{err}</p>
            <button
              onClick={() => setShow(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
            >
              Ok
            </button>
          </div>
        </div>
        <div
          className={`${
            show1 ? "" : "hidden"
          } w-[100%] h-[100%] fixed z-[200] top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
        >
          <div className="relative rounded-xl w-[30rem] pb-6 flex items-center pl-[2.5rem] pr-[2.5rem] p-3 bg-white flex-col">
            <p className="text-center text-[1.4rem] mt-[3rem] mb-5 font-semibold text-primary">
              Admin Credentials
            </p>
            <div className="w-[100%]">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Phone
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                international
                countryCallingCodeEditable={false}
                className=".PhoneInputCountryIcon .PhoneInputCountrySelectArrow .PhoneInputCountrySelect"
              />
            </div>
            <div className={`mb-6 w-[100%]`}>
              <label
                htmlFor="input1"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Email
              </label>
              <input
                className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2"
                type="text"
                placeholder="Email"
                id="input1"
              />
            </div>
            <div className="flex flex-col w-[100%]">
              <label className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
                Password
              </label>
              <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  id="Pass11"
                  className="w-[100%]"
                ></input>
                <img
                  className="w-[1.6rem] cursor-pointer"
                  // onClick={passShowHandler}
                  src={eye}
                  alt=""
                />
              </div>
            </div>
            <button
              onClick={() => {
                if (location.pathname[1] === "E") {
                  setShow1(false);
                  router("/EDashboard/adminPanel");
                } else {
                  setShow1(false);
                  router("/Dashboard/adminPanel");
                }
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Login
            </button>
          </div>
        </div>
      </nav>
      {pol && dataUser?.data?.user?.roles[0] === "pensioner" && (
        <div
          className={`${
            pol && dataUser?.data?.user?.roles[0] === "pensioner"
              ? ""
              : "hidden"
          }`}
        >
          <EmpRegisterPol />
        </div>
      )}
      <div
        className={`${
          showx ? "" : "hidden"
        } fixed top-0 left-0 w-full h-full bg-white z-[300]`}
      >
        <div className="w-full flex justify-between pr-4 pl-4 bg-primary p-6 showdow-md">
          <div className="flex items-center">
            <div className="">
              <img
                className="rounded-full w-[4rem] h-[4rem] border-2 border-[#20B574]"
                src={dataUser?.data?.user?.person?.image}
                alt="img"
              />
            </div>
            <p className="pt-1 pb-1 pl-3 pr-3 text-white font-bold rounded-xl">{`${dataUser?.data?.user?.person?.title} ${dataUser?.data?.user?.person?.firstName} ${dataUser?.data?.user?.person?.lastName}`}</p>
          </div>
          <div
            onClick={() => setShowx(false)}
            className="w-[2rem] h-[2rem] cursor-pointer flex flex-col mt-4 justify-center md:hidden"
          >
            <img src={hamW} alt="" />
            {/* <div className="w-full h-[2px] bg-white"></div>
            <div className="w-full h-[2px] bg-white"></div>
            <div className="w-full h-[2px] bg-white"></div> */}
          </div>
        </div>
        <Menu
          mode="inline"
          className="mt-4 bg-white text-lg p-2"
          openKeys={openKey ? [openKey] : []}
        >
          <Menu.Item
            key="home"
            icon={<HomeOutlined />}
            onClick={handleHomeClick}
          >
            Home
          </Menu.Item>
          {(dataUser?.data?.user?.roles[0] === "admin" ||
            dataUser?.data?.user?.roles[0] === "superadmin") && (
            <Menu.Item
              key="rolesPrivileges"
              icon={<AuditOutlined />}
              onClick={handleRolesPrivilegesClick}
            >
              Roles & Privileges
            </Menu.Item>
          )}
          <Menu.Item
            key="notifications"
            icon={<BellFilled />}
            onClick={() => router("/notifications")}
          >
            Notifications
          </Menu.Item>
          <Menu.Item
            key="about"
            icon={<ExclamationCircleOutlined />}
            onClick={handleAboutClick}
          >
            About Us
          </Menu.Item>
          {/* <Menu.Item
            key="contact"
            icon={<ContactsOutlined />}
            onClick={handleContactClick}
          >
            Contact
          </Menu.Item> */}
          <SubMenu
            key="dropdown"
            title="Languages"
            className="border-b"
            icon={<RedEnvelopeOutlined />}
            onTitleClick={() => handleSubMenuClick("dropdown")}
          >
            {items1.map((item) => (
              <Menu.Item key={item.key} onClick={() => console.log(item.label)}>
                {item.label}
              </Menu.Item>
            ))}
          </SubMenu>
          <SubMenu
            key="actions"
            title="More Actions"
            className="border-b"
            icon={<InteractionOutlined />}
            onTitleClick={() => handleSubMenuClick("actions")}
          >
            {items?.map((item) => (
              <Menu.Item
                icon={item.icon}
                key={item.key}
                onClick={() => onClickMenu(item.key)}
              >
                {item.label}
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </div>
    </div>
  );
};

export default Nav;
