import axios from "axios";
import { Select } from "antd";
import { useState } from "react";
import across from "../../imgs/across.png";
import { useDispatch, useSelector } from "react-redux";
const EmployeeInfo = () => {
  const [employer, setEmployer] = useState(
    "Ministry of Human Services & Social Services Guyana"
  );
  const [supervisor, setSupervisor] = useState("");
  const [role, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [show, setShow] = useState(false);
  const [err, setErr] = useState("");
  const [check, setCheck] = useState(true);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const nextHandler = (e) => {
    if (supervisor === "") {
      setErr("Supervisor is not Added");
      setShow(true);
      setCheck(false);
    }
    // else if (role==="") {
    //   setErr("Role is not selected");
    //   setShow(true)
    //   setCheck(false)
    // }
    else if (location === "") {
      setErr("Department is not selected");
      setShow(true);
      setCheck(false);
    } else {
      setCheck(true);
    }
    e.preventDefault();
    var body;
    if (check) {
      if (role === "") {
        body = {
          employer,
          supervisor,
          departmentOrLocation: `${location}`,
        };
      } else {
        body = {
          employer,
          supervisor,
          role,
          departmentOrLocation: `${location}`,
        };
      }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/v1/employee-info`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch({ type: "IINCREMENT" });
          dispatch({ type: "POP" });
        })
        .catch((err) => {
          setErr(err.response.data.message);
          setShow(true);
        });
    }
  };
  const handleChange = (setState) => (event) => {
    setState(event.target.value);
  };
  return (
    <div className="flex justify-center">
      <form
        action=""
        className="sm:w-[50%] w-[85%] mt-10"
        onSubmit={nextHandler}
      >
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Employer:
        </h2>
        <input
          type="text"
          name=""
          placeholder="Employer"
          onChange={handleChange(setEmployer)}
          value={employer}
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Supervisor:
        </h2>
        <input
          type="text"
          name=""
          onChange={handleChange(setSupervisor)}
          value={supervisor}
          placeholder="Supervisor"
          className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
        />
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Roles/Position:
        </h2>
        <div>
          <Select
            placeholder="Select Role/Position"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
            }}
            onChange={(val) => setRole(val)}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            size="middle"
            options={[
              {
                value: "chief probation social services officer",
                label: "Chief Probation Social Services Officer",
              },
              {
                value: "customer service",
                label: "Customer Service",
              },
              {
                value: "clerk",
                label: "Clerk",
              },
              {
                value: "manager",
                label: "Manager",
              },

              {
                value: "reviewing officer",
                label: "Reviewing Officer",
              },
              {
                value: "regional director",
                label: "Regional Director",
              },
              {
                value: "social worker",
                label: "Social Worker",
              },

              {
                value: "director of social services",
                label: "Director of Social Services",
              },
              {
                value: "senior manager",
                label: "Senior Manager",
              },
              {
                value: "staff",
                label: "Staff",
              },
            ]}
          />
        </div>
        <h2 className="text-[#596F96] text-[0.88rem] font-semibold mb-2">
          Department/Location:
        </h2>
        <div>
          <Select
            placeholder="Department/Location"
            optionFilterProp="children"
            className="wow"
            style={{
              marginBottom: "1.2rem",
            }}
            onChange={(val) => setLocation(val)}
            // filterOption={(input, option) =>
            //   (option?.label ?? "")
            //     .toLowerCase()
            //     .includes(input.toLowerCase())
            // }
            size="middle"
            options={[
              {
                value: "Accounts",
                label: "Accounts",
              },
              {
                value: "Administration",
                label: "Administration",
              },
              {
                value: "Accounting",
                label: "Accounting",
              },
              {
                value: "Childcare & Protection Agency",
                label: "Childcare & Protection Agency",
              },
              {
                value: "Customer Service",
                label: "Customer service",
              },
              {
                value: "Domestic Violence",
                label: "Domestic Violence",
              },
              {
                value: "Finance",
                label: "Finance",
              },
              {
                value: "Guyana Women's Leadership Institute",
                label: "Guyana Women's Leadership Institute",
              },
              {
                value: "Human resources",
                label: "Human resources",
              },
              {
                value: "IT",
                label: "IT",
              },
              {
                value: "Legal",
                label: "Legal",
              },
              {
                value: "Marketing",
                label: "Marketing",
              },
              {
                value: "Management",
                label: "Management",
              },
              {
                value: "Minister Secretariat",
                label: "Minister Secretariat",
              },
              {
                value: "Operations",
                label: "Operations",
              },
              {
                value: "Office of the Permanent Secretary",
                label: "Office of the Permanent Secretary",
              },
              {
                value: "Palms",
                label: "Palms",
              },
              {
                value: "Production",
                label: "Production",
              },
              {
                value: "Procurement",
                label: "Procurement",
              },
              {
                value: "Probation & Social Services",
                label: "Probation & Social Services",
              },
              {
                value: "Research and development",
                label: "Research and development",
              },
              {
                value: "Sales",
                label: "Sales",
              },
              {
                value: "Special Projects Unit",
                label: "Special Projects Unit",
              },
              {
                value: "Stores",
                label: "Stores",
              },
              {
                value: "Trafficking in Persons",
                label: "Trafficking in Persons",
              },
              {
                value: "Women of Worth",
                label: "Women of Worth",
              },
            ]}
          />
        </div>
        <div className="lg:mt-[3rem] flex mt-4 items-center sm:justify-end justify-center">
          <button
            onClick={() => dispatch({ type: "IDECREMENT" })}
            className={`lg:text-[1.1rem] bg-[#F7F7F7] text-black rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Back
          </button>
          <button
            className={`lg:text-[1.1rem] bg-gradient-to-tr ml-4 bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8`}
          >
            Register
          </button>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShow(false);
                }}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmployeeInfo;
