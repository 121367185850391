import Ellipse from "../../imgs/backg.svg";
import pensionerImg from "../../imgs/pensioners.png";
import employeeImg from "../../imgs/employeeimg.png";
import merchImg from "../../imgs/merchImg.png";
import WalletImg from "../../imgs/walletImg.png";
import SocialImg from "../../imgs/SocialImg.png";
import StockImg from "../../imgs/StockImg.png";
import MaintenanceImg from "../../imgs/MaintenanceImg.png";
import BenImg from "../../imgs/BenImg.png";
import GroImg from "../../imgs/groImg.png";
import EventsImg from "../../imgs/EventsImg.png";
import programsImg from "../../imgs/programs.svg";
import img from "../../imgs/impLogo.png";
import payrol from "../../imgs/payroll.png";
import mhsssLogo from "../../imgs/mhsss_Accounting-logo.png";
import AttendanceImg from "../../imgs/AttendanceImg.png";
import reportIcon from "../../imgs/reportIcon.svg";
import { useLocation, useNavigate } from "react-router";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import cross from "../../imgs/cross.png";
import Popup1 from "../Popup/popup1";
import logo1 from "../../imgs/logo12.png";
import appDash from "../../imgs/appDash.svg";
import logomhs from "../../imgs/logomhs.png";
import tranDash from "../../imgs/tranDash.svg";
import { useEffect, useState } from "react";
import axios from "axios";
let items;
const AdminPanel = () => {
  const location = useLocation();
  const dash = location.pathname;
  const router = useNavigate();
  const dispatch = useDispatch("");
  const dataUser = useSelector((state) => state.dataUser);
  const PesnionerLinkedCenter = useSelector(
    (state) => state.PesnionerLinkedCenter
  );
  const PesnionerLinkedCenterCheck = useSelector(
    (state) => state.PesnionerLinkedCenterCheck
  );
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [err, setErr] = useState("");
  const [programsSetting, setProgramsSetting] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showRej, setShowRej] = useState(false);
  const [verification, setVerification] = useState(false);
  const [showDef, setShowDef] = useState(false);
  const [beneficiary, setBeneficiary] = useState(false);
  const [programs, setPrograms] = useState([]);
  const show = useSelector((state) => state.isLogged);
  const [messageApi, contextHolder] = message.useMessage();
  const statusCheck = useSelector((state) => state.statusCheck);
  const [showAllContentIndex, setShowAllContentIndex] = useState(-1);
  const handleToggleContent = (index) => {
    setShowAllContentIndex(index === showAllContentIndex ? -1 : index);
  };
  const Success = () => {
    messageApi.open({
      // type: 'success',
      content: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={img} alt="Your Image" width="50" height="50" />
          <h3>
            This Module is currently in development and will be launched soon.
          </h3>
        </div>
      ),
      duration: 3,
    });
  };

  useEffect(() => {
    dispatch({ type: "VERIFICATION_START", num: false });
    dispatch({ type: "PENSIONER_BOOK_SLOT", num: false });
    if (PesnionerLinkedCenterCheck === false) {
      dispatch({
        type: "PENSIONER_LINKED_CENTER",
        num: null,
      });
    }
    if (user === "pensioner") {
      setVerification(true);
    }
  }, []);

  useEffect(() => {
    if (dash === "/Dashboard") {
      if (statusCheck === "rejected") {
        setErr(dataUser?.data?.user?.statusReason);
        setShowRej(true);
      }
      if (statusCheck === "deferred") {
        setErr(dataUser?.data?.user?.statusReason);
        setShowDef(true);
      }
      if (beneficiary) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/v1/pensioner-info/programs-beneficiaries`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            const updatedPrograms = data.data.map((i) => ({
              value: i?.program?.id,
              label:
                i?.program?.name === "senior-citizen-Pension"
                  ? "Senior Citizen Pensioner"
                  : i?.program?.name.charAt(0).toUpperCase() +
                    i?.program?.name?.slice(1),
            }));
            // To set the updated array in the state, you can use the setPrograms function
            setPrograms(updatedPrograms);
          })

          .catch(() => {});
      }
    }
  }, [beneficiary]);

  if (dash === "/superAdminDashboard") {
    items = [
      {
        key: "1",
        title: `Pensioner`,
        content: "View the pensioner accounts and make decisions.",
        img: pensionerImg,
        route: "/superAdminDashboard/Pensioner_HistoryP",
      },
      {
        key: "2",
        title: `Employee`,
        content: "View and update the employee accounts.",
        img: employeeImg,
        route: "/superAdminDashboard/allEmployees",
      },
      {
        key: "3",
        title: `Beneficiaries`,
        content: "View the Beneficiaries accounts",
        img: BenImg,
        route: "",
      },
      {
        key: "16",
        title: `Pensioner Verification`,
        content: "Verify and process the Pensioner accounts.",
        img: logomhs,
        route: "/pensionerVerification/selectLocation",
      },
      {
        key: "4",
        title: `General Register Office`,
        content: "View the GRO's employee accounts.",
        img: GroImg,
        route: "/superAdminDashboard/allGroAdmins",
      },
      {
        key: "5",
        title: `Payment Setting`,
        content: "Setup global payment setting for program and sap wallet.",
        img: programsImg,
        route: "/superAdminDashboard/Programs",
      },
      {
        key: "6",
        title: `Merchant`,
        content: "View the merchant accounts for transaction and deposits.",
        img: merchImg,
        route: "/superAdminDashboard/Merchant/Listing",
      },
      {
        key: "7",
        title: `SAP Money`,
        content: "Use your QR Code for purchases. The history will be saved.",
        img: WalletImg,
        route: "/SapMoney",
      },
      {
        key: "8",
        title: `MHSSS Accounting`,
        content: "View all payments and outstanding for Merchants.",
        img: mhsssLogo,
        route: "/MHSSS/dashboard",
      },
      {
        key: "9",
        title: `Social Benefits`,
        content:
          "View the history to keep track of all your previous pensions details.",
        img: SocialImg,
        route: "",
      },
      {
        key: "10",
        title: `Stocks`,
        content: "Track your inventory of products, locations, costs.",
        img: StockImg,
        route: "inventory",
      },
      {
        key: "20",
        title: `Inventory`,
        content: "Track your inventory of products, locations, costs.",
        img: StockImg,
        route: "/SuperAdmin/InventoryDashboard",
      },
      {
        key: "11",
        title: `Maintenance`,
        content: "This manages the detail and history of your fleet.",
        img: MaintenanceImg,
        route: "/Maintenance",
      },
      {
        key: "12",
        title: `Reports`,
        content:
          "View live stats of registrations, status ,financials and  merchants.",
        img: reportIcon,
        route: "rep",
      },
      {
        key: "13",
        title: `Events`,
        content: "The announcements will be for public or private.",
        img: EventsImg,
        route: "/EmployeeDashBoard/events",
      },
      {
        key: "14",
        title: `Attendance`,
        content:
          "Time and Attendance, Gain Actionable, Real-Time data on employee presence and activity.",
        img: AttendanceImg,
        route: "/AttendanceDashBoard",
      },
      {
        key: "15",
        title: `Payroll`,
        content:
          "Payroll records contain valuable data about wages, for managing payroll processes effectively.",
        img: payrol,
        route: "",
      },
    ];
  } else if (dash === "/adminDashboard") {
    items = [
      {
        key: "1",
        title: `Pensioner`,
        content: "View the pensioner accounts and make decisions.",
        img: pensionerImg,
        route: "/AdminDashboard/Pensioner_HistoryP",
      },
      {
        key: "2",
        title: `Employee`,
        content: "View and update the employee accounts.",
        img: employeeImg,
        route: "/AdminDashboard/allEmployees",
      },
      {
        key: "3",
        title: `Beneficiaries`,
        content: "View the Beneficiaries accounts.",
        img: BenImg,
        route: "",
      },
      {
        key: "16",
        title: `Pensioner Verification`,
        content: "Verify and process the Pensioner accounts.",
        img: logomhs,
        route: "/pensionerVerification/selectLocation",
      },
      {
        key: "4",
        title: `Merchant`,
        content: "View the merchant accounts for transaction and deposits.",
        img: merchImg,
        route: "/adminDashboard/Merchant/Listing",
      },
      {
        key: "5",
        title: `SAP Money`,
        content: "Use your QR Code for purchases. The history will be saved.",
        img: WalletImg,
        route: "/SapMoney",
      },
      {
        key: "6",
        title: `Social Benefits`,
        content:
          "View the history to keep track of all your previous pensions details.",
        img: SocialImg,
        route: "",
      },
      {
        key: "7",
        title: `MHSSS Accounting`,
        content: "View all payments and outstanding for Merchants.",
        img: mhsssLogo,
        route: "/MHSSS/dashboard",
      },
      {
        key: "8",
        title: `Stocks`,
        content: "Track your inventory of products, locations, costs.",
        img: StockImg,
        route: "",
      },

      {
        key: "9",
        title: `Maintenance`,
        content: "This manages the detail and history of your fleet.",
        img: MaintenanceImg,
        route: "",
      },
      {
        key: "10",
        title: `Reports`,
        content:
          "View live stats of registrations, status ,financials and  merchants.",
        img: reportIcon,
        route: "rep",
      },
      {
        key: "11",
        title: `Events`,
        content: "The announcements will be for public or private.",
        img: EventsImg,
        route: "/EmployeeDashBoard/events",
      },
      {
        key: "12",
        title: `Attendance`,
        content:
          "Time and Attendance, Gain Actionable, Real-Time data on employee presence and activity.",
        img: AttendanceImg,
        route: "",
      },
      {
        key: "13",
        title: `Payroll`,
        content:
          "Payroll records contain valuable data about wages, for managing payroll processes effectively.",
        img: payrol,
        route: "",
      },
    ];
  } else if (dash === "/EDashboard") {
    items = [
      {
        key: "1",
        title: `Pensioner`,
        content: "View the pensioner accounts and make decisions.",
        img: pensionerImg,
        route: "/EDashboard/Pensioner_HistoryP",
      },
      {
        key: "2",
        title: `Beneficiaries`,
        content: "View the Beneficiaries accounts.",
        img: BenImg,
        route: "",
      },
      {
        key: "3",
        title: `Merchant`,
        content: "View the merchant accounts for transaction and deposits.",
        img: merchImg,
        route: "/EDashboard/Merchant/Listing",
      },
      {
        key: "13",
        title: `Pensioner Verification`,
        content: "Verify and process the Pensioner accounts.",
        img: logomhs,
        route: "/pensionerVerification/selectLocation",
      },
      {
        key: "4",
        title: `SAP Money`,
        content: "Use your QR Code for purchases. The history will be saved.",
        img: WalletImg,
        route: "/SapMoney",
      },
      {
        key: "5",
        title: `MHSSS Accounting`,
        content: "View all payments and outstanding for Merchants.",
        img: mhsssLogo,
        route: "/MHSSS/dashboard",
      },
      {
        key: "6",
        title: `Social Benefits`,
        content:
          "View the history to keep track of all your previous pensions details.",
        img: SocialImg,
        route: "",
      },
      {
        key: "7",
        title: `Stocks`,
        content: "Track your inventory of products, locations, costs.",
        img: StockImg,
        route: "",
      },
      {
        key: "8",
        title: `Maintenance`,
        content: "This manages the detail and history of your fleet.",
        img: MaintenanceImg,
        route: "",
      },
      {
        key: "9",
        title: `Reports`,
        content:
          "View live stats of registrations, status ,financials and  merchants.",
        img: reportIcon,
        route: "rep",
      },
      {
        key: "10",
        title: `Events`,
        content: "The announcements will be for public or private.",
        img: EventsImg,
        route: "/EmployeeDashBoard/events",
      },
      {
        key: "11",
        title: `Attendance`,
        content:
          "Time and Attendance, Gain Actionable, Real-Time data on employee presence and activity.",
        img: AttendanceImg,
        route: "",
      },
      {
        key: "12",
        title: `Payroll`,
        content:
          "Payroll records contain valuable data about wages, for managing payroll processes effectively.",
        img: payrol,
        route: "",
      },
    ];
  } else if (dash === "/Dashboard") {
    items = [
      {
        key: "1",
        title: `Beneficiary`,
        content: "View edit or add a beneficiary. To manage your benefits.",
        img: BenImg,
        route: "benPen",
      },
      {
        key: "2",
        title: `Applications`,
        content: "View the Beneficiaries accounts.",
        img: appDash,
        route: "/Dashboard/allApplications",
      },
      {
        key: "3",
        title: `Transactions`,
        content: "View the history of your benefits and transactions.",
        img: tranDash,
        route: "/Dashboard/transaction_history",
      },
      {
        key: "4",
        title: `Merchant`,
        content:
          "View merchants near you for receiving your benefits, pay bills and purchase grocery.",
        img: merchImg,
        route: "/Merchant/NearMe",
      },
      {
        key: "5",
        title: `SAP Money`,
        content: "Use your QR Code for purchases. The history will be saved.",
        img: WalletImg,
        route: "/SapMoney",
      },
      {
        key: "6",
        title: `Events`,
        content: "The announcements will be for public or private.",
        img: EventsImg,
        route: "/PensionerDashBoard/events",
      },
    ];
  }
  return (
    <div
      className=""
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${Ellipse})`,
        backgroundSize: "cover",
        backgroundPosition: "center 10%",
        backgroundPositionY: "-80px",
      }}
    >
      {contextHolder}
      <div className="flex items-center justify-center">
        <div className="md:w-[85%] mt-[3rem] rounded-md w-[95%] p-[4rem] mb-16  sm:min-h-[960px] bg-[#F8F5F5] shadow-lg">
          <div className="w-full mt-5 flex flex-wrap justify-center items-center gap-8 md:gap-x-8 gap-y-14 sm:min-h-[400px]">
            {items?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  if (item?.title === "SAP Money") {
                    // ${
                    //   dataUser?.data?.user?.status === "pending" ||
                    //   dataUser?.data?.user?.status === "deferred" ||
                    //   dataUser?.data?.user?.status === "re-submitted" ||
                    //   dataUser?.data?.user?.status === "in-review" ||
                    //   dataUser?.data?.user?.status === "verified"
                    //     ? "pointer-events-none"
                    //     : ""
                    // }
                    if (
                      dataUser?.data?.user?.status === "approved" &&
                      (user === "pensioner" ||
                        user === "employee" ||
                        user === "admin" ||
                        user === "superadmin")
                    ) {
                      dispatch({ type: "SAP_MONEY_ENABLE", num: true });
                    } else {
                      return;
                    }
                  }
                  if (item?.title === "Pensioner Verification") {
                    dispatch({ type: "VERIFICATION_START", num: true });
                  }
                  if (item?.title === "MHSSS Accounting") {
                    dispatch({ type: "SAP_MONEY_ENABLE", num: false });
                  }
                  if (item?.title === "Payment Setting") {
                    setProgramsSetting(true);
                    return;
                  }

                  if (item.route === "") {
                    Success();
                  } else if (item.route === "benPen") {
                    if (dataUser?.data?.user?.status === "rejected") {
                      dispatch({ type: "SIGN_IN" });
                    } else {
                      setBeneficiary(true);
                      setShow1(true);
                    }
                  } else if (item.route === "/eWallet") {
                    dispatch({ type: "SIDEBARE", num: 1 });
                    router(item?.route);
                  } else if (item.route === "inventory") {
                    setShowRole(true);
                  } else if (item.route === "rep") {
                    setShowReport(true);
                  } else router(item?.route);
                }}
                className="relative w-[220px] rounded-md mb-[30px] h-[150px] p-2 cursor-pointer custom-shadow"
                style={{
                  background: "linear-gradient(white, #F8F5F5)",
                  height: `150px`,
                }}
              >
                <div className="absolute top-[-40px] left-1/2 flex p-5 border-[3px] border-[#F8F5F5] p-[1px] items-center justify-center transform -translate-x-1/2 bg-[#8BD6B5] rounded-full h-[80px] w-[80px]">
                  <img src={item?.img} alt="" className="h-12 w-auto p-1" />
                </div>
                <div className="flex items-center justify-center flex-col mt-6 w-full h-full">
                  <div className="flex items-center mt-1 h-full gap-y-3 justify-center w-full flex-col">
                    <div className="text-center h-[30px] ">
                      <h1
                        className="text-black text-[16px] font-bold"
                        style={{ fontFamily: "poppins", fontWeight: "600" }}
                      >
                        {item?.title}
                      </h1>
                    </div>
                    <div className="text-center min-h-[60px]">
                      <p
                        className="text-black text-[13px]"
                        style={{ fontFamily: "poppins", fontWeight: "500" }}
                      >
                        {showAllContentIndex === index ||
                        item?.content.length <= 45
                          ? item?.content
                          : `${item?.content.substring(0, 45)}...`}
                        {item?.content.length > 45 && (
                          <span
                            className="text-[#F35929] z-10"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleToggleContent(index);
                            }}
                          >
                            {showAllContentIndex === index
                              ? " Read Less"
                              : " Read More"}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`${
          dataUser?.data?.user?.status === "approved" ? "hidden" : ""
        }`}
      >
        <div className={`${show ? "hidden" : ""}`}>
          <Popup1 />
        </div>
      </div>
      <div
        className={`fixed ${
          show1 ? "" : "hidden"
        } top-0 left-0 w-full h-full bg-slate-500/50 flex justify-center items-center z-[100]`}
      >
        <div className="relative rounded-xl bg-white flex items-center p-3 flex-col">
          <div
            className="absolute top-3 right-4 cursor-pointer"
            onClick={() => setShow1(false)}
          >
            <img className="scale-[0.8]" src={cross} alt="Close" />
          </div>
          <p className="text-center text-[1.1rem] mt-[1rem] font-semibold mb-5">
            Please select a program to move forward
          </p>

          <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-4">
            {programs?.map((program) => (
              <div
                key={program.value}
                onClick={() => {
                  dispatch({ type: "penId", num: program.value });
                  router("/Dashboard/listing");
                }}
                className={`flex items-center cursor-pointer bg-[#E8E8E8]  rounded-md shadow-lg p-3 pl-5 pr-5 w-full mb-2`}
              >
                <div>
                  <img className="w-[3rem]" src={logo1} alt="" />
                </div>
                <p className="ml-4 font-semibold text-[1rem]">
                  {program.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`${
          showRej ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <div className="text-center mt-[1rem] mb-5">
            <span>
              <strong>Request {dataUser?.data?.user?.status}</strong>
            </span>
            <p>
              <strong>Reason: </strong>
              {err}
            </p>
            <p>Please address the issue and resubmit your request.</p>
          </div>

          <button
            type="submit"
            onClick={() => setShowRej(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
      <div
        className={`${
          showDef ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5">
            <strong>Request {dataUser?.data?.user?.status}</strong>
            <span>
              <strong>Reason: </strong>
              {err}
            </span>
          </p>
          <button
            type="submit"
            onClick={() => setShowDef(false)}
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
          >
            Verify
          </button>
        </div>
      </div>
      <div
        className={`${
          showRole ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative pb-8 rounded-xl w-[30rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setShowRole(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div>
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5">
            <strong>Please Select a Role.</strong>
          </p>
          <div className="flex gap-3">
            <button
              type="button"
              onClick={() => {
                dispatch({ type: "Role", num: "vendor" });
                router("/Inventory-Vendor-DashBoard");
                // router("/SuperAdmin/InventoryDashboard");
                setShowRole(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Vendor
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch({ type: "Role", num: "buyer" });
                router("/Inventory");
                setShowRole(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Buyer
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showReport ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative pb-8 rounded-xl w-[30rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setShowReport(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-red-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div>
            <img src={logo1} alt="" className="w-20 h-20" />
          </div>
          <p className="text-center mt-[1rem] mb-5">
            <strong>Select Report.</strong>
          </p>
          <div className="flex gap-3">
            <button
              type="button"
              onClick={() => {
                router("/PowerBiReports");
                setShowReport(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              PowerBi
            </button>
            <button
              type="button"
              onClick={() => {
                router("/tableauReports");
                setShowReport(false);
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[60%]"
            >
              Tableau
            </button>
          </div>
        </div>
      </div>

      <div
        className={`${
          programsSetting ? "" : "hidden"
        } fixed inset-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative sm:w-[28rem] w-[24rem] p-6 rounded-xl bg-white shadow-lg">
          <button
            className="absolute top-4 right-4 text-red-500 hover:text-red-700 transition duration-200"
            onClick={() => setProgramsSetting(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="flex justify-center mb-6">
            <img src={logo1} alt="Logo" className="h-14 w-auto" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              onClick={() => {
                router("/superAdminDashboard/Programs");
                setShowReport(false);
              }}
              className="cursor-pointer rounded-md py-2 px-2 text-white bg-primary hover:bg-primary-dark transition duration-200 w-full text-center"
            >
              Program Payment Setting
            </button>
            <button
              type="button"
              onClick={() => {
                router("/superadmin/setTransactionLimits");
                setShowReport(false);
              }}
              className="cursor-pointer rounded-md py-2 px-2 text-white bg-primary hover:bg-primary-dark transition duration-200 w-full text-center"
            >
              SAP Money Payment Setting
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          verification ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[28rem] pb-6 pt-4 flex items-center p-3 bg-white flex-col">
          <div
          // className="absolute top-[-11%] left-[42%]"
          // onClick={() => setShow1(false)}
          >
            <img src={logo1} alt="" className="w-16 h-16" />
          </div>
          <p className="text-center mt-[1rem] text-red-600 font-bold mb-2">
            MHSSS Verification Alert
          </p>
          <p className="text-center mt-[1rem]">
            A verification process is scheduled at your distribution centre on
            <b> 19th November 2024</b> from <b>9:00 AM to 5:00 PM</b>
          </p>
          <p className="text-center mt-[1rem] mb-5">
            You can visit the centre on the scheduled date or book a slot for
            quicker, priority service. We recommend booking in advance to avoid
            waiting.
          </p>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              type="button"
              onClick={() => setVerification(false)}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-red-500 w-[40%]"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                if (PesnionerLinkedCenter !== null) {
                  router("/pensionerVerification/BookSlot");
                  return;
                }
                dispatch({ type: "PENSIONER_BOOK_SLOT", num: true });
                router("/pensionerVerification/BookSlot/SelectLocation");
              }}
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-6 pr-6 text-white bg-primary w-[40%]"
            >
              Book a slot
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
