import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input";
import bcrypt from "bcryptjs";
import { parsePhoneNumber } from "react-phone-number-input";
import eye from "../../imgs/eyee.png";
import across from "../../imgs/across.png";
let checkPhone;
let checkEmail;
let checkPhoneEmail;
let personal = "";
let address = "";
let info = "";
let identification = "";
let company = "";
let admin = "";
let headquarter = "";
let store = "";
let merchantDoc = "";
let companyDoc = "";
let bank = "";
let consent = "";

const Ww = () => {
  const option = useSelector((state) => state.option);
  const token = useSelector((state) => state.token);
  const remPensioner = useSelector((state) => state.remPensioner);
  const remEmployee = useSelector((state) => state.remEmployee);
  const remMerchat = useSelector((state) => state.remMerchat);
  const router = useNavigate();
  const [prompt, setPrompt] = useState("");
  const [email, setEmail] = useState("");
  const [gid, setGid] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState("");
  const [showPass, setShowPass] = useState("password");
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const [eOption, setEOption] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [pOption, setPOption] = useState(false);
  const [gOption, setGOption] = useState(true);
  const handleChange = (setState, check) => (event) => {
    if (check === "tt") {
      setPrompt(event);
    } else {
      setState(event.target.value);
    }
  };
  // const hashPassword = async (password) => {
  //   const salt = await bcrypt.genSalt(10);
  //   const hashedPassword = await bcrypt.hash(password, salt);
  //   return hashedPassword;
  // };
  const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10);
    return await bcrypt.hash(password, salt);
  };
  const comparePassword = async (plainPassword, hashedPassword) => {
    return await bcrypt.compare(plainPassword, hashedPassword);
  };
  const handleRememberMe = async () => {
    let credentials = {};
    if (option === "Pensioner") {
      credentials = {
        gid: gid || "",
        email: email || "",
        prompt: prompt || "",
        password: await hashPassword(password),
      };
      dispatch({ type: "REM-PEN", num: credentials });
    } else if (option === "Employee") {
      credentials = {
        email: email || "",
        prompt: prompt || "",
        password: await hashPassword(password),
      };
      dispatch({ type: "REM-EMP", num: credentials });
    } else if (option === "Merchant") {
      credentials = {
        email: email || "",
        prompt: prompt || "",
        password: await hashPassword(password),
      };
      dispatch({ type: "REM-MER", num: credentials });
    }
  };

  useEffect(() => {
    const retrieveCredentials = async () => {
      if (option === "Pensioner") {
        if (remPensioner?.gid) {
          setGid(remPensioner.gid);
          logHandler("gid");
        }
        if (remPensioner?.email) {
          setEmail(remPensioner.email);
          logHandler("email");
        }
        if (remPensioner?.prompt) {
          setPrompt(remPensioner.prompt);
          logHandler("phone");
        }
        if (remPensioner?.password) {
          const isPasswordMatch = await comparePassword(
            password,
            remPensioner.password
          );
          if (isPasswordMatch) {
            setPassword(password);
          }
        }
      }
      if (option === "Employee") {
        if (remEmployee?.email) {
          setEmail(remEmployee.email);
          logHandler("email");
        }
        if (remEmployee?.prompt) {
          setPrompt(remEmployee.prompt);
          logHandler("phone");
        }
        if (remEmployee?.password) {
          const isPasswordMatch = await comparePassword(
            password,
            remEmployee.password
          );
          if (isPasswordMatch) {
            setPassword(password);
          }
        }
      }
      if (option === "Merchant") {
        if (remMerchat?.email) {
          setEmail(remMerchat.email);
          logHandler("email");
        }
        if (remMerchat?.prompt) {
          setPrompt(remMerchat.prompt);
          logHandler("phone");
        }
        if (remMerchat?.password) {
          const isPasswordMatch = await comparePassword(
            password,
            remMerchat.password
          );
          if (isPasswordMatch) {
            setPassword(password);
          }
        }
      }
    };

    retrieveCredentials();
  }, [remPensioner, remEmployee, remMerchat, option]);
  const submitHandler = () => {
    if (option === "Vendor") {
      router("/VendorDashboard");
      dispatch({ type: "USER", num: "vendor" });
      return;
    }
    if (rememberMe) {
      handleRememberMe();
    }
    dispatch({ type: "GovFalse" });
    dispatch({ type: "SEARCH_MODAL_COUNT", num: 1 });
    if (option === "SAP Money") {
      if (email) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            email: email,
          })
          .then((data) => {
            if (data?.data?.user?.roles[0] === "pensioner") {
              dispatch({ type: "USER", num: data?.data?.user?.roles[0] });
              dispatch({
                type: "tokenSet",
                num: data.data.tokens.access.token,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              router("/Sap/Money");
            } else {
              setErr("Incorrect Email or Password");
              setShow(true);
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      } else if (gid) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            gid: gid,
          })
          .then((data) => {
            dispatch({ type: "USER", num: data?.data?.user?.roles[0] });
            if (data?.data?.user?.roles[0] === "pensioner") {
              dispatch({
                type: "tokenSet",
                num: data.data.tokens.access.token,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              router("/Sap/Money");
            } else {
              setErr("Incorrect GID or Password");
              setShow(true);
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      } else if (prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;

        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            countryCode: `+${countryCode}`,
            phoneNo: number,
          })
          .then((data) => {
            dispatch({ type: "USER", num: data?.data?.user?.roles[0] });

            if (data?.data?.user?.roles[0] === "pensioner") {
              dispatch({
                type: "tokenSet",
                num: data.data.tokens.access.token,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              router("/Sap/Money");
            } else {
              setErr("Incorrect Phone or Password");
              setShow(true);
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      }
    } else if (option === "Merchant") {
      dispatch({ type: "GovFalse" });
      dispatch({
        type: "SELECTED_STORE",
        num: null,
      });
      // e.preventDefault();
      // setIsLoading(true);
      if (email) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-auth/login`, {
            password: password,
            email: email,
          })
          .then((data) => {
            // privileges Call
            if (data?.data?.merchuser?.employeeType === "headquarter") {
              dispatch({ type: "HQ" });
            }
            if (data?.data?.merchuser?.employeeType === "store") {
              dispatch({ type: "STORE" });
            }
            if (data?.data?.merchuser?.isGovtAgency === true) {
              dispatch({ type: "GovTrue" });
            }
            if (data?.data?.merchuser?.role === "merchant-owner") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/all-merch-privileges`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  dispatch({
                    type: "DEFINED_PRIVILEGES_MERCHANT",
                    num: data?.data,
                  });
                })

                .catch(() => {});
            }
            //Other Setting

            dispatch({ type: "USER", num: data?.data?.merchuser?.role });
            dispatch({
              type: "tokenSet",
              num: data.data.merchTokens.access.token,
            });
            dispatch({ type: "setUserData", num: data });
            dispatch({ type: "popDataSet", num: data?.data?.merchuser });
            dispatch({ type: "authStateSet", num: "true" });
            if (data?.data?.merchuser?.status === "verified") {
              dispatch({ type: "MERCHANT_STATUS", num: "verified" });
            } else if (data?.data?.merchuser?.status === "in-review") {
              dispatch({ type: "MERCHANT_STATUS", num: "in-review" });
              // dispatch({ type: "inreview" });
            } else if (data?.data?.merchuser?.status === "deferred") {
              dispatch({ type: "MERCHANT_STATUS", num: "deferred" });
              dispatch({ type: "deferred" });
            } else if (data?.data?.merchuser?.status === "rejected") {
              dispatch({ type: "MERCHANT_STATUS", num: "rejected" });
              dispatch({ type: "rejected" });
            } else if (data?.data?.merchuser?.status === "pending") {
              dispatch({ type: "MERCHANT_STATUS", num: "pending" });
              dispatch({ type: "SIGN_IN" });
              dispatch({ type: "pending" });
            } else if (data?.data?.merchuser?.status === "re-submitted") {
              dispatch({ type: "MERCHANT_STATUS", num: "re-submitted" });
              dispatch({ type: "resubmitted" });
            } else if (data?.data?.merchuser?.status === "approved") {
              dispatch({ type: "MERCHANT_STATUS", num: "approved" });
              dispatch({ type: "approved" });
            }

            if (
              data?.data?.merchuser?.role === "merchant-employee" &&
              data?.data?.merchuser?.needResetPass === true
            ) {
              dispatch({ type: "BRSET", num: 4 });
              // router("/Merchant/dashboard");
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-admin" &&
              data?.data?.merchuser?.needResetPass === true
            ) {
              dispatch({ type: "BRSET", num: 4 });
              // router("/Merchant/dashboard");
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-admin" &&
              data?.data?.merchuser?.needResetPass === false
            ) {
              if (data?.data?.merchuser?.isGovtAgency === true) {
                dispatch({ type: "GovTrue" });
              }
              router("/Merchant/dashboard");
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-employee" &&
              data?.data?.merchuser?.needResetPass === false
            ) {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-user/merch-employee/my-profile`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  dispatch({ type: "MERCHANT_EMPLOYEE_INFO", num: data });
                })
                .catch(() => {});

              //Linked Stores
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/my`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const store = data?.data?.map((item) => item.merchstore);
                  dispatch({ type: "MERCHANT_STORES", num: store });
                })
                .catch(() => {});

              //Head Quarter Privileges
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/my-headquarter-privileges`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const privileges = data?.data?.map(
                    (privilege) => privilege.privilege
                  );
                  dispatch({
                    type: "HEADQUARTER_PRIVILEGES_MERCHANT_EMPLOYEE",
                    num: privileges,
                  });
                })

                .catch(() => {});

              router("/Merchant/dashboard");
              return;
            }

            axios
              .get(`${process.env.REACT_APP_BASE_URL}/v1/merch-store`, {
                headers: {
                  Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                },
              })
              .then((data) => {
                dispatch({ type: "MERCHANT_STORES", num: data?.data?.rows });
              })
              .catch(() => {});

            dispatch({
              type: "tokenRef",
              num: data.data.merchTokens.refresh.token,
            });
            dispatch({ type: "setUserData", num: data });
            dispatch({ type: "popDataSet", num: data?.data?.merchuser });
            dispatch({ type: "authStateSet", num: "true" });
            dispatch({ type: "MERCHANT" });
            // if (data?.data?.merchuser?.phoneVerified === false) {
            //   axios
            //     .post(
            //       `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-phone-otp`,
            //       null,
            //       {
            //         headers: {
            //           Authorization: `Bearer ${token}`,
            //         },
            //       }
            //     )
            //     .then((data) => {
            //       axios
            //         .post(
            //           `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-email-otp`,
            //           null,
            //           {
            //             headers: {
            //               Authorization: `Bearer ${token}`,
            //             },
            //           }
            //         )
            //         .then((data) => {
            //           dispatch({ type: "RSET", num: 1 });
            //           dispatch({ type: "veri2" });
            //         })
            //         .catch((err) => {});
            //     })
            //     .catch((err) => {});
            // }

            if (data?.data?.merchuser?.isGovtAgency === true) {
              dispatch({ type: "GovTrue" });
            }
            if (data?.data?.merchuser?.signupSteps?.length === 0) {
              dispatch({ type: "RSET", num: 1 });
              dispatch({ type: "ISET", num: 2 });
            } else {
              company = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "company-info";
              });
              personal = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "personal-info";
              });
              headquarter = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "headquarter-info";
                }
              );

              store = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "store-info";
              });
              admin = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "contact-admin";
              });

              companyDoc = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "company-doc";
                }
              );
              merchantDoc = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "merchant-doc";
                }
              );
              bank = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "bank-info";
              });
              consent = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "consent";
              });
              if (company.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else if (personal.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 3 });
              } else if (headquarter.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 4 });
              } else if (
                store.length === 0 &&
                data?.data?.merchuser?.merchCompany?.isGovtAgency === false &&
                companyDoc.length === 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 5 });
              } else if (admin.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 6 });
              } else if (companyDoc.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 7 });
              } else if (merchantDoc.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 8 });
              } else if (bank.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 9 });
              } else if (consent.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 10 });
              } else {
                router("/Merchant/dashboard");
              }
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      } else if (prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;

        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/merch-auth/login`, {
            password: password,
            countryCode: `+${countryCode}`,
            phoneNo: number,
          })
          .then((data) => {
            if (data?.data?.merchuser?.employeeType === "headquarter") {
              dispatch({ type: "HQ" });
            }
            if (data?.data?.merchuser?.employeeType === "store") {
              dispatch({ type: "STORE" });
            }
            if (data?.data?.merchuser?.isGovtAgency === true) {
              dispatch({ type: "GovTrue" });
            }
            axios
              .get(
                `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/all-merch-privileges`,
                {
                  headers: {
                    Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                  },
                }
              )
              .then((data) => {
                dispatch({
                  type: "DEFINED_PRIVILEGES_MERCHANT",
                  num: data?.data,
                });
              })

              .catch(() => {});

            //Other Setting
            dispatch({ type: "USER", num: data?.data?.merchuser?.role });
            dispatch({
              type: "tokenSet",
              num: data.data.merchTokens.access.token,
            });
            dispatch({ type: "setUserData", num: data });
            dispatch({ type: "popDataSet", num: data?.data?.merchuser });
            dispatch({ type: "authStateSet", num: "true" });

            if (
              data?.data?.merchuser?.role === "merchant-employee" &&
              data?.data?.merchuser?.needResetPass === true
            ) {
              dispatch({ type: "BRSET", num: 4 });
              // router("/Merchant/dashboard");
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-admin" &&
              data?.data?.merchuser?.needResetPass === true
            ) {
              dispatch({ type: "BRSET", num: 4 });
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-admin" &&
              data?.data?.merchuser?.needResetPass === false
            ) {
              if (data?.data?.merchuser?.isGovtAgency === true) {
                dispatch({ type: "GovTrue" });
              }
              router("/Merchant/dashboard");
              return;
            }
            if (
              data?.data?.merchuser?.role === "merchant-employee" &&
              data?.data?.merchuser?.needResetPass === false
            ) {
              axios
                //Profile
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-user/merch-employee/my-profile`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  dispatch({ type: "MERCHANT_EMPLOYEE_INFO", num: data });
                })
                .catch(() => {});

              //Linked Stores
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-store/my`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const store = data?.data?.map((item) => item.merchstore);
                  dispatch({ type: "MERCHANT_STORES", num: store });
                })
                .catch(() => {});

              //Head Quarter Privileges
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/merch-employee-privileges/my-headquarter-privileges`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.merchTokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const privileges = data?.data?.map(
                    (privilege) => privilege.privilege
                  );
                  dispatch({
                    type: "HEADQUARTER_PRIVILEGES_MERCHANT_EMPLOYEE",
                    num: privileges,
                  });
                })

                .catch(() => {});
              router("/Merchant/dashboard");
              return;
            }
            dispatch({ type: "USER", num: data?.data?.merchuser?.role });
            dispatch({
              type: "tokenSet",
              num: data.data.merchTokens.access.token,
            });
            dispatch({
              type: "tokenRef",
              num: data.data.merchTokens.refresh.token,
            });
            dispatch({ type: "setUserData", num: data });
            dispatch({ type: "authStateSet", num: "true" });
            dispatch({ type: "MERCHANT" });
            // if (data?.data?.merchuser?.phoneVerified === false) {
            //   axios
            //     .post(
            //       `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-phone-otp`,
            //       null,
            //       {
            //         headers: {
            //           Authorization: `Bearer ${token}`,
            //         },
            //       }
            //     )
            //     .then((data) => {
            //       axios
            //         .post(
            //           `${process.env.REACT_APP_BASE_URL}/v1/merch-auth/resend-email-otp`,
            //           null,
            //           {
            //             headers: {
            //               Authorization: `Bearer ${token}`,
            //             },
            //           }
            //         )
            //         .then((data) => {
            //           dispatch({ type: "RSET", num: 1 });
            //           dispatch({ type: "veri2" });
            //         })
            //         .catch((err) => {});
            //     })
            //     .catch((err) => {});
            // }

            if (data?.data?.merchuser?.isGovtAgency === true) {
              dispatch({ type: "GovTrue" });
            }
            if (data?.data?.merchuser?.signupSteps?.length === 0) {
              dispatch({ type: "RSET", num: 1 });
              dispatch({ type: "ISET", num: 2 });
            } else {
              company = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "company-info";
              });
              personal = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "personal-info";
              });
              headquarter = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "headquarter-info";
                }
              );

              store = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "store-info";
              });
              admin = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "contact-admin";
              });

              companyDoc = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "company-doc";
                }
              );
              merchantDoc = data?.data?.merchuser?.signupSteps?.filter(
                (item) => {
                  return item.stepName === "merchant-doc";
                }
              );
              bank = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "bank-info";
              });
              consent = data?.data?.merchuser?.signupSteps?.filter((item) => {
                return item.stepName === "consent";
              });
              if (company.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else if (personal.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 3 });
              } else if (headquarter.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 4 });
              } else if (
                store.length === 0 &&
                data?.data?.merchuser?.merchCompany?.isGovtAgency === false &&
                companyDoc.length === 0
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 5 });
              } else if (admin.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 6 });
              } else if (companyDoc.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 7 });
              } else if (merchantDoc.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 8 });
              } else if (bank.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 9 });
              } else if (consent.length === 0) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 10 });
              } else {
                router("/Merchant/dashboard");
              }
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      }
    } else {
      // e.preventDefault();
      // setIsLoading(true);
      dispatch({
        type: "PENSIONER_LINKED_CENTER",
        num: null,
      });
      dispatch({
        type: "PENSIONER_LINKED_CENTER_CHECK",
        num: false,
      });

      if (email) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            email: email,
          })
          .then((data) => {
            dispatch({ type: "USER", num: data?.data?.user?.roles[0] });
            dispatch({ type: "popDataSet", num: data?.data?.user });
            if (data?.data?.user?.phoneNo) {
              checkPhone = true;
              dispatch({ type: "checkSet", num: "" });
            } else checkPhone = false;
            if (data?.data?.user?.email) {
              checkEmail = true;
            } else checkEmail = false;
            if (data?.data?.user?.email && data?.data?.user?.phoneNo) {
              checkPhoneEmail = true;
              dispatch({ type: "checkSet", num: "both" });
            } else checkPhoneEmail = false;
            localStorage.setItem("pol", data?.data?.user?.polStatus);
            localStorage.setItem("role", data?.data?.user?.roles[0]);
            localStorage.setItem("status", data?.data?.user?.status);
            localStorage.setItem("app", data?.data?.user?.applications);
            localStorage.setItem(
              "penNum",
              data?.data?.user?.countryCode + data?.data?.user?.phoneNo
            );
            localStorage.setItem(
              "EPhone",
              data.data.user.countryCode + data.data.user.phoneNo
            );
            if (data.data.user.status === "verified") {
              dispatch({ type: "verified" });
            } else if (data.data.user.status === "in-review") {
              dispatch({ type: "inreview" });
            } else if (data.data.user.status === "deferred") {
              dispatch({ type: "deferred" });
            } else if (data.data.user.status === "rejected") {
              dispatch({ type: "rejected" });
            } else if (data.data.user.status === "pending") {
              dispatch({ type: "SIGN_IN" });
              dispatch({ type: "pending" });
            } else if (data.data.user.status === "re-submitted") {
              dispatch({ type: "resubmitted" });
            } else if (data.data.user.status === "approved") {
              dispatch({ type: "approved" });
            }

            //Pensioner Distribution Center
            if (data.data.user.roles[0] === "pensioner") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/distribution-info/mine`,
                  {
                    headers: {
                      Authorization: `Bearer ${data?.data?.tokens?.access?.token}`,
                    },
                  }
                )
                .then((res) => {
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER",
                    num: res?.data,
                  });
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER_CHECK",
                    num: true,
                  });
                })
                .catch((err) => {});
            }

            if (data.data.user.roles[0] === "employee") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/privileges/my-privileges`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.tokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const privileges = data?.data.map((item) => item.privilege);
                  localStorage.setItem("subrole", data?.data[0]?.role);
                  dispatch({ type: "myPrivileges", num: privileges });
                })

                .catch(() => {});
            }

            dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
            dispatch({ type: "tokenRef", num: data.data.tokens.refresh.token });
            localStorage.setItem("authen", "true");
            dispatch({ type: "authStateSet", num: "true" });
            if (data.data.user.roles[0] === "pensioner") {
              dispatch({ type: "PENSIONER_INFO", num: data?.data });
              dispatch({ type: "PENSIONER" });
            } else {
              dispatch({ type: "EMPLOYEE" });
            }
            if (data.data.user.status === "rejected") {
              dispatch({
                type: "nameSet",
                num: `${data?.data?.user?.person?.firstName} ${data?.data?.user?.person?.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data.data.user.person.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data.data.tokens.access.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              } else if (data.data.user.roles[0] === "admin") {
                router("/adminDashboard");
              } else if (data.data.user.roles[0] === "superadmin") {
                router("/superAdminDashboard");
              } else if (data.data.user.roles[0] === "gro-admin") {
                router("/GroAdminDash");
              } else if (data.data.user.roles[0] === "gro-employee") {
                router("/GRO");
              }
            } else if (data.data.user.status === "approved") {
              dispatch({
                type: "nameSet",
                num: `${data?.data?.user?.person?.firstName} ${data?.data?.user?.person?.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data?.data?.user?.person?.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data?.data?.tokens?.access?.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data?.data?.tokens?.refresh?.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              } else if (data.data.user.roles[0] === "admin") {
                router("/adminDashboard");
              } else if (data.data.user.roles[0] === "superadmin") {
                router("/superAdminDashboard");
              } else if (data.data.user.roles[0] === "gro-admin") {
                router("/GroAdminDash");
              } else if (data.data.user.roles[0] === "gro-employee") {
                router("/GRO");
              }
            } else {
              if (
                data?.data?.user?.phoneVerified === false &&
                (checkPhone || checkPhoneEmail)
              ) {
                axios
                  .post(
                    `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-phone-otp`,
                    null,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {
                    if (checkPhoneEmail) {
                      axios
                        .post(
                          `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-email-otp`,
                          null,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then(() => {
                          dispatch({ type: "RSET", num: 1 });
                          dispatch({ type: "veri2" });
                        })
                        .catch(() => {});
                    } else {
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "veri2" });
                    }
                  })
                  .catch(() => {});
              } else if (
                data.data.signupSteps.length === 0 &&
                data.data.user.status !== "verified" &&
                data.data.user.status !== "deferred" &&
                data.data.user.status !== "in-review" &&
                data.data.user.status !== "re-submitted"
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else {
                personal = data.data.signupSteps.filter((item) => {
                  return item.stepName === "personal";
                });
                address = data.data.signupSteps.filter((item) => {
                  return item.stepName === "address";
                });
                if (option === "Employee") {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "employee-info";
                  });
                } else {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "pensioner-info";
                  });
                }

                identification = data.data.signupSteps.filter((item) => {
                  return item.stepName === "identification";
                });
                if (
                  (personal.length !== 0 &&
                    address.length !== 0 &&
                    info.length !== 0 &&
                    identification.length !== 0) ||
                  data.data.user.status === "verified" ||
                  data.data.user.status === "deferred" ||
                  data.data.user.status === "in-review" ||
                  data.data.user.status === "re-submitted"
                ) {
                  if (
                    data.data.user.applications === 0 &&
                    data.data.user.status !== "verified" &&
                    data.data.user.status !== "deferred" &&
                    data.data.user.status !== "in-review" &&
                    data.data.user.status !== "re-submitted"
                  ) {
                    axios
                      .get(
                        `${process.env.REACT_APP_BASE_URL}/v1/users/my-profile`,
                        {
                          headers: {
                            Authorization: `Bearer ${data.data.tokens.access.token}`,
                          },
                        }
                      )
                      .then((data) => {
                        dispatch({ type: "setProfileData", num: data.data });
                        dispatch({
                          type: "pensionerId",
                          num: data.data.user.id,
                        });
                        dispatch({ type: "data3Set", num: data });
                      })
                      .catch((err) => {
                        if (
                          err?.response?.data?.message ===
                          "Incorrect email or password"
                        ) {
                          setErr("Incorrect Email or Password");
                        } else {
                          setErr(err?.response?.data?.message);
                        }

                        setShow(true);
                        // setIsLoading(false);
                      });
                    dispatch({ type: "setUserData", num: data });

                    if (data.data.user.roles[0] === "pensioner") {
                      dispatch({
                        type: "data1Set",
                        num: data.data.user.person,
                      });
                      dispatch({ type: "userDataSet", num: data.data.user });
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "ISET", num: 17 });
                    }
                    // dispatch({ type: "data2Set", num: data });
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                  } else {
                    dispatch({ type: "setUserData", num: data });
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                    if (data.data.user.roles[0] === "pensioner") {
                      if (data.data.user.status === "approved") {
                        questions(data.data.tokens.access.token);
                      } else {
                        router("/Dashboard");
                      }
                    } else if (data.data.user.roles[0] === "employee") {
                      router("/EDashboard");
                    } else if (data.data.user.roles[0] === "admin") {
                      router("/adminDashboard");
                    } else if (data.data.user.roles[0] === "superadmin") {
                      router("/superAdminDashboard");
                    } else if (data.data.user.roles[0] === "gro-admin") {
                      router("/GroAdminDash");
                    } else if (data.data.user.roles[0] === "gro-employee") {
                      router("/GRO");
                    }
                  }
                } else if (personal.length === 0) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 2 });
                } else if (
                  address.length === 0 &&
                  info.length === 0 &&
                  identification.length === 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 3 });
                } else if (
                  info.length === 0 &&
                  identification.length === 0 &&
                  address.length !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 4 });
                } else if (
                  identification.length === 0 &&
                  info.length !== 0 &&
                  address !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 5 });
                }
              }
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      } else if (gid) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            gid: gid,
          })
          .then((data) => {
            //Pensioner Distribution Center
            if (data.data.user.roles[0] === "pensioner") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/distribution-info/mine`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.tokens.access.token}`,
                    },
                  }
                )
                .then((res) => {
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER",
                    num: res?.data,
                  });
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER_CHECK",
                    num: true,
                  });
                })
                .catch((err) => {});
            }
            dispatch({ type: "USER", num: data?.data?.user?.roles[0] });
            dispatch({ type: "popDataSet", num: data.data.user });
            if (data?.data?.user?.phoneNo) {
              checkPhone = true;
              dispatch({ type: "checkSet", num: "" });
            } else checkPhone = false;
            if (data?.data?.user?.email) {
              checkEmail = true;
            } else checkEmail = false;
            if (data?.data?.user?.email && data?.data?.user?.phoneNo) {
              checkPhoneEmail = true;
              dispatch({ type: "checkSet", num: "both" });
            } else checkPhoneEmail = false;
            localStorage.setItem("role", data?.data?.user?.roles[0]);
            localStorage.setItem("status", data?.data?.user?.status);
            localStorage.setItem("app", data?.data?.user?.application);
            localStorage.setItem("pol", data?.data?.user?.polStatus);
            localStorage.setItem(
              "penNum",
              data?.data?.user?.countryCode + data?.data?.user?.phoneNo
            );

            dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
            dispatch({ type: "tokenRef", num: data.data.tokens.refresh.token });
            localStorage.setItem("authen", "true");
            dispatch({ type: "authStateSet", num: "true" });
            if (data.data.user.status === "verified") {
              dispatch({ type: "verified" });
            } else if (data.data.user.status === "in-review") {
              dispatch({ type: "inreview" });
            } else if (data.data.user.status === "deferred") {
              dispatch({ type: "deferred" });
            } else if (data.data.user.status === "rejected") {
              dispatch({ type: "rejected" });
            } else if (data.data.user.status === "pending") {
              dispatch({ type: "SIGN_IN" });
              dispatch({ type: "pending" });
            } else if (data.data.user.status === "re-submitted") {
              dispatch({ type: "resubmitted" });
            } else if (data.data.user.status === "approved") {
              dispatch({ type: "approved" });
            }
            if (data.data.user.roles[0] === "pensioner") {
              dispatch({ type: "PENSIONER" });
              dispatch({ type: "PENSIONER_INFO", num: data?.data });
            } else {
              dispatch({ type: "EMPLOYEE" });
            }

            if (data.data.user.status === "rejected") {
              dispatch({
                type: "nameSet",
                num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data.data.user.person.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data.data.tokens.access.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              }
            } else if (data.data.user.status === "approved") {
              dispatch({
                type: "nameSet",
                num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data.data.user.person.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data.data.tokens.access.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              }
            } else {
              if (
                data?.data?.user?.phoneVerified === false &&
                (checkPhone || checkPhoneEmail)
              ) {
                axios
                  .post(
                    `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-phone-otp`,
                    null,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {
                    if (checkPhoneEmail) {
                      axios
                        .post(
                          `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-email-otp`,
                          null,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then(() => {
                          dispatch({ type: "RSET", num: 1 });
                          dispatch({ type: "veri2" });
                        })
                        .catch(() => {});
                    } else {
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "veri2" });
                    }
                  })
                  .catch(() => {});
              } else if (
                data.data.signupSteps.length === 0 &&
                data.data.user.status !== "verified" &&
                data.data.user.status !== "deferred" &&
                data.data.user.status !== "in-review" &&
                data.data.user.status !== "re-submitted"
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else {
                personal = data.data.signupSteps.filter((item) => {
                  return item.stepName === "personal";
                });
                address = data.data.signupSteps.filter((item) => {
                  return item.stepName === "address";
                });
                if (option === "Employee") {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "employee-info";
                  });
                } else {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "pensioner-info";
                  });
                }
                identification = data.data.signupSteps.filter((item) => {
                  return item.stepName === "identification";
                });
                if (
                  (personal.length !== 0 &&
                    address.length !== 0 &&
                    info.length !== 0 &&
                    identification.length !== 0) ||
                  data.data.user.status === "verified" ||
                  data.data.user.status === "deferred" ||
                  data.data.user.status === "re-submitted" ||
                  data.data.user.status === "in-review" ||
                  data.data.user.status === "re-submitted"
                ) {
                  if (
                    data.data.user.applications === 0 &&
                    data.data.user.status !== "verified" &&
                    data.data.user.status !== "deferred" &&
                    data.data.user.status !== "in-review" &&
                    data.data.user.status !== "re-submitted"
                  ) {
                    axios
                      .get(
                        `${process.env.REACT_APP_BASE_URL}/v1/users/my-profile`,
                        {
                          headers: {
                            Authorization: `Bearer ${data.data.tokens.access.token}`,
                          },
                        }
                      )
                      .then((data) => {
                        dispatch({ type: "data3Set", num: data });
                        dispatch({ type: "setProfileData", num: data.data });
                        dispatch({
                          type: "pensionerId",
                          num: data.data.user.id,
                        });
                      })
                      .catch(() => {});
                    dispatch({ type: "setUserData", num: data });

                    if (data.data.user.roles[0] === "pensioner") {
                      dispatch({
                        type: "data1Set",
                        num: data.data.user.person,
                      });
                      dispatch({ type: "userDataSet", num: data.data.user });
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "ISET", num: 17 });
                    }
                    // dispatch({ type: "data2Set", num: data });
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                  } else {
                    dispatch({ type: "setUserData", num: data });
                    if (data.data.user.roles[0] === "pensioner") {
                      if (data.data.user.status === "approved") {
                        questions(data.data.tokens.access.token);
                      } else {
                        router("/Dashboard");
                      }
                    } else if (data.data.user.roles[0] === "employee") {
                      router("/EDashboard");
                    }
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                  }
                } else if (personal.length === 0) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 2 });
                } else if (
                  address.length === 0 &&
                  info.length === 0 &&
                  identification.length === 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 3 });
                } else if (
                  info.length === 0 &&
                  identification.length === 0 &&
                  address.length !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 4 });
                } else if (
                  identification.length === 0 &&
                  info.length !== 0 &&
                  address !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 5 });
                }
              }
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect GID or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      } else if (prompt) {
        const wow = parsePhoneNumber(prompt);
        let countryCode = wow.countryCallingCode;
        let number = wow.nationalNumber;

        axios
          .post(`${process.env.REACT_APP_BASE_URL}/v1/auth/login`, {
            password: password,
            countryCode: `+${countryCode}`,
            phoneNo: number,
          })
          .then((data) => {
            dispatch({ type: "USER", num: data?.data?.user?.roles[0] });
            dispatch({ type: "popDataSet", num: data.data.user });
            if (data?.data?.user?.phoneNo) {
              checkPhone = true;
              dispatch({ type: "checkSet", num: "" });
            } else checkPhone = false;
            if (data?.data?.user?.email) {
              checkEmail = true;
            } else checkEmail = false;
            if (data?.data?.user?.email && data?.data?.user?.phoneNo) {
              checkPhoneEmail = true;
              dispatch({ type: "checkSet", num: "both" });
            } else checkPhoneEmail = false;
            localStorage.setItem("role", data?.data?.user?.roles[0]);
            localStorage.setItem("status", data?.data?.user?.status);
            localStorage.setItem("app", data?.data?.user?.application);
            localStorage.setItem("pol", data?.data?.user?.polStatus);
            localStorage.setItem(
              "penNum",
              data?.data?.user?.countryCode + data?.data?.user?.phoneNo
            );
            localStorage.setItem(
              "EPhone",
              data.data.user.countryCode + data.data.user.phoneNo
            );
            dispatch({ type: "tokenSet", num: data.data.tokens.access.token });
            dispatch({ type: "tokenRef", num: data.data.tokens.refresh.token });
            localStorage.setItem("authen", "true");
            dispatch({ type: "authStateSet", num: "true" });
            dispatch({ type: "authStateSet", num: "true" });
            if (data.data.user.status === "verified") {
              dispatch({ type: "verified" });
            } else if (data.data.user.status === "in-review") {
              dispatch({ type: "inreview" });
            } else if (data.data.user.status === "deferred") {
              dispatch({ type: "deferred" });
            } else if (data.data.user.status === "rejected") {
              dispatch({ type: "rejected" });
            } else if (data.data.user.status === "pending") {
              dispatch({ type: "SIGN_IN" });
              dispatch({ type: "pending" });
            } else if (data.data.user.status === "re-submitted") {
              dispatch({ type: "resubmitted" });
            } else if (data.data.user.status === "approved") {
              dispatch({ type: "approved" });
            }
            //Pensioner Distribution Center
            if (data.data.user.roles[0] === "pensioner") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/distribution-info/mine`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.tokens.access.token}`,
                    },
                  }
                )
                .then((res) => {
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER",
                    num: res?.data,
                  });
                  dispatch({
                    type: "PENSIONER_LINKED_CENTER_CHECK",
                    num: true,
                  });
                })
                .catch((err) => {});
            }
            if (data.data.user.roles[0] === "employee") {
              axios
                .get(
                  `${process.env.REACT_APP_BASE_URL}/v1/privileges/my-privileges`,
                  {
                    headers: {
                      Authorization: `Bearer ${data.data.tokens.access.token}`,
                    },
                  }
                )
                .then((data) => {
                  const privileges = data?.data.map((item) => item.privilege);
                  localStorage.setItem("subrole", data?.data[0]?.role);
                  dispatch({ type: "myPrivileges", num: privileges });
                })

                .catch(() => {});
            }
            if (data.data.user.roles[0] === "pensioner") {
              dispatch({ type: "PENSIONER" });
              dispatch({ type: "PENSIONER_INFO", num: data?.data });
            } else {
              dispatch({ type: "EMPLOYEE" });
            }
            if (data.data.user.status === "rejected") {
              dispatch({
                type: "nameSet",
                num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data.data.user.person.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data.data.tokens.access.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              } else if (data.data.user.roles[0] === "admin") {
                router("/adminDashboard");
              } else if (data.data.user.roles[0] === "superadmin") {
                router("/superAdminDashboard");
              } else if (data.data.user.roles[0] === "gro-admin") {
                router("/GroAdminDash");
              } else if (data.data.user.roles[0] === "gro-employee") {
                router("/GRO");
              }
            } else if (data.data.user.status === "approved") {
              dispatch({
                type: "nameSet",
                num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
              });
              dispatch({
                type: "imageSet",
                num: `${data.data.user.person.image}`,
              });
              dispatch({
                type: "tokenSet",
                num: `${data.data.tokens.access.token}`,
              });
              dispatch({
                type: "tokenRef",
                num: data.data.tokens.refresh.token,
              });
              dispatch({ type: "setUserData", num: data });
              if (data.data.user.roles[0] === "pensioner") {
                if (data.data.user.status === "approved") {
                  questions(data.data.tokens.access.token);
                } else {
                  router("/Dashboard");
                }
              } else if (data.data.user.roles[0] === "employee") {
                router("/EDashboard");
              } else if (data.data.user.roles[0] === "admin") {
                router("/adminDashboard");
              } else if (data.data.user.roles[0] === "superadmin") {
                router("/superAdminDashboard");
              } else if (data.data.user.roles[0] === "gro-admin") {
                router("/GroAdminDash");
              } else if (data.data.user.roles[0] === "gro-employee") {
                router("/GRO");
              }
            } else {
              if (
                data?.data?.user?.phoneVerified === false &&
                (checkPhone || checkPhoneEmail)
              ) {
                axios
                  .post(
                    `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-phone-otp`,
                    null,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {
                    if (checkPhoneEmail) {
                      axios
                        .post(
                          `${process.env.REACT_APP_BASE_URL}/v1/auth/resend-email-otp`,
                          null,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          }
                        )
                        .then(() => {
                          dispatch({ type: "RSET", num: 1 });
                          dispatch({ type: "veri2" });
                        })
                        .catch(() => {});
                    } else {
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "veri2" });
                    }
                  })
                  .catch(() => {});
              } else if (
                data.data.signupSteps.length === 0 &&
                data.data.user.status !== "verified" &&
                data.data.user.status !== "deferred" &&
                data.data.user.status !== "in-review" &&
                data.data.user.status !== "re-submitted"
              ) {
                dispatch({ type: "RSET", num: 1 });
                dispatch({ type: "ISET", num: 2 });
              } else {
                personal = data.data.signupSteps.filter((item) => {
                  return item.stepName === "personal";
                });
                address = data.data.signupSteps.filter((item) => {
                  return item.stepName === "address";
                });
                if (option === "Employee") {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "employee-info";
                  });
                } else {
                  info = data.data.signupSteps.filter((item) => {
                    return item.stepName === "pensioner-info";
                  });
                }
                identification = data.data.signupSteps.filter((item) => {
                  return item.stepName === "identification";
                });

                if (
                  (personal.length !== 0 &&
                    address.length !== 0 &&
                    info.length !== 0 &&
                    identification.length !== 0) ||
                  data.data.user.status === "verified" ||
                  data.data.user.status === "deferred" ||
                  data.data.user.status === "in-review" ||
                  data.data.user.status === "re-submitted"
                ) {
                  if (
                    data.data.user.applications === 0 &&
                    data.data.user.status !== "verified" &&
                    data.data.user.status !== "deferred" &&
                    data.data.user.status !== "in-review" &&
                    data.data.user.status !== "re-submitted"
                  ) {
                    axios
                      .get(
                        `${process.env.REACT_APP_BASE_URL}/v1/users/my-profile`,
                        {
                          headers: {
                            Authorization: `Bearer ${data.data.tokens.access.token}`,
                          },
                        }
                      )
                      .then((data) => {
                        dispatch({ type: "data3Set", num: data });
                        dispatch({ type: "setProfileData", num: data.data });
                        dispatch({
                          type: "pensionerId",
                          num: data.data.user.id,
                        });
                      })
                      .catch(() => {});
                    dispatch({ type: "setUserData", num: data });

                    if (data.data.user.roles[0] === "pensioner") {
                      dispatch({
                        type: "data1Set",
                        num: data.data.user.person,
                      });
                      dispatch({ type: "userDataSet", num: data.data.user });
                      dispatch({ type: "RSET", num: 1 });
                      dispatch({ type: "ISET", num: 17 });
                    }
                    // dispatch({ type: "data2Set", num: data });
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                  } else {
                    dispatch({ type: "setUserData", num: data });
                    if (data.data.user.roles[0] === "pensioner") {
                      if (data.data.user.status === "approved") {
                        questions(data.data.tokens.access.token);
                      } else {
                        router("/Dashboard");
                      }
                    } else if (data.data.user.roles[0] === "employee") {
                      router("/EDashboard");
                    } else if (data.data.user.roles[0] === "admin") {
                      router("/adminDashboard");
                    } else if (data.data.user.roles[0] === "superadmin") {
                      router("/superAdminDashboard");
                    } else if (data.data.user.roles[0] === "gro-admin") {
                      router("/GroAdminDash");
                    } else if (data.data.user.roles[0] === "gro-employee") {
                      router("/GRO");
                    }
                    dispatch({
                      type: "nameSet",
                      num: `${data.data.user.person.firstName} ${data.data.user.person.lastName}`,
                    });
                    dispatch({
                      type: "imageSet",
                      num: `${data.data.user.person.image}`,
                    });
                    dispatch({
                      type: "tokenSet",
                      num: `${data.data.tokens.access.token}`,
                    });
                    dispatch({
                      type: "tokenRef",
                      num: data.data.tokens.refresh.token,
                    });
                  }
                } else if (personal.length === 0) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 2 });
                } else if (
                  address.length === 0 &&
                  info.length === 0 &&
                  identification.length === 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 3 });
                } else if (
                  info.length === 0 &&
                  identification.length === 0 &&
                  address.length !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 4 });
                } else if (
                  identification.length === 0 &&
                  info.length !== 0 &&
                  address !== 0
                ) {
                  dispatch({ type: "RSET", num: 1 });
                  dispatch({ type: "ISET", num: 5 });
                }
              }
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            if (
              err?.response?.data?.message === "incorrect email or password"
            ) {
              setErr("Incorrect Email or Password");
            } else if (err?.response?.data?.message) {
              setErr(err?.response?.data?.message);
            } else {
              setErr("Something went wrong please try again later!");
            }
            setShow(true);
          });
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const questions = (tokk) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/security-questions/count-my-answers`,
        {
          headers: {
            Authorization: `Bearer ${tokk}`,
          },
        }
      )
      .then((data) => {
        if (data?.data?.countMySecurityQuestionAnswers >= 3) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/v1/security-question-config`,
              {
                headers: {
                  Authorization: `Bearer ${tokk}`,
                },
              }
            )
            .then((data) => {
              if (data?.data?.canBeReset) router("/SecurityQuestions");
              else router("/Dashboard");
            })
            .catch(() => {});
        } else {
          router("/SecurityQuestions");
        }
      })
      .catch(() => {});
  };
  const passShowHandler = () => {
    if (showPass === "password") setShowPass("text");
    else setShowPass("password");
  };
  // const passShowHandler = () => {
  //   const wow = document.getElementById("Pass11");
  //   if (wow.type === "password") {
  //     wow.type = "text";
  //   } else {
  //     wow.type = "password";
  //   }
  // };

  useEffect(() => {
    if (option === "Employee") {
      setGOption(false);
      setEOption(true);
      setPOption(false);
    }
    if (option === "Merchant") {
      setGOption(false);
      setEOption(true);
      setPOption(false);
    }
    if (option === "SAP Money") {
      setGOption(false);
      setEOption(true);
      setPOption(false);
    }
    if (option === "Vendor") {
      setGOption(false);
      setEOption(true);
      setPOption(false);
    }
  }, []);
  const logHandler = (e) => {
    if (e === "email") {
      setEOption(true);
      setPOption(false);
      setGOption(false);
      setPrompt("");
      setGid("");
      // setWord("Login with Phone");
      // setWord1("Login with GID");
    } else if (e === "phone") {
      setPOption(true);
      setEOption(false);
      setGOption(false);
      setEmail("");
      setGid("");
      // setWord("Login with Email");
      // setWord1("Login with GID");
    } else if (e === "gid") {
      setEmail("");
      setPrompt("");
      setGOption(true);
      setPOption(false);
      setEOption(false);
      // setWord("Login with Phone");
      // setWord1("Login with Email");
    }
  };
  return (
    <div className="m-0">
      <div className="w-full">
        <div className="text-center sm:mt-[3rem] mt-[2rem] flex flex-col items-center">
          <div
            className={`${
              option === "Pensioner" || option === "SAP Money" ? "" : "hidden"
            } sm:w-[40%] w-[80%] sm:h-[2.5rem] h-[2rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-[5rem]`}
          >
            <div
              className={`flex ${
                gOption ? "bg-gradient-to-tr bg-primary" : ""
              }  flex w-[33.3333%] cursor-pointer rounded-[5rem] items-center justify-center`}
              onClick={() => logHandler("gid")}
            >
              <p
                className={`ml-2 sm:text-[1rem] text-[0.85rem] cursor-pointer ${
                  gOption ? "text-white" : "text-[#898989]"
                } `}
              >
                GID
              </p>
            </div>
            <div
              className={`${
                pOption ? "bg-gradient-to-tr bg-primary" : ""
              } flex w-[33.3333%] cursor-pointer rounded-[5rem] items-center justify-center`}
              onClick={() => logHandler("phone")}
            >
              <p
                className={`ml-2 sm:text-[1rem] text-[0.85rem] cursor-pointer ${
                  pOption ? "text-white" : "text-[#898989]"
                } `}
              >
                Phone
              </p>
            </div>
            <div
              className={`flex ${
                eOption ? "bg-gradient-to-tr bg-primary" : ""
              }  flex w-[33.3333%] cursor-pointer rounded-[5rem] items-center justify-center`}
              onClick={() => logHandler("email")}
            >
              <p
                className={`ml-2 sm:text-[1rem] text-[0.85rem] cursor-pointer ${
                  eOption ? "text-white" : "text-[#898989]"
                }  `}
              >
                Email
              </p>
            </div>
          </div>
          <div
            className={`${
              option === "Pensioner" || option === "SAP Money" ? "hidden" : ""
            } sm:w-[40%] w-[80%] sm:h-[2.5rem] h-[2rem] flex bg-[#F2F4F8] text-[0.95rem] rounded-[5rem]`}
          >
            <div
              className={`${
                pOption ? "bg-gradient-to-tr bg-primary" : ""
              } flex w-[50%] cursor-pointer rounded-[5rem] items-center justify-center`}
              onClick={() => logHandler("phone")}
            >
              <p
                className={`ml-2 sm:text-[1rem] text-[0.85rem] cursor-pointer ${
                  pOption ? "text-white" : "text-[#898989]"
                } `}
              >
                Phone
              </p>
            </div>
            <div
              className={`flex ${
                eOption ? "bg-gradient-to-tr bg-primary" : ""
              }  flex w-[50%] cursor-pointer rounded-[5rem] items-center justify-center`}
              onClick={() => logHandler("email")}
            >
              <p
                className={`ml-2 sm:text-[1rem] text-[0.85rem] cursor-pointer ${
                  eOption ? "text-white" : "text-[#898989]"
                }  `}
              >
                Email
              </p>
            </div>
          </div>
          <h2 className="font-medium text-[1.6rem] mt-4 text-[#2c5281] mb-1">
            Login
          </h2>
          <p className="sm:w-[60%] w-[80%] text-[#596F96] text-[0.9rem]">
            Use the approved credentials provided by the Ministry of Human
            Services and Social Security (MHSSS) to access information on Senior
            Citizen Pension applications, status, and services via your account.
          </p>
        </div>
        <div className=" flex items-center justify-center sm:mt-4 mt-3">
          <form onSubmit={submitHandler} className="mt-2 sm:w-[40%] w-[80%]">
            <div className={` flex flex-col ${eOption ? "" : "hidden"}`}>
              <label
                htmlFor="email"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Email *
              </label>
              <input
                className="lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                type="email"
                pattern=".+@example\.com"
                placeholder="Email"
                id="email"
                autoComplete={true}
                value={email}
                required={eOption ? true : false}
                onChange={handleChange(setEmail)}
              />
            </div>
            <div
              className={`${
                (gOption && option === "Pensioner") ||
                (gOption && option === "SAP Money")
                  ? ""
                  : "hidden"
              } flex flex-col`}
            >
              <label
                htmlFor="gid"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                GID *
              </label>
              <input
                className=" lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4"
                type="text"
                name="input2"
                id="gid"
                placeholder="GID"
                autoComplete={true}
                value={gid}
                required={gOption && option ? true : false}
                onChange={handleChange(setGid)}
              />
            </div>
            <div className={`flex flex-col ${pOption ? "" : "hidden"}`}>
              <label
                htmlFor="phone"
                className="text-[#596F96] text-[0.88rem] font-semibold"
              >
                Phone *
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                defaultCountry="GY"
                id="phone"
                autoComplete
                value={prompt}
                onChange={handleChange(setPrompt, "tt")}
                international
                countryCallingCodeEditable={false}
                required={option || pOption === "Employee" ? true : false}
                className=".PhoneInputCountryIcon mb-[5px]"
              />
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="pass"
                className="text-[#596F96] text-[0.88rem] font-semibold mb-2"
              >
                Password *
              </label>
              <div className=" flex justify-between lg:text-[1.1rem] w-[100%] bg-white border-[1px] border-[#C6CFE5] rounded-[0.3rem] p-2 mb-4">
                <input
                  type={showPass}
                  placeholder="Password"
                  id="pass"
                  onChange={handleChange(setPassword)}
                  value={password}
                  required
                  className="w-[100%]"
                  onKeyPress={handleKeyPress}
                ></input>
                <img
                  className="w-[1.6rem] cursor-pointer"
                  onClick={passShowHandler}
                  src={eye}
                  alt=""
                />
              </div>
            </div>
            {/* <div
              onClick={() => dispatch({ type: "BRSET", num: 3 })}
              className="flex justify-end hover:underline cursor-pointer"
            >
              <p className="text-[0.8rem] font-semibold text-primary">
                Forgot Password?
              </p>
            </div> */}
            <div className="flex items-center gap-4 justify-between cursor-pointer">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="mr-2 cursor-pointer"
                  onClick={(e) => setRememberMe(e.target.checked)}
                  checked={rememberMe}
                />
                <label
                  htmlFor="rememberMe"
                  className="text-[0.8rem] cursor-pointer hover:underline font-semibold text-primary text-primary"
                >
                  Remember me
                </label>
              </div>
              <div
                onClick={() => dispatch({ type: "BRSET", num: 3 })}
                className="flex hover:underline cursor-pointer"
              >
                <p className="text-[0.8rem] font-semibold text-primary">
                  Forgot Password?
                </p>
              </div>
            </div>
            <button
              type="button"
              onClick={submitHandler}
              className="btn relative justify-center flex w-[100%] cursor-pointer mt-10 bg-primary pt-2 pb-2 pl-[4rem] pr-[4rem] text-white rounded-xl"
            >
              {/* <Spin
                style={{ display: `${isLoading ? "" : "none"}` }}
                indicator={antIcon}
              /> */}
              <span className="ml-3">Login</span>
              {/* <div
                className={`w-[100%] h-[100%] ${
                  isLoading ? "" : "hidden"
                } absolute top-0 bg-slate-400 opacity-40 rounded-2xl`}
              ></div> */}
            </button>
            {/* <div className="flex items-center mt-4 sm:ml-14 ml-5 mb-4 w-[100%]">
              <div className="w-[36%] h-[1px] bg-[#adb4c5]"></div>
              <p className="ml-2 mr-2 text-[#adb4c5]">or</p>
              <div className="w-[36%] h-[1px] bg-[#adb4c5]"></div>
            </div>
            <button
              onClick={() => logHandler(word)}
              type="button"
              className="w-[100%] cursor-pointer border-[1px] font-medium text-primary border-primary pt-2 pb-2 pl-[4rem] pr-[4rem] rounded-xl login_btn"
            >
              {word}
            </button>
            <button
              onClick={() => logHandler(word1)}
              type="button"
              className={`${
                option === "Employee" || option === "Merchant" ? "hidden" : ""
              } w-[100%] mt-4 cursor-pointer border-[1px] font-medium text-primary border-primary pt-2 pb-2 pl-[4rem] pr-[4rem] rounded-xl login_btn`}
            >
              {word1}
            </button> */}
            <div className="text-center mt-2.5 flex flex-col items-center">
              <p>
                Don't have an account?{" "}
                <span
                  onClick={() => {
                    dispatch({ type: "RSET", num: 1 });
                  }}
                  className="text-primary cursor-pointer"
                >
                  Register Now
                </span>
              </p>
            </div>
          </form>
          <div
            className={`${
              show ? "" : "hidden"
            } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
          >
            <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
              <div
                className="absolute top-[-11%] left-[42%]"
                onClick={() => setShow(false)}
              >
                <img src={across} alt="" />
              </div>
              <p className="text-center mt-[3rem] mb-5">{err}</p>
              <button
                onClick={() => setShow(false)}
                className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ww;
