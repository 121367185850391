import { Pagination, Input, DatePicker, Checkbox } from "antd";
import { useState } from "react";
import back from "../../../../imgs/back5.png";
import id from "../../../../imgs/id.png";
import join from "../../../../imgs/join.png";
import dep from "../../../../imgs/dep.png";
import React from "react";
import Card from "./empCards";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import PiCard from "../components/PiCard";
import profile from "../../../../imgs/profile-img.png";

const columnHeaders = [
  "",
  "ID",
  "Date",
  "Start Work",
  "End Work",
  "Total Time Worked",
  "Exceptions",
  "Status",
];

const staticData = [
  {
    id: "1",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "John Doe",
    date: "2024-10-01",
    startWork: "08:00",
    endWork: "17:00",
    totalTimeWorked: "9h",
    exceptions: "Overtime",
    status: "Active",
    timeRecords: {
      timeIn: "08:00",
      timeOut: "17:00",
      breakStart: "12:00",
      breakEnd: "12:30",
      totalHoursWorked: "9h",
    },
  },
  {
    id: "2",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Jane Smith",
    date: "2024-10-01",
    startWork: "09:00",
    endWork: "18:00",
    totalTimeWorked: "8h",
    exceptions: "None",
    status: "Active",
    timeRecords: {
      timeIn: "09:00",
      timeOut: "18:00",
      breakStart: "13:00",
      breakEnd: "13:30",
      totalHoursWorked: "8h",
    },
  },
  {
    id: "3",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Michael Johnson",
    date: "2024-10-01",
    startWork: "07:30",
    endWork: "16:30",
    totalTimeWorked: "9h",
    exceptions: "Overtime",
    status: "Leave",
    timeRecords: {
      timeIn: "07:30",
      timeOut: "16:30",
      breakStart: "11:30",
      breakEnd: "12:00",
      totalHoursWorked: "9h",
    },
  },
  {
    id: "4",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Emily Davis",
    date: "2024-10-01",
    startWork: "08:00",
    endWork: "17:00",
    totalTimeWorked: "8h",
    exceptions: "None",
    status: "Active",
    timeRecords: {
      timeIn: "08:00",
      timeOut: "17:00",
      breakStart: "12:00",
      breakEnd: "12:30",
      totalHoursWorked: "8h",
    },
  },
  {
    id: "5",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Daniel Wilson",
    date: "2024-10-01",
    startWork: "08:30",
    endWork: "17:30",
    totalTimeWorked: "9h",
    exceptions: "Overtime",
    status: "Active",
    timeRecords: {
      timeIn: "08:30",
      timeOut: "17:30",
      breakStart: "12:30",
      breakEnd: "13:00",
      totalHoursWorked: "9h",
    },
  },
  {
    id: "6",
    profile: "https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg",
    name: "Sophia Martinez",
    date: "2024-10-01",
    startWork: "09:00",
    endWork: "18:00",
    totalTimeWorked: "8h",
    exceptions: "None",
    status: "Active",
    timeRecords: {
      timeIn: "09:00",
      timeOut: "18:00",
      breakStart: "13:00",
      breakEnd: "13:30",
      totalHoursWorked: "8h",
    },
  },
];

const StaticTable = () => {
  const router = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const filteredData = staticData.filter((data) =>
    data.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleCheckboxChange = (id) => {
    setSelectedRow(selectedRow === id ? null : id);
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    setCalendarOpen(false); // Close the calendar after selection
  };

  const toggleCalendar = () => {
    setCalendarOpen((prev) => !prev);
  };
  const cardData = [
    {
      id: "1",
      label: "Maria Smith",
      count: "Software Developer",
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrKxfjTf49GAtu0PpFXK7mKBgqyJ5MfJCgQw&s",
    },
    {
      id: "2",
      label: "Employee ID",
      count: "ID762436",
      icon: id,
    },
    {
      id: "3",
      label: "Joining Date",
      count: "12 January 2014",
      icon: join,
    },
    {
      id: "4",
      label: "Department",
      count: "Accounts",
      icon: dep,
    },
  ];
  return (
    <div className="h-[78vh] overflow-auto px-3">
      <div className="flex items-center w-full mb-4">
        <h2 className="text-xl font-semibold flex items-center justify-center">
          <img
            src={back}
            alt=""
            onClick={() => router("/AttendanceEmployees")}
            className="mr-2 cursor-pointer w-[2rem]"
          />
          <div className="whitespace-nowrap">TimeSheet - {selectedMonth.format("MMMM YYYY")}</div>
          <DatePicker
            onChange={handleMonthChange}
            picker="month"
            allowClear={false}
            className="cursor-pointer"
            value={selectedMonth}
            format="MMMM YYYY"
            // suffixIcon={<CalendarOutlined className="text-[2rem]" />}
            style={{ width: "0px", border: "none", opacity: 0 }}
            open={calendarOpen}
            onClick={toggleCalendar}
          />
        </h2>
        <div onClick={toggleCalendar} className="cursor-pointer">
          <CalendarOutlined className="text-[2rem] text-gray-500" />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-5 mt-4">
        <div className="flex w-full flex-row items-center gap-6 px-5 py-8">
          <div className="w-16 h-16">
            <img className="w-full h-full object-contain" src={profile} alt={`Profile-img`} />
          </div>
          <div className="flex flex-col justify-between h-full gap-1">
            <div className="">
              <h2 className="text-3xl font-semibold">Raymond Ali</h2>
              <p className="text-lg font-normal text-[#1ba397]">Software Engineer</p>
            </div>
            <p className="text-base font-normal mt-2">
              <span className="font-bold text-[#1ba397]">Dept:</span> Development
            </p>
          </div>
        </div>
        <PiCard percentage={85} title="Progress" />
        <PiCard percentage={70} title="Score" />
        <div className="grid w-full px-5 py-8">
          <div className="grid grid-cols-2 items-center">
            <h6 className="text-lg font-semibold whitespace-nowrap">Start Date:</h6>
            <h4 className="text-lg whitespace-nowrap">12 Aug 2024</h4>
          </div>
          <div className="grid grid-cols-2 items-center">
            <h6 className="text-lg font-semibold whitespace-nowrap">Employee ID:</h6>
            <h4 className="text-lg whitespace-nowrap">12312397971</h4>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center my-4">
        <div className="text-xl font-semibold flex items-center justify-center">
          <h2 className="whitespace-nowrap">Maria's TimeSheet</h2>
        </div>
        <div className="flex gap-4">
          <h1 className="text-lg cursor-pointer w-[6rem] bg-gray-100 px-3 flex items-center justify-center rounded-md font-semibold text-gray-500">
            Export
          </h1>
          <h1 className="text-lg px-3 rounded-md w-[6rem] bg-purple-500 flex items-center justify-center cursor-pointer font-semibold text-white">
            Approve
          </h1>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-primary text-white">
            <tr>
              {columnHeaders.map((columnHeader, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-4 py-3 font-semibold tracking-wider whitespace-nowrap">
                  {columnHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="8" className="text-center text-gray-500 text-lg">
                  <p className="p-10">No data found</p>
                </td>
              </tr>
            ) : (
              filteredData.map((data, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={`odd:bg-white even:bg-[#f2fcfc] border-b border-[1px] border-[#d9d9d970] hover:bg-gray-100 text-md`}>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">
                      <Checkbox
                        checked={selectedRow === data.id}
                        onChange={() => handleCheckboxChange(data.id)}
                      />
                    </td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.id}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.date}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.startWork}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.endWork}</td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">
                      {data.totalTimeWorked}
                    </td>
                    <td className="px-4 py-2 text-primary whitespace-nowrap">{data.exceptions}</td>
                    <td className={`px-4 py-2`}>
                      <div
                        className={`${
                          data?.status === "Active"
                            ? "text-primary bg-green-100"
                            : "text-red-600 bg-red-100"
                        } whitespace-nowrap px-4 py-1 text-center font-semibold rounded-md`}>
                        {data.status}
                      </div>
                    </td>
                  </tr>
                  {selectedRow === data.id && (
                    <tr className="bg-gray-100">
                      <td colSpan={columnHeaders.length}>
                        <div className="p-4">
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <strong>SR:</strong>
                              <div className="mt-1">1</div>
                              <div className="">2</div>
                              <div className="">3</div>
                              <div className="">4</div>
                            </div>
                            <div>
                              <strong>Actions:</strong>
                              <div className="mt-1">Time In</div>
                              <div>Time Out</div>
                              <div>Break Start</div>
                              <div>Break End</div>
                            </div>
                            <div>
                              <strong>Time:</strong>
                              <div className="mt-1">{data.timeRecords.timeIn}</div>
                              <div>{data.timeRecords.timeOut}</div>
                              <div>{data.timeRecords.breakStart}</div>
                              <div>{data.timeRecords.breakEnd}</div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <strong>Total Hours Worked:</strong> {data.timeRecords.totalHoursWorked}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <div className="font-semibold pl-1 text-gray-600">
          Showing <b className="text-primary">{startIndex + 1}</b> to{" "}
          <b className="text-primary">{endIndex}</b> of{" "}
          <b className="text-primary">{filteredData.length}</b> entries
        </div>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredData.length}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default StaticTable;
