import back5 from "../../../imgs/back5.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
const Gallary = ({ setState, subId, state }) => {
  const { eventId } = useParams();
  const [gallary, setGallary] = useState([]);

  useEffect(() => {
    let url;
    if (state === "gallary")
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/invitee-get-event-gallary/${eventId}`;
    else
      url = `${process.env.REACT_APP_BASE_URL}/v1/event/invitee-get-event-gallary/${subId}`;
    axios
      .get(url)
      .then((data) => {
        setGallary(data?.data?.rows);
      })
      .catch(() => {});
  }, []);
  return (
    <div className="flex items-center w-full justify-center pt-[2rem] flex-col">
      <div className="md:w-[80%] w-full flex flex-col items-center justify-center">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between w-full pl-1 pr-1 items-center mt-4">
            <div
              onClick={() => setState("event")}
              className="cursor-pointer flex items-center justify-center"
            >
              <img className="sm:w-[1.7rem] w-[1.3rem]" src={back5} alt="" />
              <h2 className="font-bold sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Images Gallary
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-[80%] w-full bg-gray-100 pt-8 pb-8 min-h-[50vh] mt-6 rounded-lg shadow-md p-4 ">
        <div className="flex gap-2 grid md:grid-cols-4 grid-cols-1 gap-y-8">
          {gallary.map((image) => (
            <div
              key={image.id}
              className="relative h-[15rem] border bg-gray-50 rounded-md w-full"
            >
              <img
                src={image.image}
                alt="Event"
                className="w-full h-full rounded-md"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallary;
