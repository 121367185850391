import { useSelector } from "react-redux";
import Listing from "../components/inventory/initial/index";
import Nav from "../components/inventory/vendor/navbar";
const ListingPage = () => {
  return (
    <div>
       <Nav /> 
      <Listing />
    </div>
  );
};

export default ListingPage;
