import Gal from "../components/events/eventGallary/gallary";
import Nav from "../components/Nav/nav";

const Gallary = () => {
  return (
    <div>
      <Nav />
      <Gal />
    </div>
  );
};

export default Gallary;
