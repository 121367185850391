import { Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const Paget = () => {
  const token = useSelector((state) => state.token);
  const totalPages = 1;
  const [page, setPage] = useState(1);
  const [pensioners, setPensioners] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const router = useNavigate();
  const dispatch = useDispatch();
  // const option2 = useSelector((state) => state.option2);
  let wow = [];
  for (let i = 0; i <= 5; i++) {
    wow.push(i);
  }
  const pageHandler = (e) => {
    setPage(e);
  };
  const columnHeaders = [
    "Sr. No",
    "Vendor Name",
    "Phone",
    "Email",
    "Address",
    "Total Orders",
    "Registered On",
  ];

  const vendorData = [
    {
      srNo: 1,
      vendorName: "John's Supplies",
      phone: "123-456-7890",
      email: "john@supplies.com",
      address: "123 Market St, New York, NY",
      totalOrders: 150,
      registeredDate: "4/12/2022",
      registeredTime: "06:20pm",
    },
    {
      srNo: 2,
      vendorName: "Smith Tools",
      phone: "987-654-3210",
      email: "smith@tools.com",
      address: "456 Tool Ave, Los Angeles, CA",
      totalOrders: 98,
      registeredDate: "10/23/2021",
      registeredTime: "06:20pm",
    },
    {
      srNo: 3,
      vendorName: "Baker Essentials",
      phone: "456-123-7890",
      email: "info@bakeressentials.com",
      address: "789 Bakery Rd, Chicago, IL",
      totalOrders: 203,
      registeredDate: "1/18/2023",
      registeredTime: "06:20pm",
    },
    {
      srNo: 4,
      vendorName: "FastTech Solutions",
      phone: "321-654-9870",
      email: "support@fasttech.com",
      address: "321 Innovation Blvd, San Francisco, CA",
      totalOrders: 75,
      registeredDate: "8/5/2020",
      registeredTime: "06:20pm",
    },
    {
      srNo: 5,
      vendorName: "Green Grocers",
      phone: "789-321-6540",
      email: "sales@greengrocers.com",
      address: "654 Green Valley St, Miami, FL",
      totalOrders: 120,
      registeredDate: "12/30/2022",
      registeredTime: "06:20pm",
    },
  ];

  return (
    <section className="mt-4">
      <div className="mx-auto max-w-screen-xl ">
        <div className="bg-white  relative shadow-md sm:rounded-lg overflow-hidden border-2 border-green-100 ">
          {/* table */}
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-primary text-white rounded-lg">
                <tr>
                  {columnHeaders.map((columnHeader, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-4 py-3 font-semibold tracking-wider "
                    >
                      {columnHeader}
                    </th>
                  ))}
                </tr>
              </thead>
              {vendorData?.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="9"
                      className="text-center text-gray-500 text-lg"
                    >
                      <p className="p-10">No data found</p>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {vendorData?.map((pen, index) => (
                    <tr
                      key={index}
                      className={`border-b border-[1px] border-[#d9d9d970] hover:bg-gray-200 text-md cursor-pointer ${
                        index % 2 === 0 ? "bg-white" : "bg-[#f2faf9]"
                      }`}
                      onClick={() => {
                        // handle click
                      }}
                    >
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {pen?.srNo}.
                      </th>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10">
                            <img
                              className="h-10 w-10 rounded-full border-2 border-primary"
                              src="https://static.vecteezy.com/system/resources/thumbnails/007/209/020/small_2x/close-up-shot-of-happy-dark-skinned-afro-american-woman-laughs-positively-being-in-good-mood-dressed-in-black-casual-clothes-isolated-on-grey-background-human-emotions-and-feeligs-concept-photo.jpg"
                              alt=""
                            />
                          </div>
                          <div className="ml-4">{pen?.vendorName}</div>
                        </div>
                      </td>

                      <td className="px-4 py-4">
                        {pen?.phone === null ? "----" : `${pen?.phone}`}
                      </td>

                      <td className="px-4 py-4">{pen?.email}</td>
                      <td className="px-4 py-4">{pen?.address}</td>
                      <td className="px-4 py-4">{pen?.totalOrders}</td>
                      <td className="px-4 py-4">
                        {pen?.registeredDate}
                        <p>{pen?.registeredTime}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Paget;
