import back5 from "../../../../imgs/back5.png";
import { useNavigate } from "react-router-dom";

import Table from "./table";
import { useSelector } from "react-redux";
const Listing = () => {
  const router = useNavigate();
  const user = useSelector((state) => state.user);

  const statuses = [
    { title: "Pending", count: 200 },
    { title: "Cancelled", count: 120 },
    { title: "In-Progress", count: 60 },
    { title: "Shipped", count: 10 },
    { title: "Delivered", count: 70 },
    { title: "Partial", count: 70 },
    { title: "Delivered-Issue reported", count: 70 },
  ];

  const StatusCard = ({ title, count }) => {
    return (
      <div className="bg-[#D9EFED] border shadow-md text-center p-4 rounded-lg shadow-md w-40 h-30 flex flex-col justify-center">
        {/* Title Section */}
        <p className="text-gray-500 font-semibold text-sm h-12 ">{title}</p>

        {/* Count Section */}
        <p className="text-teal-600 font-bold text-3xl ">{count}</p>
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="flex items-center justify-center bg-[#D9EFED] ">
          <div className="w-[95%] mt-[1rem] px-10 rounded-md mb-12">
            <div className="cursor-pointer flex w-full items-center justify-center">
              <img
                onClick={() => {
                  if (user === "vendor") {
                    router("/VendorDashboard");
                    return;
                  }
                  router("/SuperAdmin/InventoryDashboard");
                }}
                className="sm:w-[1.7rem] w-[1.3rem]"
                src={back5}
                alt=""
              />
              <h2 className="font-bold w-full sm:text-[1.8rem] sm:ml-2 ml-[1rem] text-[1.4rem]">
                Orders
              </h2>
            </div>
            {user === "superadmin" && (
              <div className="flex justify-end mt-4 gap-3">
                <button
                  type="button"
                  onClick={() => {}}
                  className="cursor-pointer rounded-md pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-primary w-[12rem]"
                >
                  Place Order
                </button>
              </div>
            )}

            <div className="bg-[#F6F4F4] mt-3 rounded-md p-2">
              <div className="flex space-x-4 justify-center p-6">
                {statuses.map((status, index) => (
                  <StatusCard
                    key={index}
                    title={status.title}
                    count={status.count}
                  />
                ))}
              </div>

              <Table />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
