import React, { useEffect, useState } from "react";
import PageHeader from "../pensionerVerification/pageHeader/pageHeader";
import { useDispatch, useSelector } from "react-redux";
import ErrorModal from "../Merchant/Modals/errorModal";
import i from "../../imgs/i.svg";
import moment from "moment";
import { TimePicker } from "antd";
import deleteimg from "../../imgs/delete.svg";
import pencil from "../../imgs/pencil.svg";
import ScheduleModal from "./modal/scheduleModal";
import SuccessModal from "../Merchant/Modals/successModal";
import VerificationSchduleDone from "./modal/verificationSchduleDone";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SelectProgram = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const SelectedSlot = useSelector(
    (state) => state.scheduleVerificationSelectedSlot
  );
  const EditSlot = useSelector((state) => state.EditSlot);
  const SelectedSlotData = useSelector((state) => state.SelectedSlot);
  const dataUser = useSelector((state) => state.dataUser);
  const distributionCenter = useSelector((state) => state.distributionCenter);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState(SelectedSlot[0]?.date);
  const [showModal, setShowModal] = useState(false);
  const [verificationDone, setVerificationDone] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [afterApi, setAfterApi] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setSelectedDate(SelectedSlot[0]?.date);
    // dispatch({
    //   type: "SELECTED_SLOT",
    //   num: SelectedSlot[0],
    // });
  }, []);

  useEffect(() => {
    setEmployees([]);
    setAfterApi(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/scheduler-center-time-manager/${SelectedSlotData?.id}/get-employees`,
        {
          headers: {
            Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
          },
        }
      )
      .then((res) => {
        setEmployees(res?.data);
        setAfterApi(true);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  }, [selectedDate, refetch]);

  const DeleteEmployees = () => {
    const api = `v1/scheduler-center-time-manager/${SelectedSlotData?.id}/remove-employees`;

    const method = "post";

    axios[method](
      `${process.env.REACT_APP_BASE_URL}/${api}`,
      {
        employeeUserIDs: selectedEmployees,
      },
      {
        headers: {
          Authorization: `Bearer ${dataUser?.data?.tokens?.access?.token}`,
        },
      }
    )
      .then((res) => {
        setErr("Employees deleted successfully");
        setShowSuccess(true);
        setRefetch(!refetch);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setShowErr(true);
      });
  };

  return (
    <div className="bg-[#ebfff3]  flex flex-col p-4 min-h-screen">
      <PageHeader
        header={true}
        title={"Detail"}
        // route={"/scheduleVerification/selectProgram"}
        route={"/pensionerVerificationScheduling/selectLocation"}
      />

      <div className="flex items-center flex-col">
        <div className="sm:w-[80%]  mx-4">
          <div className="flex flex-row items-center justify-end mb-2 gap-2">
            <div className="flex flex-row gap-2 px-4">
              <div className="w-full md:w-[20rem]">
                <form className="flex items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 "
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     "
                      placeholder="Search"
                      required=""
                      //   value={searchTerm}
                      //   onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`bg-[#fafaf9] shadow-lg rounded-sm mx-4 m-5 p-7 sm:w-[80%] w-full`}
        >
          {/* Center Name */}
          <div className="flex items-center">
            <div className="w-2/12">
              <h3 className="font-medium mb-1 text-[0.92rem] text-gray-600">
                Center Name
              </h3>
            </div>
            <div className="w-10/12 font-semibold text-[0.98rem] ">
              {distributionCenter?.distributioncenter?.centerName}
              {", "}
              {
                distributionCenter?.distributioncenter?.distributionlocation
                  ?.distributionregion?.regionNo
              }
            </div>
          </div>

          {/* Date and time */}
          <div className="flex mt-4">
            <div className="w-2/12">
              <h3 className="font-medium mb-1 text-[0.92rem] text-gray-600">
                Date & Time
              </h3>
            </div>
            <div className="w-10/12 bg-white p-6 ">
              <div className="grid grid-cols-7 gap-3 mb-3">
                {SelectedSlot?.map((data, index) => (
                  <button
                    key={index} // Using index as key is fine here
                    onClick={() => {
                      setSelectedDate(data?.date);
                      dispatch({
                        type: "SELECTED_SLOT",
                        num: data,
                      });
                    }}
                    className={`px-4 py-2 rounded-lg ${
                      selectedDate === data?.date
                        ? "bg-primary text-white"
                        : "bg-gray-100"
                    } font-medium text-md`}
                  >
                    {moment(data?.date).format("DD") + " "}
                    {moment(data?.date).format("MMM")}
                  </button>
                ))}
              </div>

              <div className="space-y-3 pointer-events-none">
                {SelectedSlot?.map((slot, dataIndex) =>
                  slot.date === selectedDate ? (
                    <div key={dataIndex}>
                      <div
                        className="flex items-center  flex-col w-full bg-white"
                        style={{ marginBottom: "8px" }}
                      >
                        <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4">
                          <label>
                            Open Time:
                            <div className="relative">
                              <TimePicker
                                showNow={false}
                                value={
                                  slot?.openTime
                                    ? moment(slot?.openTime, "hh:mm A")
                                    : null
                                }
                                format="hh:mm A"
                                allowClear={false}
                              />
                            </div>
                          </label>
                          <label>
                            Close Time:
                            <div className="relative">
                              <TimePicker
                                showNow={false}
                                value={
                                  slot?.closeTime
                                    ? moment(slot?.closeTime, "hh:mm A")
                                    : null
                                }
                                format="hh:mm A"
                                allowClear={false}
                              />
                            </div>
                          </label>
                        </div>
                      </div>

                      <div
                        className="flex items-center flex-col w-full"
                        style={{ marginBottom: "8px" }}
                      >
                        <p className="font-medium w-full text-[0.95rem] capitalize">
                          Break Time
                        </p>
                        <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-4 bg-white mt-2">
                          <label>
                            Start Time:
                            <div className="relative">
                              <TimePicker
                                showNow={false}
                                value={
                                  slot?.breakStart
                                    ? moment(slot?.breakStart, "hh:mm A")
                                    : null
                                }
                                format="hh:mm A"
                                allowClear={false}
                              />
                            </div>
                          </label>
                          <label>
                            End Time:
                            <div className="relative">
                              <TimePicker
                                showNow={false}
                                value={
                                  slot?.breakEnd
                                    ? moment(slot?.breakEnd, "hh:mm A")
                                    : null
                                }
                                format="hh:mm A"
                                allowClear={false}
                              />
                            </div>
                          </label>
                        </div>
                      </div>

                      <div className="flex items-center gap-x-4 mt-7">
                        <h1 className="font-semibold whitespace-nowrap">
                          Verification slot duration (Min)
                        </h1>
                        <div className="flex gap-2 bg-[#F6F4F4] px-2">
                          <input
                            type="text"
                            value={slot?.slotDuration}
                            className="w-[4rem] h-[2rem] px-2 rounded-lg text-center bg-[#F6F4F4] text-[1rem]"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>

          {/* Text */}
          {employees?.length > 0 && afterApi && (
            <div className="flex items-center gap-2 mt-6 justify-between ">
              {/* <div className="rounded-full bg-gray-300 w-5 h-5 p-1 flex items-center justify-center shadow-md">
                <img src={i} alt="Profile" className="w-4 h-4 " />
              </div>
              <span className="text-gray-600">
                You can set a different schedule for each employee. If you don't,
                the employee will follow the distribution center's schedule.
              </span> */}
              <h1 className="font-semibold text-[1rem] text-gray-700">
                {employees?.length} Selected Employees
              </h1>
              <div className="flex gap-3">
                <div
                  className="bg-red-600 rounded-md"
                  onClick={() => {
                    if (selectedEmployees.length === 0) {
                      setErr("No employee selected to delete");
                      setShowErr(true);
                      return;
                    }
                    DeleteEmployees();
                  }}
                >
                  <button className="text-white font-semibold text-[0.95rem] px-3 py-1">
                    Delete
                  </button>
                </div>

                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                    router("/scheduleVerification/selectEmployees");
                  }}
                >
                  <button class="bg-primary text-white rounded-full p-1 hover:bg-teal-600  flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3 w-3"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4v16m8-8H4"
                      />
                    </svg>
                  </button>

                  <button className="text-primary font-semibold text-[0.95rem]">
                    Add More
                  </button>
                </div>
              </div>
            </div>
          )}

          {employees?.length === 0 && afterApi && (
            <div className="flex items-center justify-center mt-6 flex-col gap-2">
              <h1 className="text-gray-600 text-[0.95rem]">
                No employee added yet for this date
              </h1>
              <div
                className="flex items-center gap-2"
                onClick={() => {
                  router("/scheduleVerification/selectEmployees");
                }}
              >
                <button class="bg-primary text-white rounded-full p-1 hover:bg-teal-600  flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>

                <button className="text-primary font-semibold text-[0.95rem]">
                  Add Employees
                </button>
              </div>
            </div>
          )}

          {/* Users */}
          <div className="grid grid-cols-4 gap-5 mt-6">
            {employees?.map((user) => (
              <div
                className={`${
                  selectedEmployees.includes(user?.employeeUserId)
                    ? "bg-primary text-white border border-white"
                    : "bg-white text-black"
                }  p-4 rounded-lg cursor-pointer flex items-center p-4  rounded-md shadow-sm bg-white max-w-xs relative`}
                onClick={() => {
                  if (selectedEmployees.includes(user?.employeeUserId)) {
                    setSelectedEmployees(
                      selectedEmployees.filter(
                        (item) => item !== user?.employeeUserId
                      )
                    );
                  } else {
                    setSelectedEmployees([
                      ...selectedEmployees,
                      user?.employeeUserId,
                    ]);
                  }
                }}
              >
                <div
                  className={`
                  ${
                    selectedEmployees?.includes(user?.employeeUserId)
                      ? " w-16 h-16 rounded-full  overflow-hidden border-4 border-white"
                      : "w-16 h-16 rounded-full border-4 border-primary overflow-hidden"
                  }
                  `}
                >
                  <img
                    src={
                      user?.employeeUser?.person?.image
                        ? user?.employeeUser?.person?.image
                        : "https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
                    }
                    alt="Profile"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="ml-4">
                  <h3 className="text-[0.95rem] font-semibold capitalize">
                    {user?.employeeUser?.person?.firstName +
                      " " +
                      user?.employeeUser?.person?.lastName}
                  </h3>
                  <p
                    className={`${
                      selectedEmployees.includes(user?.employeeUser?.person?.id)
                        ? "text-white"
                        : "text-primary"
                    } font-medium`}
                  >
                    {user?.role}
                  </p>
                </div>
                {/* <div
                  className="absolute top-2 right-3"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <img src={pencil} alt="edit" />
               
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* button */}
      <div
        className={`mt-3 flex  items-center sm:justify-center justify-center w-full mb-5 `}
      >
        <button
          type="button"
          onClick={() => {
            if (EditSlot) {
              setShowSuccess(true);
              setErr("Verification slot edited successfully");
              return;
            }
            setVerificationDone(true);
          }}
          className={`lg:text-[1rem] bg-gradient-to-tr  bg-primary text-white rounded-[0.5rem] p-2  pr-8 pl-8 w-[12rem]`}
        >
          Submit
        </button>
      </div>
      {showErr && <ErrorModal show={showErr} setShow={setShowErr} err={err} />}
      {showModal && <ScheduleModal show={showModal} setShow={setShowModal} />}
      {verificationDone && (
        <VerificationSchduleDone
          show={verificationDone}
          setShow={setVerificationDone}
        />
      )}
      {showSuccess && (
        <SuccessModal
          show={showSuccess}
          setShow={setShowSuccess}
          err={err}
          route={"/pensionerVerificationScheduling/selectLocation"}
        />
      )}
    </div>
  );
};

export default SelectProgram;
